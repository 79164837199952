.cookie-modal {
  .modal-header {
    width: 100%;
    h5 {
      padding-top: 15px; } }
  .btn-cookie-accept {
    margin: 30px auto 10px;
    background: #232936;
    color: #ffffff;
    padding: 5px 30px; } }
