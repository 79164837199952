
.category-top-box h1 p:not(:last-child)::after {
  content: '/';
  margin-left: 10px; }
.category-top-box-container {
  background: url("../../assets/images/category-bg.png") no-repeat;
  background-size: 100% 280px;
  @media #{$tablet-strict} {
    background-size: 100% 100%; }
  .category-top-box {
    @media #{$tablet-strict} {
      padding-bottom: 30px; }
    > h1 {
      color: #ffffff;
      font-size: 32px;
      font-weight: 400;
      line-height: 42px;
      margin-bottom: 0px; }
    .main-promoted-item {
      .card {
        width: 100%;
        border: none;
        .expert-index-image {
          a {
            position: relative;
            @media #{$tablet-strict} {
              &:after {
                content: '';
                position: absolute;
                width: 45px;
                height: 45px;
                right: 20px;
                top: 45px;
                background: url("../../assets/images/play-icon.png") center center no-repeat;
                border-radius: 50%;
                transition: all 0.5s; } } }
          img {
            object-fit: cover;
            @media #{$tablet-strict} {
              max-height: 180px; } } }
        .expert-index-content {
          .card-body {
            padding: 20px 35px 20px 5px;
            @media #{$tablet-strict} {
              padding: 20px 35px; }
            h5 {
              color: #a5a5a5;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.7px;
              line-height: 24px;
              text-transform: uppercase;
              @media #{$tablet-strict} {
                display: none; } }
            h4, h4 a {
              color: $c2;
              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              a {
                &:focus, &:hover {
                  text-decoration: underline; } } }
            p {
              color: #1d1d1d;
              font-size: 15px;
              font-weight: 300;
              letter-spacing: 0.15px;
              line-height: 20px;
              position: relative;
              > span {
                font-weight: 700;
                color: #656565;
                display: table;
                line-height: 24px;
                @media #{$tablet-strict} {
                  &:first-of-type {
                    display: none; } }
                &:first-of-type {
                  padding-top: 20px; } } } }

          .btn {
            position: absolute;
            bottom: 33px;
            right: 55px;
            height: 40px;
            width: 180px;
            border-radius: 26px;
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
            background-color: #2543fd;
            opacity: 0.5;
            border: none;
            transition: 0.3s all;
            @media #{$tablet-strict} {
              display: none; }
            &:after {
              content: '';
              position: absolute;
              right: 12px;
              top: 11px;
              width: 0;
              height: 0;
              border-top: 9px solid transparent;
              border-bottom: 9px solid transparent;
              border-left: 15px solid #fff;
              clear: both; }
            &:focus, &:hover {
              opacity: 0.6;
              transition: 0.3s all; } } } } } } }



$a: 100%;
$b: 80px;
$badge: #82d6c8, #7e90ff, #8bbde6, #63d9b3, #b0c5ed, #62a2d8;


.category-item-wrapper {
  @for $i from 1 through length($badge) {
    &:nth-child(#{$i}n) {
      .category-item {
        .item-badge {
          background-color: nth($badge, $i); } } } }
  .category-item {
    margin-bottom: 25px;
    display: flex;
    .item-badge {
      display: flex;
      flex-basis: 80px; }
    .item-title {
      display: flex;
      width: calc(#{$a} - #{$b});
      padding: 0 30px 0 20px;
      align-items: center; } }
  .category-item-0 {
    &:after {
      background: url("../../assets/images/category-icons/c1.svg") no-repeat; } }
  .category-item-2 {
    &:after {
      background: url("../../assets/images/category-icons/c2.svg") no-repeat; } }
  .category-item-3 {
    &:after {
      background: url("../../assets/images/category-icons/c3.svg") no-repeat; } }
  .category-item-4 {
    &:after {
      background: url("../../assets/images/category-icons/c4.svg") no-repeat; } }
  .category-item-5 {
    &:after {
      background: url("../../assets/images/category-icons/c5.svg") no-repeat; } }
  .category-item-6 {
    &:after {
      background: url("../../assets/images/category-icons/c6.svg") no-repeat; } } }


