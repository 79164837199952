.product-breadcrumb-wrapper {
  background: $c5; }
.quiz-login-btn .login-link {
  border: 2px solid #2ee3a9;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  transition: 0.3s all;
  display: table;
  width: 256px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  padding-left: 37px;
  background: #2ee3a9;
  color: #000;
  @media #{$desktop-strict} {
    width: 230px;
    font-size: 14px; }
  &:after {
    content: "";
    width: 18px;
    height: 15px;
    right: 17px;
    top: 10px;
    position: absolute;
    transition: all 0.3s;
    background: url("../../assets/images/arr-black.png") no-repeat; }
  &:focus, &:hover, &:active {
    outline: none;
    box-shadow: none;
    border-color: #2ee3a9;
    background: transparent;
    &:after {
      right: 30px; } } }
.webinar-btn {
  border: 2px solid #2ee3a9;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  transition: 0.3s all;
  display: table;
  width: 256px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  background: transparent;
  padding-left: 37px;
  @media #{$desktop-strict} {
    width: 230px;
    font-size: 14px; }
  &:after {
    content: "";
    width: 18px;
    height: 15px;
    right: 17px;
    top: 10px;
    position: absolute;
    transition: all 0.3s;
    background: url("../../assets/images/arr-white.svg") no-repeat; }
  &:focus, &:hover, &:active {
    outline: none;
    box-shadow: none;
    background: #2ee3a9;
    border-color: #2ee3a9;
    color: #000;
    padding-left: 30px;
    &:after {
      right: 22px;
      transition: all 0.3s;
      background: url("../../assets/images/arr-black.png") no-repeat; } }
  &:hover {
    transition: 0.3s all; }
  &.reverse {
    background: #2ee3a9;
    border-color: #2ee3a9;
    color: #000;
    &:after {
      background: url("../../assets/images/arr-black.png") no-repeat; }
    &:hover, &:focus {
      background: transparent;
      color: #fff;
      &:after {
        background: url("../../assets/images/arr-white.svg") no-repeat; } } }
  &.webinar-btn-white {
    color: #fff;
    &:after {
      background: url("../../assets/images/arr-white.svg") no-repeat; }
    &:focus, &:hover, &:active {
      color: #000;
      &:after {
        background: url("../../assets/images/arr-black.png") no-repeat; } } } }


.webinar-wave-container {
  font-family: 'Red Hat Display', sans-serif;
  padding-bottom: 48px;
  &.product-container {
    padding-bottom: 40px; }
  @media #{$tablet-strict} {
    background: $c5;
    margin-bottom: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 30px;
    &.oswiata {
     background: linear-gradient(178.02deg, #242936 26.54%, #32509F 156.41%); }
    &.ksiegowosc {
      background: linear-gradient(178.02deg, #242936 26.54%, #FDD26E 156.41%); } }

  svg {
    fill: $c5;
    position: absolute;
    left: 0;
    width: 100%;
    height: 630px;
    @media #{$xlarge} {
      height: 590px; }
    @media #{$tablet-strict} {
      display: none; } }
  .webinar-wave-container-content {
    color: #ffffff;
    padding-top: 32px;
    .webinar-data {
      color: #fff!important;
      @media #{$tablet-strict} {
        padding-top: 10px; }
      h1 {
        font-size: 30px;
        font-weight: 400;
        line-height: 32px;
        margin: 0 0 7px;
        max-height: 160px;
        overflow: hidden;
        @media #{$phone-strict} {
          max-height: none; }
 }        //overflow: hidden
      h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 0 30px;
        @media #{$xlarge} {
          margin: 0 0 20px; } }
      .is-archive {
        margin-bottom: 24px;
        position: relative;
        padding-left: 24px;
        &:before {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: red;
          content: "";
          left: 6px;
          top: 5px;
          position: absolute; }
        &.active {
          &:before {
            background: $c4; } } }
      .webinar-duration {
        display: block;
        font-size: 17px;
        position: relative;
        margin-left: 50px;
        margin-bottom: 16px;
        &:before {
          width: 26px;
          height: 26px;
          background: url("../../assets/images/clock-form.png") no-repeat;
          content: "";
          left: -34px;
          position: absolute; }
        &.white {
          margin-left: 33px;
          margin-bottom: 10px;
          &:before {
            background: url("../../assets/images/clock-white.svg") no-repeat;
            background-size: contain;
            top: -3px;
            width: 26px;
            height: 26px;
            content: "";
            left: -34px;
            position: absolute; } } }
      p {
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.12px;
        line-height: 15px;
        margin: 0 0 30px;
        max-height: 61px;
        overflow: hidden;
        @media #{$xlarge} {
          margin: 0 0 20px; } }
      .webinar-dates-container {
        margin-bottom: 60px;
        //padding-right: 30px
        @media #{$xlarge} {
          margin: 0 30px 20px 0; }
        .webinar-date-item {
          border-bottom: 1px solid #ffffff;
          display: flex;
          justify-content: space-between;
          padding: 3px 0;
          &.expired {
            position: relative;
            &:before {
              position: absolute;
              width: 100%;
              border-top: 1px solid #ffffff;
              top: 44%;
              left: 0;
              content: ''; } }
          &:last-child {
            border-bottom: none; }
          > span {
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;
            &:before {
              content: "";
              border: 3px solid #ffff37;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              display: table;
              float: left;
              margin-top: 5px;
              margin-right: 7px; } }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin: 0;
            > span {
              font-size: 17px;
              line-height: 21px; } } } }
      .webinar-btn {
        margin-left: 25px;
        @media #{$tablet-strict} {
          margin-left: 0; }
        @media #{$phone-strict} {
          margin: 20px auto; } } }
    .webinar-image {
      position: relative;
      .chat-box {
        > iframe {
          margin-top: 0;
          min-height: 320px; } }
      .webinar-image-badge {
        position: absolute;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 32px;
        font-weight: 500;
        margin-top: 45px;
        z-index: 2;
        @media #{$phone-strict} {
          font-size: 12px;
          margin-top: 16px;
          line-height: 26px; }
        .webinar-image-badge-type {
          color: #ffffff;
          background: #000A48;
          margin-bottom: 3px;
          padding: 0 22px 0 26px; }
        .webinar-image-badge-category {
          color: $c5;
          background: #FFFF00;
          padding: 0 22px 0 26px;
          display: table;
          &.badge-hr-kadry {
            background: #F1B5A6;
            color: #000; }
          &.badge-kadry-prawo-pracy {
            background: #6D74B9;
            color: #fff; }
          &.badge-ksiegowosc {
            background: #F8CF76;
            color: #000; }
          &.badge-ochrona-danych-osobowych {
            background: #F2EB61;
            color: #000; }
          &.badge-oswiata {
            background: #4AA5D8;
            color: #fff; }
          &.badge-srodowisko {
            background: #4BA8A7;
            color: #fff; }
          &.badge-transport {
            background: #2EE3A9;
            color: #000; }
          &.badge-zamowienia-publiczne {
            background: #A7BEEB;
            color: #000; } } }

      > img {
        width: 100%;
        border-radius: 25px;
        @media #{$desktop} {
          height: 470px;
          object-fit: cover; } }
      .webinar-benefit-container {
        margin-top: 40px;
        display: flex;
        color: $c2;
        justify-content: space-between;
        &.product-benefits {
          flex-wrap: wrap; }
        @media #{$tablet-strict} {
          flex-direction: column; }
        @media #{$tablet-strict} {
          color: #ffffff; }
        .webinar-benefit-item {
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
          line-height: 18px;
          width: calc(100% / 3);
          padding-bottom: 12px;
          @media #{$tablet-strict} {
            width: 100%;
            margin-bottom: 20px; }
          &.item-1 {
            img {
              margin-right: 15px; } }
          &.item-2 {
            padding-right: 20px;
            img {
              margin-right: 20px; } }
          &.item-3 {
            img {
              margin-right: 15px; } } } } }
    &.webinar-paid-wave-container {
      .webinar-data {
        .webinar-dates-container {
          .webinar-date-item {
            position: relative;
            > span {
              &:before {
                border: 2px solid #fff;
                width: 13px;
                height: 13px; }
              &:after {
                content: "";
                border-radius: 50%;
                width: 5px;
                background: red;
                height: 5px;
                display: table;
                position: absolute;
                top: 12px;
                left: 4.5px; } } } } }
      .webinar-image {
        .webinar-benefit-container {
          flex-wrap: wrap; } } } }
  &.webinar-thx {
    svg {
      @media #{$desktop-strict} {
        height: 530px; } }
    .webinar-wave-container-content {
      .webinar-thx-data {
        h2 {
          margin: 18px 0 45px;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: 32px;
          text-align: center;
          @media #{$desktop-strict} {
            margin: 8px 0 12px; }
          span {
            display: block;
            font-size: 31px;
            font-weight: 500; }
          @media #{$tablet-strict} {
            font-size: 18px;
            margin: 10px 0 25px;
            span {
              font-size: 22px; } } }
        p {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.17px;
          line-height: 24px;
          padding-left: 45px;
          margin-bottom: 18px;
          position: relative;
          @media #{$desktop-strict} {
            font-size: 13px;
            line-height: 20px; }
          @media #{$tablet-strict} {
            margin-bottom: 20px;
            font-size: 16px; }
          &:before {
            content: "";
            position: absolute;
            top: - 3px;
            left: 4px;
            width: 23px;
            height: 23px;
            background: url("../../assets/images/webinar-play.png") no-repeat;
            background-size: contain; } } }
      .webinar-image {
        h1 {
          color: $c5;
          font-size: 30px;
          font-weight: 400;
          margin: 22px 0 3px;
          @media #{$phone-strict} {
            color: #ffffff; } }
        h3 {
          margin-bottom: 22px;
          >span {
            color: #000;
            font-size: 16px;
            font-weight: 500; }
          @media #{$phone-strict} {
            color: #ffffff; }
          a {
            text-transform: none;
            color: $c5;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            @media #{$phone-strict} {
              color: #ffffff; }
            &:hover {
              text-decoration: underline; } } }
        h4 {
          font-weight: 700;
          font-size: 19px;
          line-height: 23px;
          color: $c5;
          @media #{$phone-strict} {
            color: #ffffff; }
          span {
            font-size: 21px; } } } } }
  &.webinar-video-container {
    background: $c5;
    &.not-logged {
      background: transparent; }
    &.oswiata {
      background: linear-gradient(178.02deg, #242936 26.54%, #32509F 156.41%); }
    &.ksiegowosc {
      background: linear-gradient(178.02deg, #242936 26.54%, #FDD26E 156.41%); }
    .webinar-wave-container-content {
      padding-top: 30px;
      @media #{$tablet-strict} {
        padding-top: 0; }
      @media #{$desktop} {
        padding-top: 30px; }
      .webinar-data {
        &.webinar-video-data {
          padding-top: 0;
          .vimeo-access-header {
            line-height: 36px; } }
        .chat-box {
          height: 100%;
          padding-bottom: 40px;
          > iframe {
            height: 100%;
            border-radius: 11px; } }
        h1 {
          margin-bottom: 30px;
          overflow: hidden; }
        h2 {
          margin-bottom: 20px; }
        .webinar-duration {
          display: block;
          font-size: 17px;
          position: relative;
          margin-left: 50px;
          margin-bottom: 16px;
          &:before {
            width: 26px;
            height: 26px;
            background: url("../../assets/images/clock-form.png") no-repeat;
            content: "";
            left: -34px;
            position: absolute; }
          &.white {
            &:before {
              background: url("../../assets/images/clock-white.svg") no-repeat;
              background-size: contain;
              top: -3px;
              width: 26px;
              height: 26px;
              content: "";
              left: -34px;
              position: absolute; } } }

        .webinar-btn {
          margin-left: 0; } } }
    .webinar-image {
      @media #{$desktop} {
        padding-left: 25px; }
      iframe {
        border-radius: 28px; }
      &.webinar-image-denied {
        .opacity-background {
          height: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0.67);
          border-radius: 28px;
          z-index: 1;
          @media #{$tablet-strict} {
            width: calc(100% - 30px); } }
        h3 {
          position: absolute;
          z-index: 2;
          width: 70%;
          left: 15%;
          text-align: center;
          top: 45%;
          @media #{$phone-strict} {
            top: auto;
            bottom: 15px;
            font-size: 16px; } }
        &:after {
          content: '';
          z-index: 2;
          width: 230px;
          left: 65px;
          bottom: 25px;
          background: url('https://educado.pl/logo-white.svg') no-repeat;
          position: absolute;
          height: 60px;
          @media #{$phone-strict} {
            display: none; } } } } } }
.webinar-form-section {
  font-family: 'Red Hat Display', sans-serif;
  color: $c5;
  margin-bottom: 80px;
  .btn-media {
    width: 31px;
    height: 31px;
    background: url("../../assets/images/share.svg") no-repeat;
    opacity: 0.2;
    background-size: contain;
    border: none;
    padding: 0;
    &:focus, &:hover {
      opacity: 1; } }
  .webinar-details-container-content {
    box-shadow: 0 3px 6px #d9d9d9;
    border-radius: 9px;
    border: 1px solid $c5;
    background-color: #ffffff;
    padding: 24px 42px;
    @media #{$phone-strict} {
      margin-bottom: 30px;
      padding: 24px; }
    ul {
      li {
        &::marker {
          color: $c4; } } }
    h2 {
      font-weight: 400;
      display: flex;
      font-size: 30px;
      justify-content: space-between;
      letter-spacing: 0.27px;
      line-height: 35px;
      margin-bottom: 12px;
      @media #{$tablet-strict} {
        font-size: 24px; } }
    h3 {
      font-size: 25px;
      font-weight: 400;
      line-height: 27px;
      text-transform: none;
      margin: 0 0 16px;
      color: $c5;
      opacity: 1;
      @media #{$tablet-strict} {
        font-size: 22px; }
      &.webinar-headline-small {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600; } }
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-top: 18px;
      > a {
        color: $c5;
        &:hover {
          color: $c3; } }
      @media #{$tablet-strict} {
        font-size: 18px; }
      > span {
        font-size: 17px;
        position: relative;
        margin-left: 35px;
        display: block;
        white-space: nowrap;
        margin-top: 22px;
        &:before {
          width: 26px;
          height: 26px;
          background: url("../../assets/images/clock-form.png") no-repeat;
          content: "";
          left: -34px;
          position: absolute; } } }
    p {
      margin-bottom: 20px; }

    .program ul {
      position: relative;
      list-style: none;
      &:before {
        position: absolute;
        z-index: 99;
        content: "";
        width: 1px;
        height: 96%;
        background: #56e4a9;
        left: 6px;
        top: 6px;
        @media #{$tablet-strict} {
          height: 91%; } }
      li {
        font-size: 17px;
        font-weight: 400;
        line-height: 22px;
        padding-bottom: 20px;
        &:before {
          position: absolute;
          content: "";
          width: 12px;
          height: 12px;
          background: #56e4a9;
          font-weight: bold;
          display: inline-block;
          border-radius: 50%;
          left: 0;
          margin-top: 5px; } } }
    .webinar-patronage {
      display: flex;
      flex-flow: wrap;
      margin: 5px 0 28px;
      .product-patronage-col {
        padding: 20px 10px; } }
    .webinar-dates-container {
      margin-bottom: 30px;
      margin-top: 40px;
      padding-left: 0;
      .webinar-date-item {
        border-bottom: 1px solid $c5;
        display: flex;
        justify-content: space-between;
        padding: 3px 0;
        &.expired {
          position: relative;
          &:before {
            position: absolute;
            width: 100%;
            border-top: 1px solid $c5;
            top: 44%;
            left: 0;
            content: ''; } }
        &:last-child {
          border-bottom: none; }
        > span {
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          &:before {
            content: "";
            border: 3px solid $c5;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            display: table;
            float: left;
            margin-top: 5px;
            margin-right: 7px; } }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin: 0;
          > span {
            font-size: 17px;
            line-height: 21px;
            &.date {
              min-width: 110px;
              display: inline-block; } } } } }
    .product-expert-container {
      @media #{$tablet-strict} {
        flex-direction: column; }
      .product-expert-image {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
        background: $c3;
        display: block; }
      .product-expert-description {
        h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: #1d1d1d;
          > a {
            color: #1d1d1d;
            @media #{$tablet-strict} {
              white-space: nowrap; }
            &:hover, &:focus {
              color: #1d1d1d;
              transition: 0.3s all;
              text-decoration: underline; } } }
        > a {
          @media #{$tablet-strict} {
            white-space: nowrap; } }
        h5 {
          color: $c5;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          text-transform: uppercase; }
        > div {
          color: $c5;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.14px;
          line-height: 17px; }
        > a {
          font-size: 14px;
          margin-top: 12px;
          line-height: 32px;
          color: $c5;
          font-weight: 700;
          float: right;
          padding-right: 40px;
          position: relative;
          &:after {
            content: "";
            width: 18px;
            height: 15px;
            top: 8px;
            position: absolute;
            right: 8px;
            transition: all 0.3s;
            background: url("../../assets/images/arr-black.png"); }
          &:hover {
            transition: all 0.3s;
            &:after {
              right: 0; } } } } }
    .webinar-paid-benefit-container {
      display: flex;
      flex-wrap: wrap;
      @media #{$tablet-strict} {
        flex-direction: column; }
      .webinar-benefit-item {
        width: 50%;
        display: flex;
        margin: 15px 0;
        @media #{$tablet-strict} {
          width: 100%;
          img {
            margin-right: 15px; } }
        img {
          width: 50px;
          margin-right: 10px; } } }
    .webinar-content-benefits-items {
      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
      margin-top: 30px;
      color: $c2;
      .content-benefit-item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        p {
          margin-bottom: 0; }
        .img-wrapper {
          min-width: 50px;
          margin-right: 20px; } } }
    .webinar-btn {
      color: $c5;
      margin: 40px auto;
      &:after {
        background: url("../../assets/images/arr-black.png"); } } }
  .webinar-form-container {
    .webinar-offer-container {
      border: 1px solid $c5;
      background-color: $c5;
      border-radius: 9px;
      color: #fff;
      box-shadow: 0 0 9px #2ee3a9;
      padding: 30px;
      margin-bottom: 15px;
      h4 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 32px;
        font-size: 18px;
        font-weight: 400; }
      h2 {
        line-height: 24px;
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 80px; }
      p {
        margin: 12px 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 15px;
        &.offer-item {
          font-size: 15px;
          line-height: 20px;
          margin: 4px 0; } }
      h3 {
        text-align: right;
        font-size: 18px;
        padding-bottom: 4px;
        border-bottom: 1px solid #fff;
        margin: 20px 0;
        > small {
          font-size: 14px; }
        > span {
          font-size: 32px;
          display: inherit;
          > strong {
            font-size: 51px;
            font-weight: 500;
            letter-spacing: 2.55px; }
          > small {
            font-size: 15px; } } }
      .webinar-paid-price-container {
        text-align: right;
        h2, h3 {
          span {
            font-weight: 400; } }
        h2 {
          font-size: 32px;
          span {
            font-size: 15px; } }
        h3 {
          font-size: 14px;
          span {
            display: inline;
            font-size: 14px; } } }
      .webinar-btn {
        margin: 30px auto 10px; }
      &.product-offer {
        margin-top: 25px;
        h4 {
          font-size: 16px; }
        .benefit-items {
          .item {
            display: flex;
            font-size: 15px;
            margin-bottom: 12px;
            img {
              margin-right: 10px; } } }
        .price-container {
          text-align: center;
          font-size: 19px;
          margin: 26px 0 32px;
          span {
            font-size: 16px;
            strong {
              font-size: 38px;
              font-weight: 600; } } }
        .small-details {
          font-size: 14px;
          line-height: 15px;
          margin-top: 28px; } } }
    .webinar-form-container-content {
      box-shadow: 0 0 9px #2ee3a9;
      border: 1px solid $c5;
      background-color: $c5;
      border-radius: 9px;
      padding: 30px;
      h2 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.16px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 20px;
        span {
          font-weight: 700; } }
      form {
        .form-group {
          margin-bottom: 15px;
          position: relative;
          .invalid-feedback {
            position: absolute;
            left: 24px;
            top: 24px; }
          input, select {
            border-radius: 25px;
            border: 1px solid #707070;
            background-color: #ffffff;
            height: 31px;
            padding: 7px 22px 6px; }
          select {
            color: #656565;
            padding-left: 21px;
            font-size: 17px;
            padding-top: 2px;
            &[name='timetableId'] {
              margin-bottom: 20px; } }
          > label {
            color: #ffffff;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0.12px;
            line-height: 14px;
            input {
              display: table;
              float: left;
              height: 16px;
              margin-right: 20px;
              width: 16px; }
            div {
              display: table;
              span {
                color: red; }
              a {
                color: #ffffff;
                text-decoration: underline; } }
            input[name="marketingAgreement"] ~ .invalid-feedback {
              top: inherit;
              bottom: -6px; }
            .invalid-feedback {
              font-size: 13px;
              margin-left: 12px;
              @media #{$desktop-strict} {
                top: 40px; }
              @media #{$tablet-strict} {
                top: 24px; } } } }
        .webinar-btn {
          background: $c4;
          color: $c5;
          margin: 0 auto;
          &:after {
            background: url("../../assets/images/arr-black.png") no-repeat; } } } }
    .webinar-help-container {
      margin: 25px 0;
      box-shadow: 0 3px 6px #d9d9d9;
      border-radius: 9px;
      border: 1px solid $c5;
      background-color: #ffffff;
      padding: 57px 120px 29px 33px;
      @media #{$desktop-strict} {
        padding: 40px 95px 30px 33px; }
      h2 {
        color: #1d1d1d;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        margin: 0 0 10px;
        position: relative;
        &:after {
          width: 89px;
          height: 84px;
          background: url("../../assets/images/webinar-help.png") no-repeat;
          content: '';
          position: absolute;
          right: -90px;
          top: 20px; } }
      p {
        color: #656565;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.11px;
        line-height: 14px;
        margin: 0 0 20px; }
      h3 {
        color: $c5;
        font-size: 22px;
        font-weight: 700;
        margin: 0;
        position: relative;
        padding-left: 30px;
        line-height: 22px;
        &:before {
          content: "";
          width: 25px;
          height: 25px;
          background: url("../../assets/images/green-phone.png") no-repeat;
          background-size: contain!important;
          position: absolute;
          left: 0;
          top: 0; } } }
    .webinar-purchase-container {
      text-align: center;
      color: $c1;
      background: #FFFFFF;
      box-shadow: 0 3px 6px #2EE3A9;
      border: 1px solid #232936;
      border-radius: 9px;
      padding: 30px 30px 40px;
      margin-bottom: 40px;
      h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 25px; }
      p {
        letter-spacing: 0.17px;
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 30px;
        line-height: 22px;
        strong {
          font-weight: 700;
          white-space: nowrap; } }
      .webinar-list-item-btn {
        position: relative;
        border: 2px solid $c5;
        color: $c5;
        background: #ffffff;
        font-weight: 700;
        border-radius: 25px;
        padding: 8px 60px 8px 30px;
        @media #{$phone-strict} {
          padding: 8px 44px 8px 12px; }
        &:after {
          content: "";
          position: absolute;
          right: 20px;
          bottom: 11px;
          width: 17px;
          height: 14px;
          transition: all 0.5s;
          background: url("../../assets/images/arr-black.png") no-repeat; }
        &:hover {
          transition: 0.3s all;
          padding-left: 20px;
          padding-right: 70px;
          &:after {
            right: 30px; } } } } }
  &.webinar-video-details-section {
    .webinar-details-container {
      margin-top: 25px;
      .webinar-details-container-content {
        h4 {
          margin-bottom: 40px;
          font-weight: 600; }
        .patronage {
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-transform: uppercase; } } } } }
.benefits-check-green {
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 18px;
    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 20px;
      background: url("../../assets/images/benefit-icon2.svg") no-repeat;
      left: 0;
      top: 3px;
      background-size: contain; } } }

.product-image-wrapper {
  img {
    width: 100%;
    border-radius: 25px;
    @media #{$desktop} {
      height: 470px;
      object-fit: cover; }
    @media #{$phone-strict} {
      min-height: 220px;
      object-fit: cover; } }
  &.prodcut-image-access {
    .vimeo-navigation-controls {
      justify-content: space-between;
      margin-bottom: 20px;
      .btn {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.65px;
        line-height: 20px;
        padding: 8px 35px 8px 12px;
        border-radius: 22px;
        transition: 0.3s all;
        position: relative;
        color: #fff;
        background: transparent;
        border: 2px solid white;
        text-transform: none;
        &:after {
          background: url("../../assets/images/arr-white.svg") no-repeat;
          position: absolute;
          content: "";
          width: 18px;
          height: 15px;
          right: 8px;
          top: 11px;
          transition: all 0.3s; }
        &:focus, &:hover, &:active {
          color: #000;
          background: $c4;
          border-color: $c4;
          transition: 0.3s all;
          &:after {
            background: url("../../assets/images/arr-black.png") no-repeat; } }
        &:focus {
          outline: none;
          box-shadow: none; }
        &.disabled {
          opacity: 0.5;
          &:focus, &:hover {
            background: transparent;
            color: white;
            border-color: white;
            opacity: 0.5; } } }

      .float-left {
        .btn {
          padding: 8px 12px 8px 35px;
          &:after {
            background: url("../../assets/images/arr-white.svg") no-repeat;
            position: absolute;
            content: "";
            width: 18px;
            height: 15px;
            left: 8px;
            top: 11px;
            transition: all 0.3s;
            transform: rotate(180deg); }
          &:focus, &:hover, &:active {
            &:after {
              background: url("../../assets/images/arr-black.png") no-repeat; } } } } } }
  &.product-image-denied {
    position: relative;
    .webinar-image-badge {
      position: absolute;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 32px;
      font-weight: 500;
      margin-top: 45px;
      z-index: 2;
      @media #{$phone-strict} {
        font-size: 12px;
        margin-top: 16px;
        line-height: 26px; }
      .webinar-image-badge-type {
        color: #ffffff;
        background: #000A48;
        margin-bottom: 3px;
        padding: 0 22px 0 26px; }
      .webinar-image-badge-category {
        color: $c5;
        background: #FFFF00;
        padding: 0 22px 0 26px;
        display: table;
        &.badge-hr-kadry {
          background: #F1B5A6;
          color: #000; }
        &.badge-kadry-prawo-pracy {
          background: #6D74B9;
          color: #fff; }
        &.badge-ksiegowosc {
          background: #F8CF76;
          color: #000; }
        &.badge-ochrona-danych-osobowych {
          background: #F2EB61;
          color: #000; }
        &.badge-oswiata {
          background: #4AA5D8;
          color: #fff; }
        &.badge-srodowisko {
          background: #4BA8A7;
          color: #fff; }
        &.badge-transport {
          background: #2EE3A9;
          color: #000; }
        &.badge-zamowienia-publiczne {
          background: #A7BEEB;
          color: #000; } } }
    .opacity-background {
      height: 100%;
      width: 100%!important;
      position: absolute;
      background: rgba(0, 0, 0, 0.67);
      border-radius: 28px;
      z-index: 1;
      @media #{$tablet-strict} {
        width: calc(100% - 30px); } }
    img {
      border-radius: 32px; }
    h3 {
      position: absolute;
      z-index: 2;
      width: 70%;
      left: 15%;
      text-align: center;
      top: 45%;
      @media #{$phone-strict} {
        top: auto;
        bottom: 15px;
        font-size: 16px; } }
    &:after {
      content: '';
      z-index: 2;
      width: 230px;
      left: 10px;
      bottom: 10px;
      background: url('https://educado.pl/logo-white.svg') no-repeat;
      position: absolute;
      height: 60px;
      @media #{$phone-strict} {
        display: none; } } } }
