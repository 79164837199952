//Przycisk powrotu do listy ekspertów

.expert-index-return-btn {
  background: $c2;
  opacity: 0.7;
  padding: 12px 0;
  margin-bottom: 40px;
  a {
    display: block;
    padding-left: 16px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 6px;
      background: url("../../assets/images/arr-back.svg") no-repeat;
      width: 6px;
      height: 12px; }
    &:focus, &:hover {
      text-decoration: underline; } } }

//  sekcja opisu autora

.expert-index-description-container {
  .card {
    border: none;
    overflow: hidden;
    @media #{$desktop} {
      min-height: 300px; }
    .expert-index-image {
      >img {
        width: 100%;
        //height: 100%
        max-height: 300px;
        object-fit: cover; }
      @media (max-width: 575px) {
        max-height: 240px; } }

    .expert-index-content {
      .card-body {
        h5 {
          color: #a5a5a5;
          font-size: 14px;
          letter-spacing: 0.7px;
          line-height: 24px;
          text-transform: uppercase;
          &.expert-title {
            color: $c3;
            line-height: 32px;
            margin-bottom: 15px; } }
        h1 {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 8px; }
        .card-text {
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0.15px;
          line-height: 20px; } } } } }

.expert-products {
  margin-bottom: 100px;
  @media #{$phone-strict} {
    margin-bottom: 40px; }
  .headline-small {
    padding: 50px 0 6px; } }
