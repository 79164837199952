//kolor główny tekstu
$c1: #1d1d1d;

//kolor nagłówków
$c2: #304471;

//kolor leadow
$c3: #656565;

//kolor bg CTA

$c4: #2ee3a9;

//kolor głowny webinarów

$c5: #232936;


