.search-container {
  padding-bottom: 100px;
  .search-main-badge {
    background-color: $c5;
    padding: 20px 0 30px;
    h4 {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      line-height: 24px;
      margin-bottom: 16px;
      @media #{$tablet-strict} {
        text-align: center; } }
    .search-form {
      .form-inline {
        border-radius: 8px;
        @media #{$tablet-strict} {
          justify-content: center; }
        .form-control {
          color: $c1;
          font-size: 24px;
          line-height: 1em;
          border-radius: 12px 0 0 12px;
          border: 1px solid #c4c4c4;
          height: 50px;
          border-right: none;
          width: 310px;
          padding: 11px 24px;
          @media #{$phone-strict} {
            width: 200px; }
          @media #{$tablet-strict} {
            height: 40px; } }
        .btn {
          border-radius: 0 12px 12px 0;
          background-color: #7e90ff;
          border: 1px solid #7e90ff;
          font-size: 20px;
          height: 50px;
          @media #{$tablet-strict} {
            height: 40px; }
          &:focus, &:hover {
            background-color: #2ee3a9;
            border: 1px solid #2ee3a9;
            color: #fff; } } } } }
  .search-main-content {
    flex-direction: column;
    .box-filters-container {
      margin-bottom: 16px;
      .box-filters {
        margin-top: 0; }
      .filter-modal-btn {
        display: none; }
      @media #{$tablet-strict} {
        > .box-filters {
          display: none; }
        > .filter-modal-btn {
          display: block;
          color: $c2;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.7px;
          line-height: 24px;
          padding: 28px 30px;
          border: none;
          background: transparent;
          position: relative;
          &:before {
            position: absolute;
            width: 16px;
            height: 16px;
            content: "";
            left: 3px;
            top: 31px;
            background: url("../../assets/images/filter-icon.svg") no-repeat; }
          &:focus, &:hover {
            text-decoration: underline;
            outline: none;
            box-shadow: none; } } } } }
  .search-results {} }

#filters-modal {
  .box-filters {
    background: transparent;
    flex-direction: column;
    margin-top: 0;
    .login-btn {
      padding: 5px 15px;
      text-align: left; } } }
