//banner z tytułem
.expert-list-banner {
  background: $c5;
  color: #ffffff;
  padding: 30px 0;
  .head-title h1 {
    font-size: 32px;
    line-height: 42px;
    padding-top: 24px;
    @media #{$phone-strict} {
      margin-bottom: 25px;
      text-align: center; } }
  .head-description {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 20px;
    padding-right: 80px;
    @media #{$tablet-strict} {
      padding-right: 30px;
      text-align: center; } } }

//Lista ekspertów

.expert-list-item {
  margin-bottom: 32px!important; }
.expert-content-item {
  display: block;
  height: 360px;
  background: #fff;
  overflow: hidden;
  @media #{$phone-strict} {
    height: auto; }
  .expert-item-img {
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: center center;

    @media #{$desktop-strict} {
      height: 160px; } }
  .expert-item-text {
    text-align: center;
    padding: 25px 20px;
    position: relative;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > h4 {
      color: $c1;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px; }
    > p {
      color: $c3;
      font-size: 14px;
      letter-spacing: 0.7px;
      line-height: 18px;
      text-transform: uppercase;
      max-height: 54px;
      overflow: hidden;
      display: flex;
      flex: 1 1 auto;
      justify-content: center; }
    > button {
      bottom: 0;
      border-radius: 16px;
      border: 2px solid $c5;
      color: $c5;
      opacity: 0.6;
      background: #ffffff;
      padding: 2px 32px 2px 17px;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.65px;
      line-height: 20px;
      text-transform: uppercase;
      width: 212px;
      margin: 0 auto;
      &:after {
        background: url("../../assets/images/arr-expert-list.svg") no-repeat;
        width: 8px;
        height: 11px;
        right: 13px;
        content: "";
        top: 6px;
        position: absolute; }
      &:active, &:focus {
        background: transparent!important;
        color: $c5!important;
        border-color: $c5!important;
        box-shadow: none!important; } } }
  &:focus, &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    .expert-item-text {
      > button {
        opacity: 1;
        box-shadow: none; } } } }


