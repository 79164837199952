footer {
  background-color: $c5;
  color: #fff;
  padding: 45px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  @media #{$phone-strict} {
    text-align: center; }
  .footer-logo-container {
    display: flex;
    gap: 16px;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 24px;
    padding-bottom: 20px;
    @media #{$desktop} {
      gap: 56px; }
    @media #{$phone-strict} {
      flex-direction: column;
      border-bottom: none; } }
  .footer-data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media #{$tablet} {
      flex-direction: row;
      flex-wrap: wrap; }
    .contact-info {
      display: flex;
      flex-direction: column;
      @media #{$tablet-strict} {
        margin-top: 20px; }
      .item {
        display: flex;
        flex-direction: row;
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        gap: 16px;
        line-height: 24px;
        @media #{$phone-strict} {
          justify-content: center;
          align-items: center;
          flex-direction: column; }
        svg {
          width: 24px;
          height: 24px;
          color: $c4; }
        a {
          color: #fff;
          &:hover {
            color: #fff;
            text-decoration: underline; } } } }
    .footer-navi-container {
      margin: 20px 0;
      @media #{$phone-strict} {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        margin: 10px 0;
        padding-top: 10px; }
      > a, > p, > p > a {
        display: block;
        color: #ffffff;
        font-size: 13px;
        line-height: 32px;

        &.footer-item {
          white-space: nowrap; }
        @media #{$phone-strict} {
          line-height: 38px;
          font-size: 13px; } }
      > p, > p > a {
        @media #{$phone-strict} {
          line-height: 22px; } }
      a {
        &:hover, &:focus {
          text-decoration: underline;
          color: #b0c5ed; } } }
    .footer-company-data {
      display: flex;
      flex-direction: column;
      .footer-social-media {
        > a {
          margin-right: 10px; } }
      p {
        line-height: 18px!important;
        padding-top: 6px; }
      @media #{$tablet} {
        display: flex;
        justify-content: flex-end; }
      @media #{$tablet-strict} {
        white-space: nowrap; } } } }
