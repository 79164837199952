.slide {
  margin-top: 40px;
  height: 100%;
  .carousel-indicators {
    bottom: 30px;
    @media #{$phone-strict} {
      margin-bottom: 5px; }
    > li {
      width: 75px;
      height: 7px;
      border: 1px solid $c5;
      background: transparent;
      border-radius: 5px;
      margin: 0 23px 0 0;
      &:last-of-type {
        margin: 0; }
      &.active {
        background: $c5; } } }
  .carousel-inner {
    @media #{$phone-strict} {
      overflow: visible;
      min-height: 725px; }
    .carousel-item {
      .slider-img {
        height: 400px;
        object-fit: cover;
        @media #{$phone-strict} {
          height: auto; }
        @media #{$tablet-strict} {
          height: auto; } }
      .carousel-caption {
        text-align: left;
        top: 80px;
        left: 65px;
        max-height: 210px;
        overflow: hidden;
        @media #{$tablet-strict} {
          top: 40px; }
        @media #{$phone-strict} {
          top: 10%;
          max-height: 255px;
          h4 {
            font-size: 24px;
            text-align: center; }
          p {
            font-size: 14px!important;
            display: none; } }
        h4 {
          color: #ffffff;
          font-size: 32px;
          font-weight: 700;
          line-height: 32px;
          @media #{$phone-strict} {
            font-size: 24px; } }
        p {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.16px;
          line-height: 22px;
          max-width: 470px;
          @media #{$tablet-strict} {
            display: none; } } }
      .btn-slider {
        background: #fff;
        min-width: 200px;
        color: #2543fd;
        border-radius: 30px;
        text-align: left;
        padding: 5px 20px;
        font-weight: 500;
        position: absolute;
        bottom: 65px;
        left: 65px;
        z-index: 99;
        border: none;
        line-height: 30px;
        @media #{$phone-strict} {
          $a: 50%;
          $b: 100px;
          left: calc(#{$a} - #{$b});
          bottom: -65px;
          border: 2px solid #2543fd; }
        &:after {
          background: url("../../assets/images/arr-blue.svg") no-repeat;
          top: 13px; } } } }
  .carousel-control-prev, .carousel-control-next {
    width: 65px; }
  .carousel-control-prev {
    .carousel-control-prev-icon {
      background: url("../../assets/images/prev-slider.svg") no-repeat;
      width: 13px;
      height: 23px; } }
  .carousel-control-next {
    .carousel-control-next-icon {
      background: url("../../assets/images/next-slider.svg") no-repeat;
      width: 13px;
      height: 23px; } } }

.home-slider-container {
  margin-bottom: 48px;
  &.alternative {
    margin-bottom: 10px; }
  @media #{$desktop} {
    height: auto!important;
    min-height: 400px; }
  @media #{$tablet-strict} {
    height: auto!important; }
  @media #{$phone-strict} {
    padding: 0;
    margin-bottom: 40px;
    height: auto!important;
    .slide {
      margin-top: 0!important;
      .carousel-control-prev, .carousel-control-next {
        display: none; } } }
  //zmiana slideów na blur
  .carousel-item {
    opacity: 0;
    transition-duration: .6s!important;
    transition-property: opacity; }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    opacity: 1; }

  .active.carousel-item-left,
  .active.carousel-item-right {
    opacity: 0; }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active,
  .active.carousel-item-left,
  .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0); } }


.home-slider-container {
  color: #ffffff;
  min-height: 713px;
  @media #{$phone-strict} {
 }    //background: url("../../assets/images/falka-mobile.svg") no-repeat
  .carousel-control-prev, .carousel-control-next {
    display: none; }
  svg {
    fill: #232936;
    position: absolute;
    left: 0;
    width: 100%;
    height: 630px;
    @media #{$desktop-strict} {
      height: 680px; }
    &.dekstop {
      @media #{$phone-strict} {
        display: none; } }
    &.mobile {
      display: none;
      @media #{$phone-strict} {
        display: block; } } }
  .item-image-container {
    @media #{$phone-strict} {
      display: none; } }
  .item-content-container {
    font-family: "Red Hat Display", sans-serif;
    padding-top: 30px;
    @media #{$phone-strict} {
      padding: 50px 70px; }
    @media (max-width: 400px) {
      padding: 25px; }
    .item-content {
      margin-top: 45px;
      @media #{$phone-strict} {
        margin-top: 20px; }
      .slider-main-header {
        font-family: "Red Hat Display", sans-serif;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 700;
        display: table;
        border-bottom: 2px solid #898D96;
        padding-bottom: 10px;
        margin-bottom: 12px;
        @media #{$phone-strict} {
          margin: 0 auto 30px; }
        span {
          color: $c4;
          font-weight: 400;
          padding-left: 5px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            right: -25px;
            top: 6px;
            width: 20px;
            height: 20px;
            background-size: contain!important; }
          &.live {
            &:after {
              background: url("../../assets/images/webinar-circle-head.png") no-repeat; } }
          &.vod {
            &:after {
              background: url("../../assets/images/webinar-square-head.png") no-repeat; } } } }
      p {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.6px;
        font-weight: 300;
        margin-bottom: 25px; }
      .webinar-btn {
        background: $c4;
        color: $c5;
        width: 229px;
        padding-left: 27px;
        @media #{$phone-strict} {
          margin: 0 auto 30px; }
        &:hover {
          padding-left: 20px; }
        &:after {
          background: url("../../assets/images/arr-black.png") no-repeat; } }
      &.item-content-list {
        margin-top: 0;
        h2 {
          margin-bottom: 20px;
          @media #{$desktop-strict} {
            font-size: 21px; }
          @media #{$tablet-strict} {
            font-size: 19px; }
          img {
            width: 160px;
            @media #{$desktop-strict} {
              width: 140px; } }
          span {
            display: block;
            text-transform: none;
            color: #fff;
            font-weight: 700;
            text-align: center;
            font-size: 18px;
            padding-left: 0; } }
        .list-item {
          font-size: 18px;
          color: #F0F2F6;
          font-weight: 300;
          display: flex;
          align-items: center;
          position: relative;
          line-height: 20px;
          @media #{$phone-strict} {
            text-align: left; }
          @media #{$tablet-strict} {
            font-size: 15px; }
          img {
            width: 46px;
            height: 46px;
            object-fit: contain;
            margin-right: 10px; } }
        h5 {
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 0.12px;
          line-height: 15px;
          margin-bottom: 20px;
          @media #{$tablet-strict} {
            padding-right: 10px; } }
        .webinar-btn {
          float: right;
          @media #{$tablet-strict} {
            float: left; }
          @media (min-width: 1700px) {
            float: left; }
          @media #{$phone-strict} {
            margin: 20px auto 30px;
            float: initial; } } } }
    &.item-category-content {
      .item-content {
        p {
          display: flex;
          align-items: center;
          img {
            width: 48px;
            margin-right: 20px; }
          &.item-3 {
            img {
              width: 53px;
              margin-right: 15px; } } } } }
    &.home-closed-training-content {
      .item-content {
        margin-top: 30px;
        @media #{$tablet-strict} {
          margin-top: 0;
          padding-right: 12px;
          .slider-header {
            font-size: 40px; } }
        .webinar-btn {
          @media #{$desktop-strict} {
            float: right; }
          @media #{$tablet-strict} {
            float: none; } }
        ul {
          li {
            @media #{$desktop-strict} {
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 18px; } } } } } } }
