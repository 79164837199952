.form-group {
  margin-bottom: 28px;
  display: flex;
  input, select {
    border-radius: 12px;
    border: 2px solid #c4c4c4;
    color: $c1;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.65px;
    line-height: 32px;
    height: 40px; } }
.recaptcha-container {
  margin-bottom: 40px;
  text-align: center; }
.pass-reminder {
  color: $c2;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  display: block;
  text-decoration: underline;
  border: none;
  background: transparent;
  margin: 30px auto; }

