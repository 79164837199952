.box-filters {
  background-color: #ffffff;
  display: flex;
  margin-top: 70px;
  padding-right: 34px;
  padding-left: 20px;
  flex-wrap: wrap;
  @media #{$phone-strict} {
    flex-direction: column; }
  .col {
    .checkbox-input {
      padding: 24px 30px;
      cursor: pointer;
      @media #{$tablet-strict} {
        padding: 24px 10px; }
      .custom-control-input {
        &:focus, &:hover {
          outline: none; } }
      label {
        color: $c3;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.65px;
        line-height: 20px;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        &:before {
          border: 2px solid #304471!important;
          background: #304471;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          top: 0;
          left: -36px;
          opacity: 0.6;
          box-shadow: none; } }
      &.active {
        .custom-control-label {
          &:after {
            width: 10px;
            height: 12px;
            background-size: cover;
            top: 6px;
            left: -30px;
            background-image: url("../../assets/images/checkbox.png"); } } }
      &.no-active {
        .custom-control-label {
          &:before {
            background: transparent; } } } } }
  .btn {
    display: flex;
    align-self: center;
    border-radius: 12px;
    background-color: #304471;
    opacity: 0.6;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 0.65px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 2px 10px;
    @media #{$tablet-strict} {
      margin-bottom: 15px; }
    &:after {
      display: none; } } }

//Filtry przy webinarach
.filters-headline {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin: 25px 0;
  @media #{$phone-strict} {
    margin: 0; } }
.filter-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 45px;
  span {
    line-height: 32px;
    margin-right: 20px;
    margin-top: 15px;
    @media #{$phone-strict} {
      display: block;
 } }      //width: 100%
  .btn-filter {
    margin-right: 15px;
    margin-top: 15px;
    border: 1px solid $c5;
    color: $c5;
    font-size:  14px;
    line-height: 30px;
    border-radius: 22px;
    padding: 0 20px;
    background: white;
    white-space: nowrap;
    transition: all 0.3s;
    &.modal-filters-control {
      min-width: 214px;
      @media #{$phone-strict} {
        text-align: center; } }
    &:hover, &.active {
      background: $c4;
      transition: all 0.3s;
      border-color: $c5; }
    &.cat-71 {
      &:hover, &.active {
        border-color: $c5; }
      &.active {
        background: #4AA5D8!important; }
      &:hover {
        background: rgba(74, 165, 216, 0.30);
        box-shadow: 0px 8px 16px 0px rgba(58, 74, 119, 0.16); } }
    &.cat-14, &.cat-13 {
      &:hover, &.active {
        border-color: $c5; }
      &.active {
        background: #FDD26E!important; }
      &:hover {
        background: rgba(253, 210, 110, 0.30);
        box-shadow: 0px 8px 16px 0px rgba(58, 74, 119, 0.16); } } } }

.filter-modal {
  .filter-container {
    .btn {
      margin-right: 0;
      width: 100%;
      padding: 5px 15px;
      text-align: center;
      max-width: 250px; } } }
