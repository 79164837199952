.vp-center {
  align-items: flex-start!important; }
.product-container-wrapper {
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 40px;
  @media #{$phone-strict} {
    padding: 0; }
  .scroll-top {
    border-radius: 50%;
    background: $c4;
    position: absolute;
    bottom: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    right: 0;
    height: 38px;
    width: 38px;
    border: none;
    padding: 0;
    color: #000;
    text-align: center;
    transition: 0.3s all;
    svg {
      stroke: #000000;
      stroke-width: 1px; }
    &:hover {
      transition: 0.3s all;
      opacity: 0.6; } }
  .product-container-bg {
    color: #fff;
    background: linear-gradient(178.02deg, #242936 26.54%, #FDD26E 156.41%);
    &.oswiata {
      background: linear-gradient(178.02deg, #242936 26.54%, #32509F 156.41%); }
    iframe {
      max-width: 100%; } }
  .product-data {
    color: #fff;
    h1 {
      line-height: 36px;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
      @media #{$large} {
        line-height: 50px;
        font-size: 36px; } }
    h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      &.webinar-duration {
        display: flex;
        gap: 16px;
        margin-top: 28px;
        align-items: center; }
      &.is-archive {
        margin-bottom: 28px;
        position: relative;
        padding-left: 24px;
        &:before {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: red;
          content: "";
          left: 6px;
          top: 6px;
          position: absolute; }
        &.active {
          &:before {
            background: $c4; } } } }
    .product-online-btn-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 40px;
      @media #{$large} {
        flex-direction: row; }
      .educado-btn {
        font-size: 14px;
        padding: 8px 35px 8px 12px;
        max-width: 200px;
        margin-top: 12px;
        &:after {
          top: 11px;
          right: 8px; } } } }
  .webinar-details-container {
    .product-details-container {
      margin-top: 24px; }
    ul {
      li {
        &::marker {
          font-size: 26px;
          color: #FDD26E; } } }
    &.oswiata {
      ul {
        li {
          cursor: pointer;
          &:hover {
            text-decoration: underline; }
          &::marker {
            font-size: 26px;
            color: #4AA5D8; } } } } }
  .product-details-container {
    background: #fff;
    border: 1px solid rgba(35, 41, 54, 0.6);
    border-radius: 20px;
    padding: 30px;
    color: #000;
    font-size: 16px;
    margin-top: 70px;
    line-height: 24px;
    h3, h4 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 24px; }
    ol, ul {
      margin-bottom: 0; }
    p {}
    .kik {
      ul {
        li {
          &::marker {
            font-size: 22px;
            color: #FDD26E; } } } }
    .oswiata {
      ul {
        li {
          &::marker {
            font-size: 22px;
            color: #4AA5D8; } } } }
    h4 {
      margin-top: 24px; }
    .btn-media {
      width: 31px;
      height: 31px;
      background: url("../../assets/images/share.svg") no-repeat;
      opacity: 0.2;
      background-size: contain;
      border: none;
      padding: 0;
      &:focus, &:hover {
        opacity: 1; } }
    .product-benefits-container {
      list-style: none;
      padding-left: 0;
      li {
        display: flex;
        flex-direction: row;
        gap: 16px; } }
    .product-content-sheets-button-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 48px;
      margin-top: 24px;
      @media #{$phone-strict} {
        flex-direction: column;
        .educado-btn-black {
          width: 220px; } }
      .login-link {
        margin-bottom: 0;
        background: transparent;
        border-color: #000;
        width: 220px;
        &:hover {
          background-color: $c4;
          border-color: $c4; } }
      .educado-btn {
        padding: 8px 40px 8px 20px;
        font-weight: 700;
        background-color: $c4;
        border-color: $c4;
        &:hover {
          background-color: transparent; }
        &:after {
          top: 11px; } } }
    #quiz {
      .webinar-btn {
        background: #2ee3a9;
        color: #000;
        border: 2px solid #2ee3a9;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        padding: 12px 40px 12px 20px;
        transition: 0.3s all;
        border-radius: 32px;
        position: relative;
        &:after {
          top: 13px;
          background: url("../../assets/images/arr-black.png") no-repeat; }

        &:hover {
          border-color: #2ee3a9;
          background: transparent; } } }
    .product-expert-container {
      @media #{$tablet-strict} {
        flex-direction: column; }
      .product-expert-image {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
        background: $c3;
        display: block; }
      .product-expert-description {
        h3 {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          > a {
            color: #1d1d1d;
            @media #{$tablet-strict} {
              white-space: nowrap; }
            &:hover, &:focus {
              color: #1d1d1d;
              transition: 0.3s all;
              text-decoration: underline; } } }
        > a {
          @media #{$tablet-strict} {
            white-space: nowrap; } }
        h5 {
          color: $c5;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px; }
        p {
          color: $c5;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          text-transform: capitalize; }
        > div {
          color: $c5;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.14px;
          line-height: 17px; }
        > a {
          font-size: 14px;
          margin-top: 12px;
          line-height: 32px;
          color: $c5;
          font-weight: 700;
          padding-right: 40px;
          position: relative;
          &:after {
            content: "";
            width: 18px;
            height: 15px;
            top: 8px;
            position: absolute;
            right: 8px;
            transition: all 0.3s;
            background: url("../../assets/images/arr-black.png"); }
          &:hover {
            transition: all 0.3s;
            &:after {
              right: 0; } } } } } }
  .product-online-right {
    .product-online-right-content {
      padding: 10px;
      border-radius: 20px;
      background: white;
      border: 1px solid rgba(35, 41, 54, 0.6);
      @media #{$tablet-strict} {
        margin-top: 40px; }
      img {
        border-radius: 10px; }
      .button-wrapper {
        padding: 0 24px;
        a {
          margin: 30px 0;
          width: 100%;
          &:hover {
            color: #000;
            &:after {
              background: url("../../assets/images/arr-black.png") no-repeat; } } } }
      .benefit-container {
        border-top: 1px solid rgba(35, 41, 54, 0.6);
        border-bottom: 1px solid rgba(35, 41, 54, 0.6);
        padding: 24px 24px 8px;
        ul {
          list-style: none;
          padding-left: 0;
          margin-top: 16px!important;
          li {
            display: flex;
            flex-direction: row;
            line-height: 21px;
            font-size: 15px;
            font-weight: 400;
            gap: 16px;
            margin-bottom: 0; } } }
      .offer-container {
        padding: 24px;
        h2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px; }
        .multi {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          svg {
            width: 100%; }
          p {
            line-height: 21px;
            font-size: 15px;
            font-weight: 400; } }
        .button-wrapper {
          padding: 0;
          button {
            margin: 0;
            width: 100%; } } } } } }
.video-list {
  border-radius: 20px;
  border: 1px solid rgba(35, 41, 54, 0.6);
  background-color: #fff;
  padding: 30px;
  color: #1d1d1d;
  h3 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px; }
  > p {
    font-size: 15px;
    font-weight: 400;
    color: rgba(101, 101, 101, 1);
    margin-bottom: 0; }
  .btn-video-list {
    padding-left: 30px;
    list-style: none;
    padding-top: 12px;
    li {
      margin-bottom: 12px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 19px;
        height: 20px;
        left: -20px;
        top: 2px;
        background: url("../../assets/images/rectangle-green.svg") no-repeat;
        background-size: contain;
        transition: 0.2s all; }
      &:hover {
        &:before {
          left: -17px;
          transition: 0.2s all; } }
      .video-list-item {
        border: none;
        width: 100%;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        background: transparent;
        color: #000;
        &:focus, &:hover {
          text-decoration: underline; } } } } }
.webinar-help-container {
  margin: 25px 0;
  box-shadow: 0 3px 6px #d9d9d9;
  border-radius: 9px;
  border: 1px solid $c5;
  background-color: #ffffff;
  padding: 57px 120px 29px 33px;
  @media #{$desktop-strict} {
    padding: 40px 95px 30px 33px; }
  h2 {
    color: #1d1d1d;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 10px;
    position: relative;
    &:after {
      width: 89px;
      height: 84px;
      background: url("../../assets/images/webinar-help.png") no-repeat;
      content: '';
      position: absolute;
      right: -90px;
      top: 20px; } }
  p {
    color: #656565;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.11px;
    line-height: 14px;
    margin: 0 0 20px; }
  h3 {
    color: $c5;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    position: relative;
    padding-left: 30px;
    line-height: 22px;
    &:before {
      content: "";
      width: 25px;
      height: 25px;
      background: url("../../assets/images/green-phone.png") no-repeat;
      background-size: contain!important;
      position: absolute;
      left: 0;
      top: 0; } } }
