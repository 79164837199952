.index-page-main-container {
  position: relative;
  &:before {
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 18px;
    content: '';
    background: #212632; }
  .container-content-wrapper {
    position: relative;
    z-index: 999;
    .index-background {
      $wide: 1921px;
      @media (min-width: $wide) {
        width: 100vw;
        svg {
          width: 100vw; } } }
    .container-content {
      background: #ECEEF4;
      position: relative;
      padding: 30px 0;
      border-radius: 25px;
      @media #{$phone-strict} {
        left: -30px;
        width: 100vw; }
      @media (min-width: 574px) and (max-width: 767px) {
        left: 0;
        width: 100%; } } } }
