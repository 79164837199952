@import url('https://fonts.googleapis.com/css2?family=Signika:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: Signika, sans-serif;
  font-weight: 400;
  color: $c1; }

.headline-small {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 48px 0 30px 0;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 0;
  @media #{$phone-strict} {
    font-size: 20px;
    padding: 40px 0 0; }
  @media #{$tablet-strict} {
    font-size: 20px; } }
.btn {
  font-size: 16px;
  padding: 5px 15px;
  text-align: left;
  position: relative;
  &.btn-arr {
    &:after {
      content: '';
      background: url("../../assets/images/arr-black.png");
      position: absolute;
      right: 15px;
      width: 18px;
      height: 15px;
      transition: all 0.3s;
      top: 9px; } }
  &:hover, &:focus {
    &:after {
      right: 20px;
      transition: all 0.3s; } } }

.breadcrumb {
  background: transparent;
  padding: 20px 0;
  margin: 0;
  .breadcrumb-item {
    > a {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      line-height: 32px;
      &:after {
        content: "|";
        padding-left: 15px; }
      &:before {
        display: none; } }
    &.active {
      > a {
        &:after {
          display: none; } } } } }

.background-white {
  background: #fff; }
.container-fluid {
  overflow: hidden; }

table, tr, td {
  border: 1px solid $c2; }
td {
  padding: 5px; }

.webinar-main-head {
  color: #1d1d1d;
  font-family: "Red Hat Display", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  width: 100%;
  z-index: 9; }
.webinar-list-headline {
  color: $c2;
  font-family: "Red Hat Display", sans-serif;
  font-size: 27px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  display: table;
  padding: 0 5px 10px;
  border-bottom: 1px solid $c2;
  margin: 0 auto 25px; }
.show-more-btn-container {
  @media #{$phone-strict} {
    margin-top: 0 !important; } }
.show-more {
  float: right;
  position: relative;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: $c5;
  padding-right: 36px;
  &:after {
    content: '';
    background: url("../../assets/images/arr-black.png");
    position: absolute;
    right: 0;
    width: 18px;
    height: 15px;
    transition: all 0.3s;
    top: 5px; }
  &:hover, &:focus {
    transition: all 0.3s;
    color: $c5;
    text-decoration: underline;
    &:after {
      right: 16px;
      transition: all 0.3s; } } }
.buffer-container {
  background: #F0F2F6;
  height: 40px; }

#cookie-modal {
  top: 70px; }
