// Najnowsze Szkolenia


.training-item {
  display: block;
  background: #fff;
  //transition: all 0.3s
  height: 360px;
  position: relative;
  top: 0;
  .training-item-duration {
    position: absolute;
    top: 16px;
    right: 20px;
    background: #fff;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    line-height: 18px;
    color: $c3;
    padding: 5px 8px; }
  .play-icon {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 20px;
    top: 120px;
    background: url("../../assets/images/play-icon.png") center center no-repeat;
    border-radius: 50%;
    transition: all 0.5s;
    z-index: 9; }
  .training-user-item-details {
    position: absolute;
    bottom: 176px;
    width: 100%;
    transition: all 0.3s;
    &.training-item-archive-details {
      bottom: 250px;
      left: 0; }
    > span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.7px;
      line-height: 18px;
      text-transform: uppercase;
      background-color: #304471;
      padding: 5px; }
    .archived-label {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.7px;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      background-color: #e56767;
      display: table;
      padding: 5px; }
    .progress {
      height: 4px;
      background-color: transparent;
      border-radius: 0;
      .progress-bar {
        background-color: #2ee3a9; } } }
  .card-img-top {
    height: 180px;
    object-fit: cover;
    display: block;
    transition: all 0.3s;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .card-body {
    height: 160px;
    overflow: hidden;
    transition: all 0.3s;
    .card-title {
      color: $c2;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      transition: all 0.5s;
      @media #{$tablet-strict} {
        font-size: 16px;
        line-height: 22px; } }
    .training-item-description {
      transition: all 2s;
      display: none;
      color: $c1;
      letter-spacing: 0.15px;
      overflow: hidden;
      font-weight: 300;
      font-size: 15px;
      padding-top: 18px; } }
  .training-item-details {
    transition: all 0.3s;
    color: $c3;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    @media #{$phone-strict} {
      overflow: hidden;
      font-size: 14px; } }
  &:hover {
    top: -6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    //transition: all 0.3s
    .play-icon {
      top: 66px;
      transition: all 0.5s; }
    .card-img-top {
      height: 100px;
      object-fit: cover;
      transition: all 0.3s; }
    .training-user-item-details {
      position: absolute;
      bottom: 256px;
      transition: all 0.3s;
      &.training-item-archive-details {
        bottom: 260px; } }
    .card-body {
      overflow: hidden;
      display: flex;
      height: 250px;
      flex-direction: column;
      .training-item-description {
        display: block;
        transition: all 2s; } }

    .training-item-details {
      display: none;
      transition: all 0.5s; } }
  &.home-training-item {
    height: 430px;
    .card-body-wrapper {
      display: block; }
    .training-item-details {
      bottom: 85px;
      font-weight: 600; }
    .home-product-category-item-container {
      padding: 0 20px;
      position: absolute;
      bottom: 10px;
      left: 0;
      max-height: 72px;
      overflow: hidden;
      .home-product-category-item {
        display: table;
        float: left;
        border: 1px solid #2543FD;
        color: #2543FD;
        padding: 5px 8px;
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 14px;
        text-transform: lowercase;
        &:hover {
          background: #8DA4F1; } } }
    &:hover {
      top: -1px;
      .card-body {
        height: 230px; }
      .home-product-category-item-container {
        bottom: 9px; } } } }



//.training-user-item-details
//  position: absolute
//  top: 132px
//  width: 100%
//  left: 0
//  transition: all 0.3s
//  .progress
//    height: 4px
//    background-color: transparent
//    border-radius: 0
//    .progress-bar
//      background-color: #2ee3a9
