input[type=checkbox] {
  width: 20px!important;
  height: 20px!important;
  accent-color: $c4; }
.educado-btn {
  border: 2px solid #2ee3a9;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 12px 40px 12px 20px;
  transition: 0.3s all;
  border-radius: 32px;
  position: relative;
  &:after {
    content: "";
    width: 18px;
    height: 15px;
    right: 12px;
    top: 15px;
    position: absolute;
    transition: all 0.3s;
    background: url("../../assets/images/arr-white.svg") no-repeat; }
  &:focus, &:hover, &:active {
    outline: none;
    box-shadow: none;
    background: #2ee3a9;
    border-color: #2ee3a9;
    color: #000;
    &:after {
      right: 20px;
      transition: all 0.3s;
      background: url("../../assets/images/arr-black.png") no-repeat; } }
  &:hover {
    transition: 0.3s all; }
  &.educado-btn-full {
    background: #2ee3a9;
    border-color: #2ee3a9;
    color: #000;
    &:after {
      background: url("../../assets/images/arr-black.png") no-repeat; }
    &:hover, &:focus {
      background: transparent;
      color: #fff;
      &:after {
        background: url("../../assets/images/arr-white.svg") no-repeat; } } }
  &.educado-btn-white {
    color: #fff;
    background: transparent;
    border-color: white;
    &:after {
      background: url("../../assets/images/arr-white.svg") no-repeat; }
    &:focus, &:hover, &:active {
      color: #000;
      background: $c4;
      border-color: $c4;
      &:after {
        background: url("../../assets/images/arr-black.png") no-repeat; } } }
  &.educado-btn-wide {
    padding-right: 62px; }
  &.educado-btn-black {
    color: black;
    background: white;
    border-color: black;
    &:after {
      background: url("../../assets/images/arr-black.svg") no-repeat; }
    &:focus, &:hover, &:active {
      color: #000;
      background: $c4;
      border-color: $c4;
      &:after {
        background: url("../../assets/images/arr-black.png") no-repeat; } } } }
.educado-btn-text {
  color: #FFF;
  font-family: Signika, sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  background: transparent;
  line-height: 26px;
  padding: 0 40px 0 0;
  border: none;
  span {
    font-size: 18px;
    font-weight: 700; }
  &:after {
    content: "";
    width: 18px;
    height: 15px;
    right: 12px;
    top: 6px;
    position: absolute;
    transition: all 0.3s;
    background: url("../../assets/images/arr-white.svg") no-repeat; }
  &:hover, &:focus {
    text-decoration: underline;
    color: white;
    outline: none;
    background: transparent;
    &:after {
      transition: all 0.3s;
      right: 16px; } }
  &.black {
    color: #000;
    &:after {
      background: url("../../assets/images/arr-black.png") no-repeat; } } }
.social-media-modal {
  transition: all 0.5s;
  .modal-dialog {
    @media #{$phone-strict} {
      margin: 0; }
    .modal-content {
      border: none;
      margin-top: 100px;
      padding: 20px;
      transition: all 0.5s;
      @media #{$phone-strict} {
        width: 70%;
        padding: 20px 10px;
        height: 100vh; }
      h3 {
        font-weight: 400;
        font-size: 19px; }
      .btn-social-item {
        border: none;
        background: none;
        color: #4e4e4e;
        font-size: 15px;
        font-weight: 600;
        padding: 20px 40px;
        &:focus, &:hover {
          outline: none;
          text-decoration: underline; }
        &:before {
          content: "";
          position: absolute;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: contain!important;
          border-radius: 50%;
          left: 14px;
          top: 15px; }
        i {
          position: absolute;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 15px;
          top: 15px;
          border-radius: 50%;
          svg {
            color: #fff;
            font-size: 16px; } }
        &.btn-facebook {
          &:before {
            background: url("../../assets/images/fb-icon.png"); } }
        &.btn-twitter {
          &:before {
            background: url("../../assets/images/twitter-icon.png"); } }
        &.btn-linkedin {
          &:before {
            background: url("../../assets/images/in-icon.png"); } }
        &.btn-email {
          i {
            background: #fdb300; }
          &:before {
            display: none; } }
        &.btn-copy-to-clipboard {
          i {
            background: #7c7c7c; }
          &:before {
            display: none; } } } } } }
