.ReactModalPortal {
  > div {
    z-index: 9999; } }
.browser-modal {
  text-align: center;
  .modal-content-container {
    background: #fff;
    margin-top: 60px;
    padding-bottom: 40px;
    max-width: 760px;
    h5 {
      width: 100%;
      background: #7E90FF;
      color: #ffffff;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      padding: 26px 40px; }
    p {
      text-align: center;
      width: 100%;
      padding: 24px 100px 0; }
    .btn {
      margin: 20px auto 0;
      background: transparent;
      color: #2543fd;
      border: 1px solid #2543fd;
      padding: 8px 30px;
      border-radius: 27px;
      &:focus, &:hover {
        color: #697EFE;
        border: 1px solid #697EFE; } } } }
