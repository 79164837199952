.profile-main-container {
  position: relative;
  overflow: hidden;
  font-family: "Red Hat Display", sans-serif;
  .profile-container-content {
    padding: 64px 15px;
    > .container {
      @media #{$phone-strict} {
        padding: 0; } }
    @media #{$desktop} {
      padding: 64px 15px; }
    position: relative;
    z-index: 999;
    .main-profile-nav {
      .main-profile-tab {
        padding: 14px 31px 18px 31px;
        margin-right: 8px;
        border-radius: 20px 20px 0 0;
        color: rgba(35, 41, 54, 0.60);
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background: #F0F2F6;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-bottom: none;
        @media #{$tablet-strict} {
          font-size: 14px;
          padding: 14px 8px 18px; }
        @media #{$phone-strict} {
          width: 100%; }
        &.active {
          color: rgba(35, 41, 54, 0.90);
          margin-bottom: -2px;
          @media #{$phone-strict} {
            margin-bottom: 0; }
          text-shadow: 0 0 1px $c5;
          border-top: 1px solid var(--Ciemny-szary, #232936);
          border-right: 1px solid var(--Ciemny-szary, #232936);
          border-left: 1px solid var(--Ciemny-szary, #232936);
          box-shadow: 6px 0px 4px -2px rgba(0, 0, 0, 0.25); }
        &#uncontrolled-tab-example-tab-oswiata {
          &:hover {
            background: rgba(74, 165, 216, 0.30);
            text-shadow: 0px 0px 1px $c5;
            box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
            svg {
              path {
                fill: rgb(74, 165, 216); } } }
          &.active {
            background: rgba(74, 165, 216, 0.60);
            border-bottom: 1px solid #8AC2E4; } }
        &#uncontrolled-tab-example-tab-kik {
          &:hover {
            background: rgba(253, 210, 110, 0.30);
            text-shadow: 0px 0px 1px $c5;
            box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
            svg {
              path {
                fill: #FDD26E; } } }
          &.active {
            background: rgba(253, 210, 110, 0.60);
            border-bottom: 1px solid #F6DDA4; } }
        &#uncontrolled-tab-example-tab-user {
          &:hover {
            background: rgba(35, 41, 54, 0.10);
            text-shadow: 0px 0px 1px $c5;
            svg {
              path {
                stroke: $c4; } } }
          &.active {
            background: white;
            text-shadow: 0px 0px 1px $c5;
            svg {
              path {
                stroke: $c4; } } } } } }
    >.tab-content {
      background: #F7F8FA;
      padding: 45px 25px;
      border-radius: 0 12px 12px;
      border: 1px solid var(--Ciemny-szary, #232936);
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.15);
      @media #{$phone-strict} {
        padding: 24px 12px; }
      .tab-pane {
        >.tab-content {
          background: #fff;
          border-radius: 0 12px 12px 12px;
          padding: 32px 24px;
          border: 1px solid rgba(35, 41, 54, 0.70);
          box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.15);
          .nav-tabs {
            border: none;
            .nav-item {
              color: $c5;
              border: none!important;
              font-weight: 600;
              font-family: Signika, sans-serif;
              font-size: 16px;
              padding: 8px 12px;
              margin-left: 25px;
              margin-right: 0;
              &.active {
                border: none;
                color: $c4; }
              &:hover, &:focus, &:focus-visible {
                border: none;
                color: $c4; } } } }
        >.nav-tabs {
          >.nav-item {
            border-radius: 12px 12px 0 0;
            border-top: 1px solid rgba(35, 41, 54, 0.60);
            border-right: 1px solid rgba(35, 41, 54, 0.60);
            border-left: 1px solid rgba(35, 41, 54, 0.60);
            margin-right: 16px;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            border-bottom: none;
            font-weight: 600;
            color: rgba(35, 41, 54, 0.60);
            gap: 8px;
            @media #{$phone-strict} {
              width: 100%;
              margin-right: 0;
              margin-bottom: 4px; }
            &:hover {
              text-shadow: 0 0 1px $c5;
              box-shadow: 4px 0 4px 0px rgba(0, 0, 0, 0.25); }
            &.active {
              color: $c5;
              text-shadow: 0 0 1px $c5;
              box-shadow: 4px 0 4px 0px rgba(0, 0, 0, 0.25);
              border-top: 1px solid var(--Ciemny-szary, #232936);
              border-right: 1px solid var(--Ciemny-szary, #232936);
              margin-bottom: -2px;
              border-left: 1px solid var(--Ciemny-szary, #232936); }
            &.kik {
              &.active {
                background: rgba(253, 210, 110, 0.30);
                border-bottom: 1px solid #F8ECD0; }
              &:hover {
                background: rgba(253, 210, 110, 0.15); } }
            &.oswiata {
              &.active {
                background: rgba(74, 165, 216, 0.30);
                border-bottom: 1px solid #C2DFF0; }
              &:hover {
                background: rgba(74, 165, 216, 0.15); } } } } } }


    .profile-edit-container {
      background: #ffffff;
      padding: 24px;
      border-radius: 12px;
      border: 1px solid rgba(35, 41, 54, 0.30);
      background: var(--BIAY, #FFF);
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.15);
      font-family: "Red Hat Display", sans-serif;
      @media #{$phone-strict} {
        padding: 24px 8px; }
      .btn-help-container {
        display: flex;
        justify-content: flex-end; }
      .btn-help {
        font-family: Signika, sans-serif;
        font-size: 16px;
        padding: 12px;
        border: 2px solid #000;
        color: #000;
        line-height: 20px;
        border-radius: 32px;
        min-height: 46px;
        display: table;
        max-height: 46px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 24px; }
      &:first-child {
        margin-bottom: 24px; }
      .row {
        gap: 24px;
        &.brands-container {
          flex-direction: row;
          gap: 24px;
          flex-wrap: nowrap;
          max-width: 100%;
          @media #{$phone-strict} {
            flex-direction: column; }
          .profile-settings-subscription-column {
            border-radius: 12px;
            padding: 24px;
            border: 1px solid rgba(35, 41, 54, 0.30);
            background: var(--BIAY, #FFF);
            &.kik {
              h3 {
                background: rgba(253, 210, 110, 0.30); } }
            h3, h5, p {
              font-size: 16px;
              line-height: 22px;
              font-weight: 500; }
            h3 {
              text-transform: uppercase;
              padding: 8px 12px;
              border-radius: 6px;
              margin-bottom: 24px;
              display: table; }
            h5 {
              color: rgba(35, 41, 54, 0.60);
              margin-bottom: 8px; }
            p {
              color: #000; }
            &.oswiata {
              h3 {
                background: rgba(74, 165, 216, 0.30); } } } } }
      #profileUserSave {
        padding-bottom: 30px;
        @media #{$phone-strict} {
          padding-bottom: 0; } }
      .profile-settings-column {
        padding: 24px;
        border-radius: 12px;
        border: 1px solid rgba(35, 41, 54, 0.30);
        background: var(--BIAY, #FFF);
        @media #{$tablet} {
          max-width: calc((100% - 48px) / 3); }
        > .row {
          display: inline-flex;
          flex-direction: row;
          gap: 16px;
          margin-bottom: 24px;
          .col {
            flex-direction: row;
            &:first-child {
              padding: 0 0 0 15px; }
            &:last-child {
              padding: 0;
              @media #{$phone-strict} {
                padding: 0 0 0 15px; } }
            .form-group {
              margin-bottom: 0;
              flex-direction: row;
              label {
                white-space: nowrap;
                margin-bottom: 0; }
              .form-control {
                padding-left: 8px; } } } } }
      h4, h2 {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        padding: 8px 12px;
        display: inline-flex;
        border-radius: 6px;
        background: var(--bg, #F0F2F6);
        margin-bottom: 24px;
        text-transform: uppercase; }
      h2 {
        span {
          line-height: 22px; }
        svg {
          margin-right: 10px; } }
      .form-group {
        border-radius: 12px;
        border: none;
        background-color: #ffffff;
        margin-bottom: 24px;
        flex-direction: column;
        &:last-child {
          margin-bottom: 0; }
        @media #{$phone-strict} {
          margin-bottom: 16px; }
        label {
          font-size: 16px;
          font-weight: 500;
          opacity: 0.5;
          letter-spacing: 0.65px;
          line-height: 22px;
          color: rgba(35, 41, 54, 0.60);
          margin-bottom: 8px; }
        .form-control {
          background: transparent;
          border: none;
          color: $c1;
          padding: 0;
          border-radius: 12px;
          height: initial;
          line-height: 22px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.15px;
          &:focus {
            box-shadow: none;
            border: none; } }
        .form-control::placeholder {
          display: none;
          color: #ffffff; } } } } }






.profile-main-container {
  .btn-light {
    padding: 10px 20px;
    @media #{$desktop} {
      padding: 10px 60px; }
    background-color: #dddddd;
    color: $c2;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.65px;
    line-height: 20px;
    border: none;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
      &.active {
        text-decoration: none; } }
    &.active {
      background: $c5!important;
      color: #fff!important;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: 400;
      opacity: 1; } } }
.profile-container {
  padding: 0;
  .header {
    background: #fff;
    padding: 20px 10px;
    @media #{$phone-strict} {
      button {
        margin-left: 0!important; } }
    @media #{$desktop} {
      padding: 30px 60px; }
    font-weight: 400;
    color: $c2;
    button {
      font-weight: 600;
      text-decoration: underline;
      background: transparent;
      border: none;
      color: $c2; } }
  .profile-profiler-container {
    background: #ffffff;
    margin-top: 65px;
    padding: 45px 140px 45px 60px;
    @media #{$desktop-strict} {
      padding-right: 60px; }
    @media #{$tablet-strict} {
      padding: 45px 40px; }

    h4 {
      color: $c2;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 16px;
      @media #{$phone-strict} {
        margin-bottom: 16px; } } }

  .submit-button-container {
    justify-content: center;
    margin-top: 48px;
    .login-btn {
      background: transparent;
      font-weight: 400;
      border: 2px solid #7c7c7c;
      color: #7c7c7c;
      font-size: 16px;
      line-height: 30px;
      border-radius: 30px;
      width: 180px;
      &:after {
        content: "";
        width: 18px;
        height: 15px;
        background: url("../../assets/images/arr-gray.png") no-repeat;
        right: 17px;
        top: 13px;
        position: absolute; }
      &:focus, &:hover {
        outline: none;
        box-shadow: none;
        &:after {
          right: 22px;
          transition: all 0.3s; } } } } }
.profile-pass-btn-container {
  margin-top: 64px;
  background: #ffffff;
  padding: 24px 60px;
  @media #{$phone-strict} {
    padding: 24px 30px;
    margin-top: 48px; }
  .btn-link {
    padding: 0;
    border: none;
    color: $c2;
    text-decoration: underline; } }

.profile-contact-box {
  display: flex;
  justify-content: space-between;
  @media #{$phone-strict} {
    margin-top: 24px;
    flex-direction: column;
    max-width: max-content; }
  p {
    font-size: 16px;
    font-weight: 500;
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 6px;
    background: var(--bg, #F0F2F6);
    padding: 8px 12px; }
  .btn-link {
    font-family: Signika,sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    border: 2px solid #000;
    color: #000;
    line-height: 20px;
    border-radius: 32px;
    min-height: 46px;
    display: table;
    max-height: 46px;
    text-transform: uppercase; } }
//profile products
.profile-product-container {
  .tab-content {
    padding: 0; } }
.btn-profile-delete {
  float: right;
  border: none;
  background: transparent;
  opacity: 0.6;
  color: #656565;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
  line-height: 32px;
  text-decoration: underline;
  padding: 10px 0 0;
  &:focus, &:hover {
    color: #2543fd;
    opacity: 1;
    outline: none; } }
.profile-auto-subscription-container {
  color: $c2;
  h4 {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-transform: uppercase; }
  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
    position: relative;
    &:before {
      content: '';
      width: 11px;
      height: 14px;
      position: absolute;
      background: url("../../assets/images/checked.svg") no-repeat;
      left: -16px;
      top: 10px; } }
  a {
    color: $c2;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    &:focus, &:hover {
      color: $c2;
      opacity: 1;
      outline: none; } } }
.downolad-certificate {
  border: none;
  background: transparent;
  color: $c2;
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 32px;
  padding: 10px 0 0;
  font-weight: 500;
  &:focus, &:hover {
    text-decoration: underline;
    color: $c2; } }
.profile-certificates-list {
  .profile-certificate-item-wrapper {
    background: #fff;
    margin-top: 12px;
    .profile-certificate-item {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      align-items: center;
      h2 {
        max-width: 450px;
        a {
          &:hover {
            text-decoration: underline; } } }
      h2, p, h2 > a {
        color: $c2;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin: 0; }
      .webinar-download-certificate {
        color: $c5;
        background: none;
        border: none;
        font-size: 16px;
        padding: 0 20px 0 0;
        position: relative;
        font-weight: 600;
        cursor: not-allowed;
        transition: 0.3s all;
        &.active {
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            width:  13px;
            height: 17px;
            right: 0;
            top: 3px;
            background: url("../../assets/images/arr-bottom.svg"); }
          &:hover {
            transition: 0.3s all;
            text-decoration: underline;
            &:after {
              top: 7px; } } } } } } }

.started-list-container {
  .index-list-item {
    .webinar-labels {
      top: 76px;
      .overdue {
        background: red!important; }
      &.with-overdue {
        top: 50px; } }
    &:hover {
      .webinar-labels {
        top: 50px;
        &.with-overdue {
          top: 24px; } } } } }


