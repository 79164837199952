.index-list-container {
  padding: 40px 0 60px;
  background: #ffffff;
  .webinar-list-benefits {
    display: flex;
    justify-content: center;
    border-radius: 18px;
    font-weight: 300;
    font-size: 16px;
    background-color: #ffffff;
    padding: 18px 0;
    margin: 0 auto 20px;
    color: $c5;
    box-shadow: 0 3px 6px rgba(0,0,0, 0.29);
    @media #{$tablet-strict} {
      flex-wrap: inherit; }
    @media #{$phone-strict} {
      flex-direction: column; }
    .benefit-item {
      padding: 0 30px;
      display: flex;
      align-items: center;
      img {
        margin-right: 15px; }
      @media #{$desktop-strict} {
        padding: 0 25px; }
      @media #{$tablet-strict} {
        padding: 20px 25px;
        width: 50%; }
      @media #{$phone-strict} {
        padding: 0 30px;
        margin-bottom: 20px;
        width: 100%;
        img {
          min-width: 49px;
          object-fit: scale-down; } } } } }

.list-item-container {
  @media #{$tablet} {
    padding-left: 12px;
    padding-right: 12px; } }
.index-list-item {
  display: block;
  background: #fff;
  height: 480px;
  padding: 9px;
  position: relative;
  top: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(35, 41, 54, 0.60);
  border-radius: 20px;
  .card-img-top {
    height: 147px;
    object-fit: cover;
    display: block;
    transition: all 0.3s;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .training-user-item-details {
    position: absolute;
    top: 132px;
    width: 100%;
    left: 0;
    transition: all 0.3s;
    > span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.7px;
      line-height: 18px;
      text-transform: uppercase;
      background-color: #304471;
      padding: 5px; }
    .progress {
      height: 4px;
      background-color: transparent;
      border-radius: 0;
      .progress-bar {
        background-color: #2ee3a9; } } }
  .webinar-labels {
    position: absolute;
    top: 105px;
    left: 0;
    transition: 0.3s all;
    &.webinar-paid-labels {
      top: 78px; }
    &.category-71, &.category-87, &.category-100, &.category-89, &.category-88, &.category-97 {
      .archived-label {
        background: linear-gradient(0deg, rgba(74, 165, 216, 0.30) 0%, rgba(74, 165, 216, 0.30) 100%), #FFF; }
      .category-label {
        background: #4AA5D8; } }
    &.category-14, &.category-90, &.category-13 {
      .archived-label {
        background: linear-gradient(0deg, rgba(253, 210, 110, 0.30) 0%, rgba(253, 210, 110, 0.30) 100%), #FFF; }
      .category-label {
        background: #FDD26E; } }
    &.with-overdue {
      top: 78px; }
    > div {
      border-radius: 3px;
      color: $c5;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 24px;
      display: table;
      padding: 0 10px 0 18px; }
    .archived-label {
      margin-bottom: 3px; }
    .category-label {
      transition: all 0.5s; }
    .overdue {
      margin-top: 3px;
      background: #ea0e27; } }
  .card-body {
    overflow: hidden;
    transition: all 0.3s;
    font-family: "Red Hat Display", sans-serif;
    color: $c5;
    padding-bottom: 10px;
    .card-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      transition: all 0.5s;
      margin-bottom: 0;
      max-height: 60px;
      min-height: 40px;
      overflow: hidden;
      color: $c1;
      &:hover {
        color: $c1;
        text-decoration: underline; }
      @media #{$tablet-strict} {
        font-size: 16px;
        line-height: 22px; } }
    .expert-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 48px;
      overflow: hidden;
      .webinar-item-expert {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        color: $c5; } }
    .item-info-container {
      margin-top: 16px;
      p {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid #DBDBDB; } }
    .webinar-dates-container {
      transition: all 2s;
      color: $c5;
      letter-spacing: 0.15px;
      overflow: hidden;
      font-size: 14px;
      padding-top: 16px;
      .webinar-date-item {
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        padding: 3px 0;
        position: relative;
        align-items: center;
        &.expired {
          &:before {
            position: absolute;
            width: 100%;
            border-top: 1px solid $c5;
            top: 44%;
            left: 0;
            content: ''; } }
        > span {
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          display: flex;
          align-items: center;
          svg {
            margin-right: 6px; } }
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          margin: 0;
          span {
            font-size: 16px;
            line-height: 20px;
            display: table;
            float: left;
            &.date {
              min-width: 92px;
              text-align: right; }
            &.time {
              margin-right: 18px; } } } }
      &.webinar-paid-dates-container {
        .webinar-date-item {
          > span {
            font-weight: 700;
            &:before {
              border-color: $c5!important;
              padding: 2px;
              margin-top: 4px;
              width: 16px!important;
              height: 16px!important; }
            &:after {
              left: 5px;
              top: 12px;
              content: '';
              width: 6px;
              height: 6px;
              background: $c5;
              position: absolute;
              border-radius: 50%; } }

          > p {
            padding-top: 2px; } } } } }

  .item-lead {
    transition: all 0.3s;
    color: $c5;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.6px;
    line-height: 18px;
    display: none;
    padding: 0 20px;
    max-height: 74px;
    overflow: hidden;
    &.webinar-item-lead {
      max-height: 54px; }
    @media #{$phone-strict} {
      overflow: hidden;
      font-size: 14px; } }
  .webinar-list-item-btn {
    position: absolute;
    bottom: 15px;
    right: 20px;
    border: 2px solid $c5;
    color: $c5;
    background: #ffffff;
    font-weight: 700;
    border-radius: 25px;
    padding: 6px 90px 6px 17px;
    &:after {
      content: "";
      position: absolute;
      right: 20px;
      bottom: 12px;
      width: 17px;
      height: 14px;
      transition: all 0.5s;
      background: url("../../assets/images/arr-black.png") no-repeat; } }
  .button-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 30px;
    text-align: center;
    min-height: 120px;
    &.webinar-free-wrapper {
      min-height: 95px; }
    .educado-btn-text {
      margin-bottom: 10px; }
    .educado-btn-black {
      margin-bottom: 16px;
      width: 100%;
      text-align: center; }
    .training-btn {
      color: #000;
      &:hover {
        color: #000;
        text-decoration: underline; } } }
  .price-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 23px;
    left: 0;
    width: 100%;
    padding: 0 30px;
    .price-item {
      font-size: 31px;
      font-weight: 400;
      color: $c5; }
    .webinar-list-item-btn {
      position: relative;
      right: 0;
      height: 40px;
      bottom: -5px; } }
  &:hover {
    top: -6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
    //transition: all 0.3s
    .card-img-top {
      height: 120px;
      object-fit: cover;
      transition: all 0.3s; }
    .training-user-item-details {
      top: 106px; }
    .webinar-labels {
      top: 78px;
      transition: 0.3s all;
      &.webinar-paid-labels {
        top: 51px;
        &.with-overdue {
          top: 23px; } }
      &.with-overdue {
        top: 51px; }
      .category-label {
        display: table;
        transition: all 0.5s; } }
    .card-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .item-info-container {
        display: none; }
      .webinar-dates-container {
        display: none; }
      .training-item-description {
        display: block;
        transition: all 2s; } }
    .item-lead {
      display: block; }
    .webinar-list-item-btn {
      color: #000;
      background: $c4;
      border-color: $c4;
      &:after {
        right: 30px;
        transition: all 0.3s; } }
    .training-item-details {
      display: none;
      transition: all 0.5s; } } }

.webinar-item-container {
  &.webinar-training-item {
    .webinar-item {
      .card-body {
        .expert-wrapper {
          margin-top: 10px; }
        .legal-date {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 8px; }
        .webinar-dates-container {
          padding-top: 0;
          .home-product-category-item-container {
            display: flex;
            flex-wrap: wrap;
            max-height: 102px;
            a {
              font-size: 13px;
              line-height: 28px;
              border: 1px solid $c5;
              color: $c5;
              border-radius: 5px;
              padding: 0 9px;
              margin-right: 5px;
              margin-bottom: 5px; } } } } } }
  &.webinar-paid-item-container {
    .webinar-labels {
      top: 49px;
      .chat-label {
        width: 69px;
        background: #030A48 url("../../assets/images/czat.svg") no-repeat;
        height: 53px;
        margin-bottom: 3px; } }
    &:hover {
      .webinar-labels {
        top: 22px; } }
    .webinar-list-item-btn {
      padding: 6px 60px 6px 16px;
      @media #{$tablet-strict} {
        padding: 6px 48px 6px 16px; } }
    .card-body {
      .webinar-dates-container {
        .webinar-date-item {
          p {
            span.time {
              @media #{$tablet-strict} {
                margin-right: 8px; } } } } } } }
  &.webinar-paid {
    .webinar-labels {
      top: 49px;
      .chat-label {
        width: 69px;
        background: #030A48 url("../../assets/images/czat.svg") no-repeat;
        height: 53px;
        margin-bottom: 3px; } }
    .card-body {
      .webinar-dates-container {
        .webinar-date-item {
          p {
            span.time {
              @media #{$tablet-strict} {
                margin-right: 8px; } } } } } }
    .webinar-list-item-btn {
      padding: 6px 60px 6px 16px;
      @media #{$tablet-strict} {
        padding: 6px 48px 6px 16px; } }
    .card-body {
      .webinar-dates-container {
        .webinar-date-item {
          p {
            span.time {
              @media #{$tablet-strict} {
                margin-right: 8px; } } }
          > span {
            font-weight: 700;
            &:before {
              border-color: #1d1d1d!important; }
            &:after {
              content: "";
              border: 2px solid #1d1d1d;
              border-radius: 50%;
              width: 0;
              height: 0;
              display: table;
              float: left;
              margin-top: 5px;
              margin-right: 7px;
              position: absolute;
              top: 7px;
              left: 4px; } } } } }
    .price-container {
      > span {
        font-size: 31px;
        font-weight: 500;
        color: #232936; } }
    &:hover {
      .webinar-labels {
        top: 22px; } } }
  .webinar-item {
    .card-body {
      .webinar-dates-container {
        .webinar-date-item {
          > span {} } } } } }


.webinar-list-subscription-container {
  background: $c5;
  color: #FFFFFF;
  padding: 32px 0 10px;
  .webinar-list-subscription-image {
    @media #{$phone-strict} {
      text-align: center; }
    img {
      height: 230px;
      width: 245px;
      margin-left: 45px;
      margin-top: 28px;
      @media #{$tablet-strict} {
        margin-left: 0; }
      @media #{$phone-strict} {
        margin: 0 0 30px 0; } } }
  .webinar-list-subscription-text {
    font-family: "Red Hat Display", sans-serif;
    h3 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 22px; }
    p {
      font-size: 18px;
      line-height: 21px;
      font-weight: 300;
      margin-bottom: 22px; }
    .webinar-btn {
      @media #{$phone-strict} {
        margin: 20px auto 30px; } } } }
.mobile-webinar-carousel.carousel {
  width: 100%;
  margin-top: 0;
  .carousel-inner {
    min-height: 540px; }
  &.mobile-product-carousel {
    .carousel-indicators {
      margin-bottom: 25px; }
    .carousel-inner {
      min-height: 488px; } } }
.mobile-home-webinar-head {
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  display: table;
  border-bottom: 2px solid #898D96;
  padding-bottom: 10px;
  margin-bottom: 12px;
  &.no-banner {
    margin-top: 40px; }
  @media #{$phone-strict} {
    margin: 0 auto 30px; }
  span {
    color: $c4;
    font-weight: 400;
    padding-left: 5px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: -25px;
      top: 6px;
      width: 20px;
      height: 20px;
      background-size: contain!important;
      background: url("../../assets/images/webinar-circle-head.png") no-repeat; } } }
