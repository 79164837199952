//Expert slider

.home-expert-slider {
  @media #{$phone-strict} {
    //padding: 0
    .headline-small {
      padding-left: 15px; } }
  .carousel-wrapper {
    @media #{$phone-strict} {
      padding: 0; }
    @media #{$desktop} {
      overflow: hidden; }
    .carousel-container {
      background: #fff;
      padding: 32px 0 18px;
      @media #{$desktop} {
        overflow: hidden; }
      > ul {
        > li {
          padding: 0 15px;
          .carousel-image {
            width: 130px;
            height: 129px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            margin: 0 auto; }
          img {
            object-fit: cover; }
          &:before {
            display: none; }
          a {
            display: block;
            margin-top: 18px;
            > p {
              font-size: 18px;
              color: $c1;
              font-weight: 700;
              line-height: 18px;
              margin-bottom: 8px;

              > span {
                color: $c3;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.7px;
                line-height: 18px;
                text-transform: uppercase; } } } } } } } }
.carousel-main-container {
  background: #fff;
  padding-bottom: 18px;
  h3 {
    font-family: "Red Hat Display", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: $c1;
    margin-top: 28px;
    margin-bottom: 38px;
    text-align: center; }
  &.awards-carousel {
    .carousel-container {
      @media #{$desktop} {}
      .react-multi-carousel-track {
        .react-multi-carousel-item {
          .carousel-image {
            img {
              height: 90px;
              max-width: 190px;
              object-fit: scale-down; } } } } } }
  .react-multiple-carousel__arrow--right {
    right: 0; }
  .react-multiple-carousel__arrow--left {
    left: 0; }
  .react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right {
    background: transparent;
    &:before {
      color: #000; } } }
