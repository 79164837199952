.registration-account-container {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 100vw;
    padding: 0; }
  h3 {
    opacity: 0.8;
    color: $c1;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.98px;
    line-height: 20px;
    text-transform: uppercase;
    padding-bottom: 20px; }
  .form-group {
    margin-bottom: 28px;
    display: flex;
    flex-flow: wrap;

    .form-control::placeholder {
      opacity: 0.5;
      color: #656565;
      text-transform: uppercase; }
    input {
      border-radius: 12px;
      border: 2px solid #c4c4c4;
      color: $c1;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.65px;
      line-height: 32px;
      height: 40px;
      &:focus {
        border: 2px solid #304471;
        box-shadow: none; } }
    .recaptcha-container {
      margin-bottom: 40px;
      text-align: center; }
    .pass-reminder {
      color: $c2;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      display: block;
      text-decoration: underline;
      border: none;
      background: transparent;
      margin: 30px auto; } }

  .register-form-content, #LoginForm {
    .form-group {
      height: 40px;
      max-width: 266px;
      flex-flow: wrap;
      .is-invalid {
        border: 2px solid rgba(229, 103, 103, 0.51)!important; }
      .is-invalid::placeholder {
        opacity: 0.8;
        color: #e56767; }
      .invalid-feedback {
        color: #e56767;
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.12px;
        line-height: 20px; } } }


  .register-login-form-container {
    margin-top: 80px;
    @media #{$phone-strict} {
      margin-top: 0;
      padding: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 0; }
    .register-login-form-wrapper {
      padding: 70px 50px 80px;
      background-color: rgba(255, 255, 255, 0.58);
      @media #{$phone-strict} {
        padding: 45px 0 60px;
        display: flex;
        flex-direction: column;
        align-items: center; }
      @media (min-width: 768px) and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .pass-reminder {
        margin: 48px auto 0; }
      .form-group {
        &:last-of-type {
          margin-bottom: 46px; } }
      .login-btn {
        color: $c2;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        display: block;
        margin: 0 auto;
        min-width: 200px;
        border-radius: 20px;
        border: 2px solid #304471;
        background: transparent;
        position: relative;
        opacity: 0.58;
        &:after {
          content: "";
          width: 18px;
          height: 15px;
          background: url("../../assets/images/arr-navy.svg") no-repeat;
          right: 17px;
          top: 12px;
          position: absolute; }
        &:focus, &:hover {
          outline: none;
          box-shadow: none;
          opacity: 1;
          &:after {
            right: 22px;
            transition: all 0.3s; } } } } }
  .register-account-offer {
    @media #{$phone-strict} {
      padding: 0; }
    .register-form-content {
      margin-top: 80px;
      @media #{$phone-strict} {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center; } } } }
