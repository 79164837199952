.register-form-offer {
  background: #fff;
  padding: 120px 0 72px 50px;
  position: relative;
  @media #{$phone-strict} {
    padding: 34px 60px 50px;
    text-align: center; }
  &:after {
    position: absolute;
    content: "";
    background: #fff;
    width: 500vw;
    height: 100%;
    top: 0;
    left: 100%; }
  @media #{$phone-strict} {
    &:after {
      display: none; } }

  .form-group {
    margin-bottom: 7px;
    label {
      font-size: 12px;
      color: $c1;
      font-weight: 300;
      font-style: normal;
      letter-spacing: 0.12px;
      line-height: 20px;
      @media #{$phone-strict} {
        text-align: left; }
      input {
        width: 17px;
        height: 17px;
        border: 2px solid #c4c4c4;
        background-color: #ffffff;
        float: left;
        margin-left: -25px; }
      a {
        color: $c2;
        font-weight: 500;
        &:focus, &:hover {
          text-decoration: underline;
          color: $c2; } } } }
  .register-info-block {
    color: $c2;
    margin-top: 35px;
    h4 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.98px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 0; }
    h2 {
      font-size: 34px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 0;

      > span {
        font-size: 24px;
        font-weight: 400;
        line-height: 34px; } }
    h5 {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.15px;
      line-height: 20px;
      margin-bottom: 25px; }
    p {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.12px;
      line-height: 20px;
      position: relative;
      &:before {
        width: 11px;
        height: 11px;
        position: absolute;
        content: "";
        left: -18px;
        top: 4px;
        background: url("../../assets/images/checked.svg") no-repeat; }
      @media #{$phone-strict} {
        &:before {
          display: none; } }
      a {
        text-decoration: underline;
        font-weight: 400;
        display: block;
        color: $c2; } } }
  .register-btn {
    border-radius: 56px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    background: $c2;
    padding: 3px 20px;
    min-width: 150px;
    position: relative;
    border: none;
    &:after {
      content: "";
      width: 18px;
      height: 15px;
      background: url("../../assets/images/arr-white.svg") no-repeat;
      right: 17px;
      top: 12px;
      position: absolute; }
    &:focus, &:hover, &:active {
      outline: none;
      box-shadow: none;
      &:after {
        right: 22px;
        transition: all 0.3s; } } } }
