.breadcrumb-container-content {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 26px;
  margin-top: 18px;
  position: relative;
  .home-unit {
    padding-right: 4px;
    &:hover {
      svg {
        fill: $c4; } } }
  .breadcrumb-unit {
    padding: 0 4px;
    font-family: "Red Hat Display", sans-serif;
    font-size: 12px;
    font-weight: 500;
    > a {
      color: white;
      &:hover {
        color: white;
        text-decoration: underline; } }
    > span {
      color: $c4; } }
  &.dark {
    .breadcrumb-unit {
      > a {
        color: #232936;
        &:hover {
          color: #232936; } } } } }
