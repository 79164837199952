.product-mobile-container {
  .video-warning {
    > img {
      width: 100%; } }
  .product-mobile-wrapper {
    position: relative; }
  h1 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.15px;
    line-height: 20px;
    color: $c2;
    padding: 15px 0; }
  .product-item-archive-badge {
    background-color: #ef7373;
    color: #ffffff;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    padding: 10px 15px; }
  .video-box {
    width: 100%;
    iframe {
      width: 100%; } }
  .product-info-container {
    padding: 22px 15px 25px;
    flex-direction: column;
    h3, h5 {
      color: #4e4e4e;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 32px; }
    h5 {
      text-align: center;
      margin-bottom: 0;
      line-height: 16px; }
    .social-media-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .btn-media {
        padding-right: 50px!important;
        opacity: 0.5!important; }
      .btn-media {
        width: 19px;
        height: 21px;
        background: url("../../assets/images/share.svg") no-repeat;
        opacity: 0.2;
        border: none;
        padding: 0;
        &:focus, &:hover {
          opacity: 1; } }
      .product-tools {
        > button {
          > i {
            width: 16px;
            height: 22px;
            display: block;
            background: no-repeat;
            &:focus, &:hover {
              outline: none;
              opacity: 0.6; }
            &.active {
              background: url("../../assets/images/add-favourite-on.png"); }
            &.non-active {
              background: url("../../assets/images/add-favourite-off.svg"); } } } } }
    .expert, .duration-time, .benefit-list, .product-status-badge {
      color: $c3;
      font-size: 15px;
      line-height: 32px; }
    .expert, .expert > a, .duration-time, .product-status-badge {
      font-weight: 600;
      font-size: 15px;
      line-height: 32px; }
    .expert > a {
      color: $c2; }
    .product-status-badge {
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: -12px;
        top: 11px;
        position: absolute; }
      &.archived {
        &:before {
          background: #ff0047; } }
      &.actual {
        &:before {
          background: #17d844; } } }
    .product-info {
      margin-bottom: 30px; }
    .benefit-list {
      margin: 45px 0;
      > ul {
        list-style: none;
        padding-left: 0;
        > li {
          position: relative;
          padding-left: 20px;
          &:before {
            content: "";
            background: url("../../assets/images/benefit-icon.svg") no-repeat;
            width: 21px;
            height: 21px;
            left: 0;
            top: 11px;
            position: absolute; } } } }
    .offer-box {
      margin-top: 32px;
      .btn {
        width: 260px;
        height: 40px;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: space-between;
        @media #{$desktop-strict} {
          width: 100%; }
        @media #{$tablet-strict} {
          width: 260px; }
        &:after {
          content: "";
          position: absolute;
          width: 18px;
          height: 14px;
          top: 12px;
          right: 13px; }
        &:focus, &:hover {
          &:after {
            right: 17px; } }
        > span {
          margin-right: 35px;
          font-weight: 400; }
        &.annual {
          background: #2993d3;
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          border: 2px solid #2993d3;
          margin: 0 auto;
          &:after {
            background: url("../../assets/images/arr-white.svg") no-repeat; } }
        &.shop {
          background: #4c4c4c;
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          border: 2px solid #4c4c4c;
          margin: 16px auto;
          &:after {
            background: url("../../assets/images/arr-white.svg") no-repeat; } }
        &.month {
          margin: 24px auto 16px;
          border: 2px solid #2543fd;
          color: #2543fd;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          &:after {
            background: url("../../assets/images/arr-blue.svg") no-repeat; } } }
      .offer-product-link {
        color: #7c7c7c;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        display: block;
        margin-top: 20px;
        &:hover {
          text-decoration: underline; } } } }
  .product-mobile-description {
    .nav {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 999;
      text-align: left;
      .nav-item {
        opacity: 1;
        flex: 1 1 auto;
        font-size: 11px; } }
    .tab-content {
      padding-bottom: 84px;
      background: #fff;
      width: 100%;
      .product-content-block {
        padding: 32px 0 16px;
        h4 {
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.7px;
          margin-bottom: 12px; }
        > div {
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0.15px;
          line-height: 24px; }
        ul {
          padding: 0 0 0 6px;
          list-style: none;
          margin-bottom: 40px;
          > li {
            position: relative;
            padding-left: 18px;
            margin-bottom: 24px;
            &:before {
              content: "";
              background: url("../../assets/images/arr-right-navy.svg") no-repeat;
              width: 9px;
              height: 14px;
              left: 0;
              top: 2px;
              position: absolute; }
            > a {
              font-size: 15px;
              font-weight: 300;
              letter-spacing: 0.15px;
              line-height: 20px;
              color: $c1;
              &:hover, &:focus {
                text-decoration: underline; } } } } }
      .product-patronage-block {
        margin-top: 70px;
        display: flex;
        justify-content: flex-start;
        @media #{$phone-strict} {
          justify-content: center; }
        flex-wrap: wrap;
        padding: 0 20px;
        .product-patronage-col {
          padding: 5px;
          @media #{$phone-strict} {
            max-width: 250px; }
          .product-patronage-content {
            border: 1px solid #eeeeee;
            border-radius: 4px;
            height: 70px;
            display: flex;
            .product-patronage-image {
              margin: auto;
              max-width: 70%;
              max-height: 35px;
              height: auto;
              width: auto; } } } }
      .product-expert-container {
        padding: 10px 0;
        .product-expert-image {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
          background: $c3;
          display: block;
          margin-bottom: 12px; }
        .product-expert-description {
          h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px; }
          h5 {
            color: $c3;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.7px;
            line-height: 18px;
            margin-bottom: 12px;
            text-transform: uppercase; }
          > div {
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.15px;
            line-height: 24px; }
          > a {
            color: #7c7c7c;
            font-size: 14px;
            font-weight: 400;
            margin-top: 12px;
            line-height: 32px;
            text-decoration: underline; } } }
      #uncontrolled-tab-example-tabpane-3, #uncontrolled-tab-example-tabpane-2 {
        //padding: 0 0 20px
        .container {
          //padding: 0
 }          //width: 100vw
        h3 {
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.15px;
          line-height: 20px;
          color: $c2;
          text-align: center;
          padding: 20px 15px 0;
          margin-bottom: 0;
          > a {
            text-decoration: underline;
            color: $c2; } }
        .product-quiz-blue-section {
          padding: 20px 10px;
          color: #ffffff;
          background: $c2;
          margin-bottom: 15px;
          > h3 {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.15px;
            line-height: 20px; } }

        .btn {
          color: $c2;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          display: block;
          margin: 25px auto;
          min-width: 200px;
          border-radius: 20px;
          border: 2px solid #304471;
          background: transparent;
          position: relative;
          &:after {
            content: "";
            width: 18px;
            height: 15px;
            background: url("../../assets/images/arr-navy.svg") no-repeat;
            right: 17px;
            top: 12px;
            position: absolute; }
          &:focus, &:hover {
            outline: none;
            box-shadow: none;
            &:after {
              right: 22px;
              transition: all 0.3s; } }
          &.downolad-certificate {
            &:after {
              transform: rotate(90deg); }
            &:focus, &:hover {
              &:after {
                right: 17px;
                top: 15px; } } } }
        .product-quiz-summary-btn {
          font-size: 14px;
          color: $c2;
          line-height: 32px;
          background: none;
          border: none;
          width: 100%;
          text-decoration: underline; } } } }
  .vimeo-navigation-controls {
    background-color: #b0c5ed;
    padding: 10px 0;
    &.not-logged {
      height: 48px;
      width: 100vw;
      margin: 0; }
    > div {
      width: 33%;
      text-align: center;
      .btn {
        white-space: nowrap;
        color: $c2;
        background: #fff;
        font-weight: 400;
        letter-spacing: 0.55px;
        line-height: 20px;
        font-size: 12px;
        border-radius: 30px;
        text-transform: uppercase;
        padding: 1px 10px; }
      .btn:not(.disabled) {
        &:focus, &:hover {
          background: $c2;
          color: #fff; } } } }
  .show-video-list {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: $c2;
    background: #fff;
    font-weight: 400;
    letter-spacing: 0.55px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 2px 10px;
    border: none;
    &:focus, &:hover {
      background: $c2;
      color: #fff; } }
  .non-active {
    display: none; } }
.modal-video-mobile-list.show {
  .modal-dialog {
    margin: 0;
    min-height: 100vh;
    .modal-content {
      min-height: 100vh;
      .modal-header, .modal-body, .modal-footer {
        width: 100%; }
      .modal-header {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.7px;
        line-height: 24px;
        text-transform: uppercase;
        color: $c2;
        border-bottom: none;
        .close {
          opacity: 1;
          padding-top: 8px;
          span {
            font-size: 30px;
            font-weight: 300;
            color: $c2;
            opacity: 1; } } }
      .modal-body {
        .video-list {
          padding: 40px 20px;
          h5 {
            display: none; }
          .btn-video-list {
            color: #656565;
            font-size: 15px;
            font-weight: 400;
            line-height: 32px;
            background: transparent;
            border: none;
            &.active {
              font-weight: 700; } } } }
      .modal-footer {
        border-top: 0;
        justify-content: center;
        .btn {
          border: none;
          background-color: transparent;
          color: $c2;
          text-decoration: underline;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px; } } } } }
