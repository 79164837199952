//menu desktop
//// main menu
#main-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #232936;
  height: 75px;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 99999;
  font-family: "Red Hat Display", sans-serif;
  &.logged {
    #nav-category {
      justify-content: space-around; } }
  .logo-header {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 25px;
    > img {
      width: 166px; }
    @media #{$desktop-strict} {
      margin-right: 15px;
      > img {
        width: 123px; } } }
  #nav-category {
    justify-content: unset;
    .webinar-menu-btn {
      margin-right: 30px!important;
      @media #{$desktop-strict} {
        margin-right: 12px!important; }
      a {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: 0.5px;
        position: relative;
        padding-left: 20px;
        padding-top: 2px;
        @media #{$desktop-strict} {
          padding-left: 0; }
        span {
          color: #2ee3a9; }
        &:hover {
          color: #2ee3a9; } } }
    .category-btn {
      margin-right: 20px;
      > .dropdown {
        &.show {
          > .nav-link {
            color: $c4;
            &:after {
              background: url("../../assets/images/menu-arr-green.svg") no-repeat;
              transform: rotate(-180deg);
              transition: 0.3s all; } } }
        > .nav-link {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-transform: uppercase;
          font-style: normal;
          letter-spacing: 0.5px;
          position: relative;
          padding-top: 10px;
          padding-left: 20px;
          transition: 0.3s all;
          &:after {
            content: "";
            border: none;
            vertical-align: 0;
            width: 14px;
            height: 8px;
            transition: 0.3s all;
            background: url("../../assets/images/menu-arr-white.svg") no-repeat;
            margin-left: 10px; }
          &:hover {
            color: $c4;
            &:after {
              background: url("../../assets/images/menu-arr-green.svg") no-repeat; } } }

        >.dropdown-menu {
          > .nav-item {
            &.show {
              > a {
                border-radius: 30px 30px 0 30px!important; } } } }
        >.dropdown-menu.show {
          background: $c5;
          border-radius:  0 0 30px 30px;
          border: 1px solid $c4;
          border-top: none;
          box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.8);
          padding: 30px 30px 45px;
          color: #fff;
          top: 57px;
          > .nav-item {
            > a {
              color: #fff;
              font-family: "Red Hat Display", sans-serif;
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0.5px;
              text-align: left;
              border-radius: 30px;
              padding: 10px 30px 10px 42px;
              text-transform: uppercase;
              position: relative;
              &:before {
                position: absolute;
                top: 11px;
                left: 10px;
                width: 25px;
                height: 20px;
                content: '';
                background: url("../../assets/images/logos/logo-mini-default.svg");
                background-size: cover!important;
                background-repeat: no-repeat!important; }
              &:hover {
                &:before {
                  background: url("../../assets/images/logos/logo-mini-default.svg")!important;
                  background-size: cover!important;
                  background-repeat: no-repeat!important;
                  width: 25px; } }

              &:after {
                display: none; } }
            > .dropdown-menu.show {
              position: relative;
              width: 90%;
              margin-left: 10% !important;
              padding: 0;
              background: transparent;
              border: none;
              .categories-wrapper {
                border-radius: 0 0 20px 20px;
                box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
                padding: 8px 0 22px 24px;
                > a {
                  color: #fff;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 22px;
                  letter-spacing: 0.5px;
                  border-radius: 15px 0 0 15px;
                  padding: 11px 0 11px 26px;
                  margin-bottom: 8px; } } }
            &.oswiata {
              margin-bottom: 45px;
              > a {
                background: rgba(74, 165, 216, 0.1);
                &:before {
                  background: url("../../assets/images/logos/logo-mini-oswiata.svg"); }
                &:hover {
                  background: rgba(74, 165, 216, 1); } }

              > .dropdown-menu.show {
                .categories-wrapper {
                  background: rgba(74, 165, 216, 0.1);
                  > a {
                    &:focus, &:hover {
                      background: rgba(74, 165, 216, 1); } } } } }
            &.kik {
              > a {
                background: rgba(253, 210, 110, 0.1);
                &:before {
                  background: url("../../assets/images/logos/logo-mini-kik.svg"); }
                &:hover {
                  background: rgba(253, 210, 110, 0.6); } }

              > .dropdown-menu.show {
                .categories-wrapper {
                  background: rgba(253, 210, 110, 0.1);
                  > a {
                    &:focus, &:hover {
                      background: rgba(253, 210, 110, 0.6); } } } } } } } } }
    .search-form {
      @media #{$desktop} {
        margin-right: 20px!important; }
      .form-inline {
        border-radius: 21px;
        border: 1px solid #fff;
        .form-control {
          color:  #fff;
          font-size: 14px;
          line-height: 37px;
          border-radius: 21px 0 0 21px;
          border: none;
          height: 37px;
          border-right: none;
          background: transparent;
          max-width: 238px;
          width: 230px;
          @media #{$desktop-strict} {
            width: 170px; } }
        .form-control::placeholder {
          color: #fff; }
        .btn {
          border-radius: 50%;
          background-color: transparent;
          border: 1px solid #FFF;
          font-size: 13px;
          height: 37px;
          width: 37px;
          text-align: center;
          line-height: 31px;
          padding: 0;
          &:focus, &:hover {
            background-color: #2ee3a9;
            border: 1px solid #2ee3a9;
            color: #fff; } } } } }
  .contact-btn {
    &.show {
      > a {
        box-shadow: 2px 2px 6px 0 #36232D inset;
        &:after {
          transform: rotate(-180deg);
          transition: 0.3s all; } } }
    > a {
      color: #242936;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      margin-right: 12px;
      background: $c4;
      border-radius: 32px;
      padding: 12px 48px 12px 20px;
      position: relative;
      &:after {
        content: '';
        border: none;
        width: 14px;
        height: 8px;
        top: 19px;
        transition: 0.3s all;
        right: 15px;
        position: absolute;
        background: url("../../assets/images/menu-arr-navy.svg"); }
      &:hover {
        box-shadow: 0 8px 16px 0 #000;
        background: #81EECB;
        border-color: #81EECB; } }
    >.dropdown-menu.show {
      background: $c5;
      border-radius:  0 0 30px 30px;
      border: 1px solid $c4;
      border-top: none;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.8);
      padding: 30px 30px 45px;
      color: #fff;
      left: -128px;
      top: 60px;
      > a {
        color: #fff;
        font-family: "Red Hat Display", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.5px;
        border-radius: 30px;
        padding: 10px 30px 10px 42px;
        text-transform: uppercase;
        position: relative;
        display: flex;
        width: auto;
        text-wrap: nowrap;
        &.oswiata {
          background: rgba(253, 210, 110, 0.1);
          margin-bottom: 45px;
          &:before {
            background: url("../../assets/images/logos/logo-mini-oswiata.svg");
            background-size: cover!important;
            background-repeat: no-repeat!important; }
          &:hover {
            background: rgba(74, 165, 216, 1); } }
        &.kik {
          background: rgba(253, 210, 110, 0.1);
          &:before {
            background: url("../../assets/images/logos/logo-mini-kik.svg");
            background-size: cover!important;
            background-repeat: no-repeat!important; }
          &:hover {
            background: rgba(253, 210, 110, 0.6); } }
        &:before {
          position: absolute;
          top: 11px;
          left: 10px;
          width: 25px;
          height: 20px;
          content: '';
          background: url("../../assets/images/logos/logo-mini-default.svg");
          background-size: cover!important;
          background-repeat: no-repeat!important; }
        &:hover {
          &:before {
            background: url("../../assets/images/logos/logo-mini-default.svg")!important;
            background-size: cover!important;
            background-repeat: no-repeat!important;
            width: 25px; } } } } }

  .user-info {
    .nav-link {
      max-width: 136px;
      @media #{$desktop-strict} {
        max-width: 119px; } }
    .navbar-nav {
      .login-btn {
        color: white;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        background: transparent;
        border: 1px solid white;
        border-radius: 32px;
        padding: 11px 20px;
        position: relative;
        &:hover, &:focus {
          border-color: $c4; }
        svg {
          margin-left: 10px;
          margin-top: -2px; }
        span {
          padding-left: 14px; } } } }
  //end main menu
 }  //// submenu user login
.user-info {
  > .nav-item {
    height: 31px;
    width: 31px;
    >.dropdown-toggle {
      &:after {
        display: none; }
      &:before {
        width: 37px;
        height: 37px;
        background: url("../../assets/images/user.png") center no-repeat;
        content: "";
        left: 0;
        position: absolute;
        top: -2px;
        border-radius: 50%;
        border: 1px solid #fff; }
      &:hover {
        &:before {
          border-color: $c4; } } }
    >.show {
      padding: 10px 0;
      border: none;
      left: unset;
      right: -13px;
      top: 53px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      background: #242936;
      min-width: 200px;
      border-radius: 0 0 15px 15px;
      >.dropdown-item {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 36px;
        padding: 0 42px 0 24px;
        &:hover, &:focus, &:active {
          color: rgba(46, 227, 169, 0.8);
          background: #304471; } } }


 } }    // end submenu user login

//end menu desktop
