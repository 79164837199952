.notfound-desc {
  text-align: center;
  img {
    max-width: 100%; }
  h3 {
    color: $c2;
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    margin-top: 35px; }
  a.btn-primary.btn {
    margin: 42px auto 0;
    width: 235px; } }

.stop-war {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  padding: 3px 0 4px;
  .text {
    display: inline-block;
    padding-top: 3px; }
  .ua-flag {
    width: 24px;
    display: inline-block;
    margin: 0 20px;
    .top {
      background: #005bbb;
      height: 8px;
      margin: 0;
      padding: 0; }
    .bottom {
      background: #ffd500;
      height: 8px;
      margin: 0;
      padding: 0; } } }
