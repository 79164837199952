.category-item {
  display: block;
  height: 80px;
  background: #fff;
  box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 23px;
    bottom: 29px;
    right: 24px;
    @media #{$tablet-strict} {
      right: 10px; } }
  &:hover, &:focus {
    box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.2); }
  .item-badge {
    width: 80px;
    float: left;
    height: 100%;
    background: #2ee3a9 url("../../assets/images/icon-book.svg") center  19px no-repeat; }
  .item-title {
    color: $c1;
    letter-spacing: 0.7px;
    line-height: 24px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 29px 0 0 22px;
    display: table; }
  &:nth-child(2n+1) {
    margin-bottom: 24px; }
  &:nth-child(2n) {
    margin-top: 12px; }
  &.category-item-1 {
    height: 184px;
    margin: 0;
    .item-badge {
      background-color: #82d6c8; }
    &:after {
      background: url("../../assets/images/category-icons/ci-1.svg") no-repeat; } }
  &.category-item-2 {
    .item-badge {
      background-color: #7e90ff; }
    &:after {
      background: url("../../assets/images/category-icons/ci-2.svg") no-repeat; } }
  &.category-item-3 {
    .item-badge {
      background-color: #b0c5ed; }
    &:after {
      background: url("../../assets/images/category-icons/ci-3.svg") no-repeat; } }
  &.category-item-4 {
    .item-badge {
      background-color: #8bbde6; }
    &:after {
      background: url("../../assets/images/category-icons/ci-4.svg") no-repeat; } }
  &.category-item-5 {
    .item-badge {
      background-color: #4997d8; }
    &:after {
      background: url("../../assets/images/category-icons/ci-5.svg") no-repeat; }
    @media #{$tablet-strict} {
      .item-title {
        padding-top: 20px; } }
    @media #{$phone-strict} {
      .item-title {
        padding: 29px 35px 0 22px; } } }
  @media #{$phone-strict} {
    &.category-item-1 {
      height: 80px;
      margin-bottom: 25px; }
    &:nth-child(2n) {
      margin: 12px 0; } }
  @media #{$desktop-strict} {
    .item-title {
      font-size: 14px;
      line-height: 18px;
      padding: 29px 35px 0 22px; } }
  @media #{$tablet-strict} {
    .item-badge {
      width: 50px; }
    .item-title {
      font-size: 13px; } } }
.category-list-container {
  padding: 0;
  .headline-small {
    padding-left: 15px; } }
