/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 21px;
  height: 20px;
  left: 0;
  top: 12px;
  opacity: 0.8; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 10% !important; }

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  opacity: 1!important;
  background: $c4; }


/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 37px!important;
  height: 37px!important;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 1!important;
  text-align: center;
  &:hover {
    background: $c4;
    > span {
      >.bm-cross {
        background: $c5; } } }
  > span {
    top: 9px !important;
    right: 18px!important;
    >.bm-cross {
      background: white; } } }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  width: 2px!important;
  height: 16px!important; }
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100% !important; }

/* General sidebar styles */
.bm-menu {
  background: #232936;
  padding: 50px 0 8px;
  font-size: 1.15em; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad; }

/* Individual item */
.bm-item {
  display: inline-block; }

#page-wrap {
  font-family: "Red Hat Display", sans-serif;
  display: flex !important;
  flex-direction: column;
  min-height: 100%;
  padding: 16px;
  .mobile-menu-section {
    display: flex;
    flex-direction: column;
    &:first-child {
      h2 {
        border-top: none; } }
    h2 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.5px;
      padding: 16px 10px 12px;
      border-top: 1px solid rgba(255,255,255,0.3);
      color: #fff;
      position: relative;
      text-transform: uppercase;
      &.oswiata, &.kik {
        padding-left: 40px;
        &:before {
          position: absolute;
          top: 17px;
          left: 10px;
          width: 25px;
          height: 20px;
          content: '';
          background: url("../../assets/images/logos/logo-mini-default.svg");
          background-size: cover!important;
          background-repeat: no-repeat!important; } }
      &.oswiata {
        &:before {
          background: url("../../assets/images/logos/logo-mini-oswiata.svg");
          background-size: cover!important;
          background-repeat: no-repeat!important; } }
      &.kik {
        &:before {
          background: url("../../assets/images/logos/logo-mini-kik.svg");
          background-size: cover!important;
          background-repeat: no-repeat!important; } } }
    .items-wrapper {
      margin-left: 40px;
      a {
        color: #fff;
        display: flex;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
        gap: 16px;
        align-items: center;
        &:hover {
          color: $c4;
          text-decoration: underline;
          gap: 12px;
          padding-left: 4px;
          transition: 0.2s all; } } } }

  @media #{$tablet-strict} {
    justify-content: normal; } }
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

.mobile-menu-container {
  position: relative; }

#mobile-menu {
  position: sticky;
  background: #232936!important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  font-family: "Red Hat Display", sans-serif;
  .mobile-menu-container {
    #outer-container {
      width: 61px; }
    .logo-header {
      margin-right: 0; }
    .user-info {
      padding-bottom: 30px;
      .nav-item {
        color: #ffffff;
        font-size: 16px;
        line-height: 36px;
        background: none;
        padding: 0 40px;
        &:focus, &:hover {
          background-color: #304471;
          color: #b0c5ed; }
        &.user-data {
          color: #b0c5ed;
          padding: 0 19px;
          svg {
            color: #b0c5ed;
            margin-right: 5px; }
          &:focus, &:hover {
            background: transparent; } } }
      .btn {
        width: 200px;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 30px;
        position: relative;
        border-radius: 20px;
        margin: 0 auto 16px;
        &:after {
          content: "";
          width: 18px;
          height: 15px;
          background: url("../../assets/images/arr-white.svg") no-repeat;
          right: 17px;
          top: 12px;
          position: absolute; }
        &:focus, &:hover, &:active {
          outline: none;
          box-shadow: none;
          &:after {
            right: 22px;
            transition: all 0.3s; } }

        &.login-btn {
          border: 2px solid #ffffff;
          background: transparent;
          svg {
            display: none; } }
        &.purchase-btn {
          background: $c2;
          border: none;
          line-height: 32px; } } }
    .menu-mobile-right-icons {
      display: flex;
      .modal-content-container {
        padding-right: 0; }
      .login-btn {
        background: transparent;
        border: none;
        padding: 0;
        margin-right: 20px;
        font-size: 18px;
        opacity: 0.8;
        position: relative;
        height: 20px;
        width: 18px;
        color: transparent;
        &:before {
          content: "";
          position: absolute;
          width: 18px;
          height: 20px;
          background: url("../../assets/images/profile-menu-mobile.png") no-repeat;
          top: 0;
          left: 0; }
        img {
          display: none; }
        > svg {
          display: none; }
        > span {
          display: none; } }
      .search-btn {
        background: transparent;
        position: relative;
        border: none;
        padding: 0;
        font-size: 18px;
        width: 18px;
        height: 20px;
        &:before {
          content: "";
          position: absolute;
          width: 18px;
          height: 20px;
          background: url("../../assets/images/search-menu-mobile.svg") no-repeat;
          top: 0;
          left: 0; }
        > svg {
          display: none; } } } } }
#profile-modal {
  margin: 0;
  width: 100%;
  max-width: 100%;
  font-family: "Red Hat Display", sans-serif;
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
    background-color: #232936;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    border: none;
    .modal-content-container {
      padding-left: 15px;
      .modal-header {
        border: none;
        width: 100%;
        .close {
          font-size: 35px;
          font-weight: 300;
          text-shadow: none;
          color: #fff;
          opacity: 0.8;
          margin-top: -24px; }
        .menu-mobile-category-badge {
          color: #b0c5ed;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 0.65px;
          line-height: 22px;
          text-transform: uppercase;
          svg {
            font-size: 16px;
            margin-right: 7px; } } }
      .modal-body {
        padding: 0;
        .user-info {
          width: 100%;
          .user-data {
            color: #ffffff;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.65px;
            line-height: 38px;
            padding: 0 42px;
            text-transform: uppercase;
            height: auto;
            width: auto; }
          .nav-link, .logout {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 38px;
            padding: 0 42px;
            &:focus, &:hover {
              background-color: #304471;
              color: #b0c5ed; } } } } } } }
#search-modal {
  font-family: "Red Hat Display", sans-serif;

  .search-form {
    .form-control {
      height: 40px;
      border-radius: 12px;
      border: 2px solid #c4c4c4;
      background-color: #ffffff;
      margin: 0 auto 36px;
      max-width: 320px; }
    .form-control::placeholder {
      color: #A5A5A5;
      font-style: italic; }
    .btn {
      min-width: 120px; } } }

.fade {
  opacity: 1;
  transition: opacity 200ms linear;
  .show {
    opacity: 1;
    transition: opacity 200ms linear; }
  .backdrop.fade.show {
    opacity: 0.5;
    transition: opacity 200ms linear; } }
