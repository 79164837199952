.register-product-account-container, .register-product-buyer-container {
  padding: 76px 0 65px;
  flex-flow: column;
  &.register-product-users-container {
    flex-flow: row;
    @media #{$phone-strict} {
      flex-flow: column; } }
  .webinar-btn {
    background: $c4;
    color: $c5;
    width: 153px;
    padding-left: 17px;
    float: right;
    @media #{$phone-strict} {
      margin: 0 auto 30px; }
    &:after {
      background: url("../../assets/images/arr-black.png") no-repeat; }
    &.btn-reverse {
      background: transparent;
      border-color: $c4;
      padding-right: 17px;
      padding-left: 30px;
      text-align: right;
      width: 111px;
      &:after {
        transform: rotate(180deg);
        left: 17px;
        right: unset; }
      &:hover {
        &:after {
          right: unset;
          left: 22px; } } } } }

.rpa-login-form-container {
  font-family: Signika, sans-serif;
  >.container {
    @media #{$phone-strict} {
      padding-left: 0;
      padding-right: 0; } }
  .webinar-help-container {
    margin: 25px 0;
    padding-right: 90px;
    padding-top: 25px;
    @media #{$desktop-strict} {
      padding: 40px 95px 30px 33px; }
    @media #{$phone-strict} {
      width: 90%;
      margin: 25px auto; }
    h2 {
      color: #1d1d1d;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      margin: 0 0 10px;
      position: relative;
      &:after {
        width: 89px;
        height: 84px;
        background: url("../../assets/images/webinar-help.png") no-repeat;
        content: '';
        position: absolute;
        right: -90px;
        top: 20px; } }
    p {
      color: #656565;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.11px;
      line-height: 14px;
      margin: 0 0 20px; }
    h3 {
      color: $c1;
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      position: relative;
      padding-left: 0;
      line-height: 22px;
      &:before {
        content: "";
        width: 25px;
        height: 25px;
        background: url("../../assets/images/green-phone.png") no-repeat;
        background-size: contain !important;
        position: absolute;
        left: -20px;
        top: 0; } } }
  h3 {
    text-align: center;
    color: $c2;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    span {
      display: block; } }
  .background-wrapper {
    background: #fff;
    padding: 55px 35px;
    height: 100%;
    @media #{$phone-strict} {
      padding: 55px 0; }
    .rpa-login-form {
      border-right: 1px solid rgba(199, 199, 199, 1);
      .register-login-form-wrapper {
        padding-right: 35px;
        @media #{$phone-strict} {
          padding-right: 0; }
        > p {
          text-align: center;
          color: rgba(101, 101, 101, 1);
          font-size: 14px;
          line-height: 16px; } } }
    .rpa-register-form {
      padding-left: 50px;
      @media #{$phone-strict} {
        padding-left: 0; } } }
  form {
    .form-group {
      margin: 0 auto;
      height: auto !important;
      min-height: 51px;
      .invalid-feedback {
        margin-top: 0;
        text-align: center; }
      input {
        height: 31px;
        border-radius: 25px;
        box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding-left: 25px; }
      > label {
        color: $c5;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.12px;
        line-height: 14px;
        position: relative;
        input {
          display: table;
          float: left;
          height: 16px;
          margin-right: 20px;
          width: 16px; }
        div {
          display: table;
          span {
            color: red; }
          a {
            color: $c2;
            text-decoration: underline; } }
        input[name="acceptTerms"] ~ .invalid-feedback, input[name="marketingAgreement"] ~ .invalid-feedback {
          position: absolute;
          top: inherit;
          bottom: -6px;
          left: -6px; }
        .invalid-feedback {
          font-size: 13px;
          margin-left: 12px;
          @media #{$desktop-strict} {
            top: 40px; }
          @media #{$tablet-strict} {
            top: 24px; } } } }
    .login-btn {
      display: table;
      margin: 20px auto;
      background: transparent;
      color: #000;
      border-radius: 30px;
      border: 2px solid rgba(103, 118, 151, 0.5);
      font-size: 16px;
      line-height: 32px;
      font-weight: 700;
      text-align: left;
      min-width: 187px;
      padding: 3px 20px;
      transition: 0.3s all;
      &:after {
        content: '';
        position: absolute;
        right: 16px;
        top: 12px;
        height: 13px;
        width: 18px;
        transition: 0.3s all;
        background: url("../../assets/images/arr-black.svg") no-repeat; }
      &:hover {
        transition: 0.3s all;
        &:after {
          right: 22px;
          transition: 0.3s all; } } } } }

.rpa-offer {
  > .container {
    background: #fff;
    height: 100%;
    .register-offer-wrapper {
      padding: 65px 25px 30px;
      h2 {
        color: #1d1d1d;
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 20px; }
      p {
        font-size: 15px;
        line-height: 24px;
        color: $c5;
        margin-bottom: 0; }
      .green-dot {
        font-family: "Red Hat Display", sans-serif;
        font-size: 14px;
        color: #1d1d1d;
        padding-left: 16px;
        position: relative;
        line-height: 20px;
        margin-top: 10px;
        > a {
          color: inherit;
          font-weight: 700;
          text-decoration: underline; }
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $c4;
          left: 4px;
          top: 5px;
          position: absolute; } }
      h3 {
        font-size: 15px;
        color: rgba(101, 101, 101, 1);
        border-bottom: 1px solid rgba(101, 101, 101, 1);
        text-transform: uppercase;
        padding-bottom: 3px;
        position: relative;
        &.payu {
          margin-bottom: 35px;
          padding-top: 50px;
          span {
            position: absolute;
            left: 0;
            bottom: -26px;
            font-size: 14px;
            font-family: "Red Hat Display", sans-serif; }
          &:after {
            width: 49px;
            height: 24px;
            top: 48px;
            background-size: cover;
            right: 0;
            content: '';
            position: absolute;
            background-image: url("../../assets/images/payu.svg"); } } }
      .price-container {
        font-family: Signika, sans-serif;
        text-align: right;
        h4 {
          font-size: 44px;
          font-weight: 600;
          small {
            font-size: 32px; }
          span {
            font-size: 15px;
            font-weight: 400; } }
        h5 {
          font-size: 18px;
          font-weight: 600;
          small, span {
            font-size: 14px;
            font-weight: 400; } } }
      .btn-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 30px; } } } }
.registration-account-container {
  .breadcrumb-container {
    .container {
      .col {
        display: none;
        &.active {
          display: block;
          .reg-breadcrumb {
            color: $c2;
            font-size: 14px;
            font-family: Signika, sans-serif;
            opacity: 1;
            font-weight: 400; } } } } } }

// step 2 buyer form

.register-product-buyer-container {
  .payment-container {
    .background-wrapper {
      &:before {
        left: 33%; } }
    .buyer-form-container {
      .payment-data-container {
        .payment-item {
          background: #F0F2F6;
          text-transform: uppercase;
          color: $c2;
          font-weight: 300;
          border-radius: 11px;
          font-size: 16px;
          line-height: 21px;
          padding: 14px 22px 14px;
          margin-top: 12px;
          h2 {
            font-weight: 600;
            font-size: 14px; }
          p {
            margin-bottom: 0;
            &.invalid-code-message {
              text-transform: none;
              color: red;
              font-weight: 500; } }
          .form-box-discount {
            p {
              font-size: 14px;
              text-transform: none;
              margin-bottom: 6px;
              font-weight: 500;
              margin-top: 0; } }
          &.vat-release {
            text-transform: initial;
            font-size: 14px;
            font-weight: 400;
            strong {
              font-weight: 600; }
            .btn {
              width: 100%;
              margin-top: 16px;
              margin-bottom: 6px;
              float: none; } }
          &.payment-radio {
            background: transparent;
            .payu {
              flex-direction: column;
              label {
                color: transparent;
                position: relative;
                &:before {
                  width: 60px;
                  height: 30px;
                  top: 8px;
                  background-size: cover;
                  left: 0;
                  content: '';
                  position: absolute;
                  background-image: url("../../assets/images/payu.svg"); } }
              .invoice {
                margin-top: 30px;
                label {
                  color: initial;
                  padding-top: 15px;
                  &:before {
                    display: none; } } } } } } } } }



  .background-wrapper {
    position: relative;
    padding: 0 35px 55px;
    @media #{$phone-strict} {
      padding: 0 10px 40px; }
    &:before {
      position: absolute;
      top: 0;
      height: 8px;
      width: 33%;
      left: 0;
      background: $c2;
      content: '';
      z-index: 99; }
    &:after {
      position: absolute;
      top: 0;
      height: 8px;
      width: 100%;
      z-index: 9;
      left: 0;
      background: rgba(211, 217, 227, 1);
      content: ''; }
    .form-box-gray {
      border-radius: 11px;
      color: $c2;
      background: rgba(240, 242, 246, 1);
      padding: 17px 26px;
      margin-bottom: 16px;
      font-size: 13px;
      label {
        line-height: 22px; }
      h4 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        span {
          color: red; } }
      p {
        margin: 12px 0 12px; }
      .discount-code-btn {
        background: transparent;
        color: $c5;
        border: none;
        justify-content: right;
        display: flex;
        width: 100%;
        padding-right: 30px;
        position: relative;
        &:after {
          content: '';
          background: url("../../assets/images/arr-black.svg") no-repeat;
          width: 14px;
          background-size: contain;
          height: 11px;
          right: 0;
          top: 11px;
          position: absolute;
          transition: 0.3s all; }
        &:hover {
          &:after {
            right: 10px;
            transition: 0.3s all; } } }
      .delete-code {
        background: transparent;
        color: $c2;
        border: none;
        font-family: "Red Hat Display", sans-serif;
        font-size: 13px;
        letter-spacing: 0.12px;
        padding-left: 0;
        &:hover {
          text-decoration: underline; } } } }
  #BuyerForm {
    .buyer-form-container {
      padding-bottom: 0; }
    .order-data-container {
      flex-direction: row;
      h3 {
        font-family: "Red Hat Display", sans-serif;
        color: $c2;
        font-size: 14px;
        line-height: 16px;
        opacity: 1;
        font-weight: 700;
        text-align: left;
        margin: 8px 0 0; }
      .form-col-wrapper {
        padding: 0; }
      .nav-tabs {
        padding: 0;
        border-bottom: 0;
        .nav-item {
          font-family: "Red Hat Display", sans-serif;
          margin-right: 0;
          width: 50%;
          max-width: 160px;
          text-transform: uppercase;
          color: $c2;
          text-align: center;
          font-size: 14px;
          line-height: 16px;
          opacity: 1;
          font-weight: 500;
          &.active {
            background: #f0f2f6;
            border: none;
            border-radius: 11px 11px 0 0; } } }
      .tab-pane {
        &.active {
          flex-direction: column;
          &.tab-company {
            height: auto; } }
        .register-form-content {
          padding: 0 26px;
          .form-check-label {
            font-size: 13px; }
          &.register-form-invoice-content {
            h3 {
              position: initial; }
            p {
              color: $c2;
              font-size: 14px;
              font-weight: 600;
              font-family: "Red Hat Display", sans-serif;
              margin-top: 50px;
              margin-bottom: 30px; } } }
        .register-primar {
          background: rgba(240, 242, 246, 1);
          border-bottom-right-radius: 11px;
          border-bottom-left-radius: 11px; }
        .vat-release {
          display: flex;
          position: relative;
          span {
            background: #000;
            border-radius: 50%;
            color: #fff;
            width: 12px;
            height: 12px;
            font-size: 11px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            cursor: pointer; }
          &:hover {
            &:after {
              content: 'Zaznacz, jeżeli reprezentujesz jednostkę budżetową';
              position: absolute;
              width: 168px;
              height: 74px;
              padding: 7px;
              left: 143px;
              top: 0;
              border-radius: 12px;
              border: 2px solid $c2;
              background: #fff;
              font-size: 13px;
              font-weight: 300; } } } } } }
  &.register-product-users-container {
    .background-wrapper {
      .buyer-form-container {
        .users-list-container {
          padding-top: 20px;
          h2 {
            color: $c2;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 24px;
            span {
              color: #C73838; } }
          .list-container {
            .list-item {
              display: flex;
              justify-content: space-between;
              .list-item-data {
                display: flex;
                flex-direction: row;
                width: 100%;
                p {
                  margin-right: 30px; } }
              .delete-btn {
                border: 1px solid #C73838;
                color: #707070;
                background: transparent;
                border-radius: 50%;
                width: 23px;
                height: 23px;
                box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.29);
                display: table;
                padding: 0;
                text-align: center;
                font-weight: 600;
                transition: all 0.3s;
                span {
                  line-height: 21px;
                  vertical-align: text-bottom; }
                &:hover {
                  transition: all 0.3s;
                  background: rgba(229,103,103,.51);
                  box-shadow: inset 0px 3px 6px rgba(229,103,103,.51); } } } }
          .addUser {
            border: 1px solid $c4;
            border-radius: 25px;
            background: transparent;
            box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.29);
            font-size: 15px;
            color: #7C7C7C;
            font-weight: 400;
            transition: 0.3s all;
            span {
              font-weight: 600; }
            &:hover {
              transition: 0.3s all;
              background: $c4;
              color: #000; } }
          .form-wrapper {
            .form-group {
              input {
                &.is-invalid {
                  border: 2px solid #dc3545; } } }
            .list-item-wrapper {
              display: flex;
              .add-number {
                margin: 4px 10px 0 0; } } } } } } } }
