.newsletter-container {
  padding: 30px 35px;
  box-shadow: 0px 3px 6px #D9D9D9;
  border: 1px solid #232936;
  border-radius: 9px;
  font-family: "Red Hat Display", sans-serif;
  background: #fff;
  margin-top: 25px;
  h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 22px; }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 22px; }
  .img-picture {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    img {
      width: 94px;
      height: 126px;
      background: #C0CBE0;
      box-shadow: 0 3px 6px #00000029;
      object-fit: cover;
      margin-right: 15px; }
    .ebook-data {
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600; }
      h4 {
        font-size: 15px;
        line-height: 16px;
        font-style: italic;
        font-weight: 500; } } }
  .newsletter-form-container-content {
    form {
      .field-wrapper {
        position: relative;
        margin-bottom: 30px;
        max-width: 300px;
        @media #{$tablet-strict} {
          margin: 30px auto; }
        .newsletter-btn {
          border-radius: 25px;
          height: 40px;
          padding: 7px 8px 6px;
          border: 2px solid #232936;
          position: absolute;
          top: 0;
          background: #F0F2F6;
          color: #000;
          font-weight: 600;
          right: 0;
          transition: all 0.3s;
          &:hover {
            transition: 0.3s all;
            background: $c4; } } }
      .form-group {
        margin-bottom: 15px;
        position: relative;
        .invalid-feedback {
          position: absolute;
          left: 24px;
          top: 38px; }
        input {
          border-radius: 25px;
          border: 2px solid #232936;
          box-shadow: inset 0px 3px 6px #00000029;
          background-color: #ffffff;
          height: 40px;
          padding: 7px 22px 6px;
          &.is-invalid {
            background: rgba(250, 15, 15, 0.1);
            border-color: red; } }
        > label {
          color: #000;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0.12px;
          line-height: 14px;
          input {
            display: table;
            float: left;
            height: 16px;
            margin-right: 14px;
            width: 16px; }
          div {
            display: table;
            span {
              color: red; }
            a {
              color: #000;
              text-decoration: underline; } }
          input[name="acceptTerms"] ~ .invalid-feedback {
            top: 24px; }
          input[name="marketingAgreement"] ~ .invalid-feedback {
            top: inherit;
            bottom: -6px; }
          .invalid-feedback {
            font-size: 13px;
            margin-left: 6px;
            @media #{$desktop-strict} {
              top: 40px; }
            @media #{$tablet-strict} {
              top: 24px; } } } } } } }
