.about-us-container {
  h2 {
    color: $c2;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; }
  .about-us-head {
    text-align: center;
    margin-bottom: 25px;
    @media #{$desktop} {
      margin-bottom: 45px; } } }
.about-us-definition {
  padding-top: 80px;
  .container {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      background: url("../../assets/images/static/about-us.svg") no-repeat;
      right: 0;
      bottom: 0;
      width: 330px;
      height: 266px;
      background-size: contain;
      @media #{$phone-strict} {
        display: none; }
      @media #{$large} {
        right: 130px; } }
    h2 {
      margin-bottom: 30px; }
    p {
      color: $c1;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 20px; } } }

.about-us-benefits-section {
  padding: 20px 0;
  @media #{$desktop} {
    padding: 67px 0 120px;
    display: flex; }
  @media #{$tablet} {
    display: flex; }
  .item-container {
    @media #{$desktop} {
      display: flex; }
    @media #{$phone-strict} {
      margin-bottom: 24px;
      background: #fff; }
    .item-wrapper {
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      @media (min-width: 767px) and (max-width: 991px) {
        min-height: 306px; }
      @media #{$desktop} {
        padding: 36px 42px 28px; }
      .item-badge {
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
        &:after {
          content: "";
          height: 120px;
          width: 120px;
          left: 0;
          top: 0;
          position: absolute;
          background-repeat: no-repeat; } }
      .card-body {
        text-align: center;
        @media #{$tablet-strict} {
          padding: 0; }
        .card-title {
          color: $c2;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.7px;
          line-height: 32px;
          text-transform: uppercase;
          white-space: nowrap; }
        .benefit-content {
          color: $c1;
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0.15px;
          line-height: 20px; } } }
    &.item-1 {
      .item-wrapper {
        .item-badge {
          background-color: #b0c5ed;
          &:after {
            background: url("../../assets/images/svg/icons8-notification-50.svg") no-repeat center center; } } } }
    &.item-2 {
      .item-wrapper {
        .item-badge {
          background-color: #7e90ff;
          &:after {
            background: url("../../assets/images/svg/icons8-planner-50.svg") no-repeat center center; } } } }
    &.item-3 {
      .item-wrapper {
        .item-badge {
          background-color: #82d6c8;
          &:after {
            background: url("../../assets/images/svg/icons8-hand-50.svg") no-repeat center center; } } } } } }
.about-us-badge {
  background: #2e1b46;
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    right: -20%;
    top: -90%;
    content: "";
    width: 300px;
    background-color: $c4;
    border-radius: 50%; }
  h3 {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 0;
    padding: 30px 0;
    @media #{$phone-strict} {
      text-align: center;
      font-weight: 400;
      font-size: 24px; }
    >span {
      color: $c4;
      text-decoration: underline; } } }
.about-us-circle-section {
  background: url("../../assets/images/circles.png") no-repeat center;
  padding-top: 60px;
  flex-direction: column;
  .circle-partners {
    padding-top: 20px;
    @media #{$desktop} {
      padding-top: 120px; }
    .partner-item {
      img {
        height: 170px;
        width: 170px;
        border-radius: 50%;
        background: #fff;
        display: block;
        margin: 0 auto; } } } }
.about-us-contact {
  background: #fff;
  padding: 46px 0;
  @media #{$phone-strict} {
    padding: 24px 0;
    text-align: center; }
  h4 {
    line-height: 32px;
    font-weight: 400;
    color: $c2;
    > span {
      font-weight: 700;
      margin-left: 20px;
      white-space: nowrap; } } }
