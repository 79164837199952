.partners-introduction-section {
  overflow: hidden;
  position: relative;
  &:before {
    position: absolute;
    left: -53%;
    top: 20%;
    content: "";
    width: 1614px;
    height: 1610px;
    background-color: rgba(126, 144, 255, 0.1);
    border-radius: 50%; } }
.partners-introduction-content {
  padding-top: 80px;
  padding-bottom: 80px;
  @media #{$phone-strict} {
    padding: 48px 15px 24px; }
  .partners-introduction-left {
    padding-bottom: 15px;
    @media #{$tablet} {
      border-right: 1px solid #7e90ff; }
    @media #{$desktop} {
      padding-right: 150px; }
    h3 {
      color: $c2;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 30px; }
    p {
      color: $c1;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 20px; } }
  .partners-introduction-right {
    >ul {
      padding: 0;
      @media #{$tablet} {
        padding: 30px 0 0 30px; }
      @media #{$desktop} {
        padding: 30px 0 0 120px; }
      >li {
        color: $c1;
        letter-spacing: 0.7px;
        line-height: 24px;
        text-transform: uppercase;
        padding-bottom: 20px; } } } }
.stage-container {
  @media #{$desktop} {
    padding-bottom: 78px; }
  h3 {
    color: $c2;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 42px;
    @media #{$phone-strict} {
      margin-bottom: 24px; } }
  .stage-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 42px;
    .item-badge {
      order: 0;
      flex: 0 1 80px;
      align-self: auto;
      min-height: 80px;
      background: #82d6c8;
      box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.1);
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 80px;
        height: 80px;
        left: 0;
        top: 0;
        background: url("../../assets/images/svg/icons8-ebook-50.svg") no-repeat center center; } }
    .item-title {
      box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.1);
      order: 1;
      flex: 1 0 auto;
      align-self: auto;
      min-height: 80px;
      background: #fff;
      color: $c1;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.7px;
      line-height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding: 0 32px;
      flex-basis: inherit;
      @media #{$phone-strict} {
        $a: 100%;
        $b: 80px;
        max-width: calc(#{$a} - #{$b}); } }
    .item-content {
      order: 2;
      flex: 0 1 100%;
      align-self: auto;
      background: #f8f8f8;
      padding: 18px 42px;
      color: $c1;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 20px;
      $a: 100%;
      $b: 80px;
      min-height: calc(#{$a} - #{$b}); }

    &.stage-item-2 {
      .item-badge {
        background: #6396c1;
        &:before {
          background:  url("../../assets/images/svg/icons8-commercial-50.svg") no-repeat center center; } } }
    &.stage-item-3 {
      .item-badge {
        background: #3d8dd0;
        &:before {
          background:  url("../../assets/images/svg/icons8-book-reading-50.svg") no-repeat center center; } } }
    &.stage-item-4 {
      .item-badge {
        background: #7e90ff;
        &:before {
          background:  url("../../assets/images/svg/icons8-video-conference-50.svg") no-repeat center center; } } }
    &.stage-item-5 {
      .item-badge {
        background: #b0c5ed;
        &:before {
          background:  url("../../assets/images/svg/icons8-trophy-50.svg") no-repeat center center; } } } } }

.partners-blue-section {
  background: #304471;
  overflow: hidden;
  position: relative;
  padding: 120px 0 40px;
  @media #{$phone-strict} {
    padding: 40px 0 0; }
  &:before {
    position: absolute;
    content: "";
    right: -11%;
    top: -30%;
    width: 1300px;
    height: 1300px;
    background-color: rgba(126, 144, 255, 0.2);
    border-radius: 50%; }

  .partners-item {
    padding-bottom: 80px;
    color: #fff;
    @media #{$phone-strict} {
      padding-bottom: 40px; }
    h3 {
      color: #b0c5ed;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      @media #{$phone-strict} {} }
    ul {
      padding-left: 15px;
      margin-bottom: 0;
      > li {
        font-size: 15px;
        letter-spacing: 0.15px;
        line-height: 20px;
        padding-top: 10px; } } } }
.partners-contact-section {
  background: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
  position: relative;
  margin-top: 40px;
  @media #{$phone-strict} {
    padding-bottom: 0; }
  &:before {
    position: absolute;
    content: "";
    right: -64%;
    top: -83%;
    width: 1300px;
    height: 1000px;
    background-color: rgba(126, 144, 255, 0.1);
    border-radius: 50%; }
  .partners-contact-content {
    @media #{$tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    @media #{$phone-strict} {
      text-align: center;
      margin-bottom: 32px; }
    h5 {
      color: $c1;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.7px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 16px; }

    h4, h4 > a {
      color: $c2;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
      @media #{$phone-strict} {
        font-size: 18px; } } }
  .partners-contact-img {
    @media #{$tablet} {
      display: flex;
      align-self: center; }
    img {
      width: 100%;
      max-width: 101px;
      height: 101px;
      border-radius: 50%;
      float: left;
      object-fit: cover;
      background-color: darkseagreen;
      @media #{$phone-strict} {
        float: none;
        display: block;
        margin: 0 auto; }
      @media #{$tablet-strict} {
        float: none;
        display: block;
        margin: 0 auto; } }
    p {
      color: $c2;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 0;
      padding-left: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media #{$phone-strict} {
        display: block;
        padding: 24px 0;
        text-align: center; }
      > span {
        display: block;
        color: $c1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.7px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 16px; } } } }
