.btn-quiz {
  color: #304471;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  display: block;
  min-width: 200px;
  border-radius: 20px;
  border: 2px solid #304471;
  background: transparent;
  position: relative;
  &:hover {
    color: #304471; }
  &.btn-arr {
    &:after {
      background: url("../../assets/images/arr-navy.svg") no-repeat;
      background-size: contain;
      top: 11px; } }
  &.no-arr {
    padding-right: 20px!important;
    &:after {
      display: none; } }
  &.btn-inner-quiz {
    min-width: auto;
    padding-right: 60px;
    margin: 40px auto;
    font-weight: 400;
    &:hover, &:focus {
      opacity: 0.8;
      color: #ffffff;
      background: $c2;
      &:after {
        background: url("../../assets/images/arr-white.svg") no-repeat;
        background-size: contain;
        right: 15px; } }
    &:focus {
      opacity: 1; } } }

.quiz-modal {
  padding: 0!important;
  .modal-dialog {
    margin: 0;
    max-width: 100%;
    min-height: 100%;
    height: 100%;

    .modal-content {
      background: #eceef4;
      min-height: 100%;
      .modal-content-container {
        display: flex;
        flex: 1 1 auto;
        .row {
          flex-direction: column;
          width: 100%;
          @media #{$phone-strict} {
            margin: 0; }
          .modal-header {
            padding: 20px 0 0 0;
            border: none;
            img {
              padding-left: 15px;
              width: 150px; }
            .close {
              font-size: 50px;
              font-weight: 400;
              color: $c2; } }
          .modal-body {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            @media #{$phone-strict} {
              padding: 0; }
            .quiz-content {
              @media #{$phone-strict} {
                padding: 0; }
              .quiz-summary-content {
                h4, p, h5 {
                  background: #ffffff;
                  color: $c2;
                  font-size: 15px;
                  font-weight: 700;
                  letter-spacing: 0.15px;
                  line-height: 20px;
                  text-align: center;
                  margin: 0;
                  padding: 40px 96px 0;
                  @media #{$phone-strict} {
                    padding: 30px; } }
                p {
                  padding: 0 96px 40px; }
                h5 {
                  padding: 40px 96px; }
                .item-question-list-container {
                  padding: 0 96px 40px;
                  background: #fff;
                  max-height: 500px;
                  overflow-y: scroll;
                  @media #{$phone-strict} {
                    padding: 0 35px 30px;
                    max-height: none; }
                  .item-question {
                    padding: 20px 0;
                    .item-question-title {
                      font-size: 15px;
                      font-weight: 700;
                      letter-spacing: 0.15px;
                      line-height: 20px;
                      color: $c2;
                      margin-bottom: 16px; }
                    .item-question-answer {
                      position: relative;
                      font-size: 15px;
                      font-weight: 400;
                      letter-spacing: 0.15px;
                      line-height: 20px;
                      color: $c2;
                      margin-bottom: 16px;
                      &:after {
                        position: absolute;
                        content: "";
                        left: -35px;
                        top: 0;
                        width: 21px;
                        height: 21px;
                        background-repeat: no-repeat;
                        background-size: contain; }
                      @media #{$phone-strict} {
                        &:after {
                          left: -25px; } } }
                    &.failed {
                      .item-question-answer {
                        &:after {
                          background: url("../../assets/images/test-fail.svg"); } } }
                    &.correct {
                      .item-question-answer {
                        &:after {
                          background: url("../../assets/images/test-corr.svg"); } } } } }
                .item-question-list-container::-webkit-scrollbar-thumb {
                  width: 12px;
                  height: 24px;
                  border-radius: 6px;
                  background-color: #304471; }
                .item-question-list-container::-webkit-scrollbar {
                  width: 2px;
                  background: #b0c5ed; } }

              .quiz-question-form {
                background: #fff;
                color: $c2;
                padding: 40px 98px;
                @media #{$phone-strict} {
                  padding: 30px; }
                .form-label {
                  h5 {
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0.7px;
                    line-height: 24px;
                    text-transform: uppercase; }
                  p {
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 0.15px;
                    line-height: 20px; }
                  h4 {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 32px;
                    text-transform: uppercase; } }
                .form-check {
                  padding: 16px 0;
                  input {
                    width: 19px;
                    height: 19px;
                    border: 2px solid $c2;
                    opacity: 0.5;
                    border-radius: 50%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    left: -16px;
                    @media #{$phone-strict} {
                      left: -8px; } }
                  input[type="radio"]:checked {
                    opacity: 1;
                    //position: relative
                    &:after {
                      content: "";
                      background: url("../../assets/images/checked.svg") no-repeat;
                      width: 9px;
                      height: 11px;
                      position: absolute;
                      top: 2px;
                      left: 3px;
                      background-size: contain; } }
                  label {
                    font-size: 15px;
                    letter-spacing: 0.15px;
                    line-height: 20px; } } } } } } } } } }
