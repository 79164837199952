.circle-credentials {
  padding: 20px 15px;
  @media #{$desktop} {
    padding: 120px 15px; }
  .testimonial-item {
    display: flex;
    margin-bottom: 15px;
    @media #{$phone-strict} {
      padding: 0;
      margin-bottom: 24px; }
    .testimonial-item-wrapper {
      background: $c2;
      color: #fff;
      text-align: center;
      padding: 42px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      p {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.15px;
        line-height: 20px; }
      h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px; }
      h6 {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.7px;
        line-height: 24px;
        text-transform: uppercase; } } } }
