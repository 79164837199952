.static-main-badge {
  background-color: $c5;
  h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    padding: 50px 0;
    margin-bottom: 0;
    @media #{$phone-strict} {
      padding: 30px 0; } } }
.static-second-badge {
  background: $c5;
  h3 {
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 20px;
    padding: 20px 0;
    @media #{$phone-strict} {
      padding: 16px 0 8px; } } }
ul.green-list {
  list-style: none;
  padding-left: 0;
  color: #000000;
  font-size: 15px;
  line-height: 25px;
  > li {
    position: relative;
    padding-left: 40px;
    &:before {
      content: "";
      background: url("../../assets/images/benefit-icon.png") no-repeat;
      width: 21px;
      height: 21px;
      left: 0;
      top: 6px;
      position: absolute; } } }

a.btn.btn-contact {
  border: 2px solid $c2;
  background: transparent;
  color: $c2;
  border-radius: 40px;
  padding: 6px 17px;
  text-align: left;
  display: block;
  font-size: 16px;
  width: 200px;
  position: relative;
  transition: all 0.3s;
  &:after {
    content: "";
    width: 18px;
    height: 15px;
    background: url("../../assets/images/arr-navy.svg") no-repeat;
    right: 17px;
    top: 11px;
    position: absolute; }
  &:focus, &:hover {
    outline: none;
    box-shadow: none;
    &:after {
      right: 22px;
      transition: all 0.3s; } } }
.expired-access-box {
  a {
    color: $c2;
    font-weight: 500;
    &:focus, &:hover {
      text-decoration: underline;
      color: $c2; } } }

