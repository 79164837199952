#restricted-modal {
  max-width: 555px !important;
  @media #{$desktop} {
    top: 60px; }
  @media #{$phone-strict} {
    max-width: 100% !important;
    margin: 0; }
  .modal-content {
    border: 4px solid $c4;
    padding: 40px;
    background: #F0F2F6;
    border-radius: 10px;
    color: $c5;
    font-family: "Red Hat Display", sans-serif;
    @media #{$phone-strict} {
      padding: 15px; }
    .modal-content-container {
      padding: 0;
      @media #{$phone-strict} {
        padding: 20px; }
      .modal-header {
        flex-direction: column;
        padding: 0;
        border-bottom: none;
        @media #{$phone-strict} {
          padding: 0 15px; }
        h2 {
          margin: 0 0 6px;
          font-weight: 700;
          font-size: 30px; }
        .close {
          position: absolute;
          top: 10px;
          font-weight: 200;
          right: 10px;
          font-size: 60px;
          color: $c5; } }
      .modal-body {
        padding: 0;
        h3 {
          font-weight: 700;
          font-size: 18px;
          color: $c5; }
        p {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 24px;
          color: $c5;
          strong {
            font-weight: 900; } }
        .btn {
          margin: 6px auto 0;
          &:hover {
            background: $c5;
            border-color: $c5; } } } } } }
