.login-modal {
  padding: 0!important;
  .login-btn {
    color: $c2;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    display: block;
    margin: 0 auto;
    min-width: 200px;
    border-radius: 20px;
    border: 2px solid #304471;
    background: transparent;
    position: relative;
    &:after {
      content: "";
      width: 18px;
      height: 15px;
      background: url("../../assets/images/arr-navy.svg") no-repeat;
      right: 17px;
      top: 12px;
      position: absolute; }
    &:focus, &:hover {
      outline: none;
      box-shadow: none;
      &:after {
        right: 22px;
        transition: all 0.3s; } } }

  .modal-dialog {
    margin: 0;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    .modal-content {
      background-color: #e8f7fd;
      min-height: 100%;
      border-radius: 0;
      border: none;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      &:before {
        position: absolute;
        content: "";
        left: -84%;
        top: 2%;
        width: 1600px;
        height: 1600px;
        background-color: rgba(126, 144, 255, 0.09);
        border-radius: 50%; }
      .modal-content-container {
        display: flex;
        flex: 1 1 auto;
        .row {
          flex-direction: column;
          width: 100%;
          margin: 0;
          @media #{$phone-strict} {
            margin: 0; }
          .modal-header {
            padding: 20px 0 0 0;
            border: none;
            img {
              width: 150px;
              cursor: pointer; }
            .close {
              font-size: 50px;
              font-weight: 400;
              color: $c2; } }
          .modal-body {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .form-container-content {
              margin: 0 auto;
              padding: 100px 0 60px;
              @media #{$phone-strict} {
                padding: 48px 0 20px; }
              h3 {
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.7px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                color: $c2;
                margin-bottom: 24px; }
              p {
                text-align: center;
                color: $c2;
                > a {
                  color: $c2; } }
              .alert {
                position: absolute;
                top: 0;
                width: 100%; }
              #LoginForm, #PasswordReminder, #profileNewPassword {
                .form-group {
                  margin-bottom: 28px;
                  display: flex;
                  height: 40px;
                  position: relative;
                  input {
                    border-radius: 12px;
                    border: 2px solid #c4c4c4;
                    color: $c1;
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: 0.65px;
                    line-height: 32px;
                    height: 40px; }
                  .is-invalid {
                    border-color: rgba(229, 103, 103, 0.51); }
                  .invalid-feedback {
                    display: block;
                    position: absolute;
                    bottom: -20px;
                    left: 10px; } } }
              .recaptcha-container {
                margin-bottom: 40px;
                text-align: center; }
              .pass-reminder {
                color: $c2;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                text-align: center;
                display: block;
                text-decoration: underline;
                border: none;
                background: transparent;
                margin: 30px auto; } } } } } } }
  .mobile-login-offer {
    display: none;
    @media #{$phone-strict} {
      display: block;
      padding-bottom: 24px; }
    .btn-offer {
      background: $c2;
      color: #ffffff;
      display: block;
      margin: 0 auto;
      max-width: 200px;
      border: none;
      line-height: 32px;
      &:after {
        top: 14px;
        background: url("../../assets/images/arr-white.svg") no-repeat; } } }
  .modal-badge {
    display: none;
    background: $c2;
    color: #fff;
    bottom: 0;
    padding: 34px 0 22px;
    @media #{$phone-strict} {
      padding: 20px 15px;
      display: none; }
    .modal-badge-container {
      .modal-badge-content {
        float: left;
        h3 {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.7px;
          line-height: 24px;
          text-transform: uppercase; }
        p {
          font-size: 15px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0.15px;
          line-height: 20px; } }
      .white {
        float: none;
        border-color: #ffffff;
        color: #fff;
        min-width: 210px;
        font-weight: 400;
        margin-top: 25px;
        @media #{$tablet-strict} {
          display: table; }
        &:after {
          background: url("../../assets/images/arr-white.svg"); }
        @media #{$desktop} {
          float: right; } } } } }

