.buyer-form-container {
  padding-top: 35px;
  padding-bottom: 80px;
  @media #{$tablet-strict} {
    padding-bottom: 15px; }
  .order-data-container {
    flex-direction: column;
    padding-top: 48px;
    .nav-tabs {
      padding: 0 15px 30px;
      .nav-item {
        opacity: 0.8;
        color: #1d1d1d;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.65px;
        line-height: 20px;
        background: transparent;
        max-width: 115px;
        &.active {
          border-radius: 12px;
          background-color: #304471;
          opacity: 0.6;
          color: #ffffff; }
        &:first-of-type {
          margin-right: 15px; } } }

    .tab-pane {
      &.active {
        display: flex;
        flex-direction: row;
        position: relative;
        @media #{$phone-strict} {
          flex-direction: column; }
        &.tab-company {
          @media #{$desktop} {
            height: 480px; } }
        &.tab-person {
          @media #{$desktop} {
            height: 340px; } } }
      .register-form-content {
        .form-group {
          @media #{$phone-strict} {
            max-width: 100%; }
          &.tin-error {
            position: absolute;
            top: 40px;
            .is-invalid {
              display: none; } }
          .invalid-feedback {
            white-space: nowrap; }
          input {
            font-size: 15px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: 0.15px;
            line-height: 20px; }
          input::placeholder {
            font-size: 12px; } }
        .address-wrapper {
          display: flex;
          .col-6, .tin-wrapper {
            &:first-of-type {
              padding: 0 16px 0 0 !important; }
            &:last-of-type {
              padding: 0 0 0 16px; } }
          .col-6 {
            max-width: 133px;
            @media #{$phone-strict} {
              max-width: 50%; } }
          .btn {
            opacity: 0.5;
            color: #1d1d1d;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.14px;
            line-height: 20px;
            text-align: center;
            text-decoration: underline;
            padding: 0 0 28px;
            white-space: nowrap; } }
        .form-check {
          label {
            font-size: 15px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: 0.15px;
            line-height: 20px; } }
        &.register-form-invoice-content {
          h3 {
            position: absolute;
            left: 15px;
            top: -66px;
            @media #{$phone-strict} {
              position: initial;
              margin-top: 30px; } } } } } } }

.register-form-offer {
  &.register-form-order-offer {
    padding: 88px 0 180px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media #{$tablet-strict} {
      padding: 40px;
      text-align: center;
      &:before {
        position: absolute;
        content: "";
        background: #fff;
        width: 500vw;
        height: 100%;
        top: 0;
        right: 100%; } }
    @media #{$phone-strict} {
      padding: 40px 0;
      &:before {
        display: none; } }
    .register-info-block {
      h5 {
        margin-bottom: 0; } }
    .register-btn {
      width: 150px;
      @media #{$tablet-strict} {
        margin: 0 auto; }
      &.register-btn2 {
        width: auto;
        text-align: center;
        padding: 5px 20px;
        line-height: 25px;
        &:after {
          right: 56px;
          top: 34px; }
        @media #{$desktop-strict} {
          &:after {
            display: none; } }
        &:hover, &:focus {
          &:after {
            right: 64px; } } } } }
  &.register-form-payment-offer {
    padding: 88px 0 93px 50px;
    @media #{$tablet-strict} {
      padding: 40px;
      text-align: center;
      &:before {
        position: absolute;
        content: "";
        background: #fff;
        width: 500vw;
        height: 100%;
        top: 0;
        right: 100%; } }
    @media #{$phone-strict} {
      padding: 40px 0; }
    .register-btn {
      width: 215px;
      text-align: left;
      @media #{$desktop-strict} {
        width: 190px;
        font-size: 15px;
        padding: 3px 10px; } } } }

.reg-payment-form {
  .buyer-form-container {
    padding-bottom: 100px;
    @media #{$tablet-strict} {
      padding-bottom: 42px; }
    h5 {
      opacity: 0.8;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.98px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 0; }
    .form-group-container {
      flex-direction: column;
      @media #{$large} {
        margin-bottom: 90px; }
      .form-label {
        padding: 52px 0 32px;
        @media #{$phone-strict} {
          padding: 0 0 32px; } }
      .form-check {
        padding: 22px;
        background: #fff;
        margin-bottom: 28px;
        input {
          -moz-appearance: none;
          -webkit-appearance: none;
          -ms-progress-appearance: unset;
          opacity: 0.5;
          background: rgba(255,255,255,0.5);
          border-radius: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 20px;
          margin-top: 0;
          z-index: 999;
          border: 2px solid transparent;
          &:focus {
            outline: none; }
          &#radio-1 {
            &:after {
              content: "";
              position: absolute;
              background: url('../../assets/images/payu.png') no-repeat;
              width: 68px;
              height: 30px;
              left: 92px;
              top: 17px; } }
          &#radio-2 {
            &:after {
              content: "";
              position: absolute;
              background: url('../../assets/images/payment-arr.png') no-repeat;
              width: 68px;
              height: 30px;
              left: 92px;
              top: 19px;
              background-position-x: center; } }
          &:before {
            content: "";
            width: 21px;
            height: 21px;
            border: 2px solid #304471;
            left: 20px;
            top: 22px;
            position: absolute;
            border-radius: 50%; }
          &:checked {
            opacity: 1;
            background: transparent;
            -moz-appearance: none;
            -webkit-appearance: none;
            -ms-progress-appearance: unset;
            border: 2px solid $c2;
            &:before {
              background: url("../../assets/images/checkbox.png") $c2 no-repeat center center; } } }
        label {
          color: $c2;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.98px;
          line-height: 24px;
          text-transform: uppercase;
          z-index: 999;
          padding-left: 174px; } } }

    .invoice-data-container {
      @media #{$phone-strict} {
        padding-left: 28px; }
      .payment-data-head-container {
        display: flex;
        margin-bottom: 32px;
        .btn {
          opacity: 0.5;
          color: #1d1d1d;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.14px;
          line-height: 20px;
          text-align: center;
          text-decoration: underline;
          background: transparent;
          border: none;
          padding: 0 10px;
          margin-left: 30px;
          &:focus, &:hover {
            opacity: 0.8; } } }
      > p {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.15px;
        line-height: 20px;
        margin-bottom: 0; } } } }
