.product-content-block {
  margin: 25px;
  li:hover, li:focus {
    cursor: pointer; } }
.document-content {
  .form-container-content {
    margin: 0 auto;
    h3 {
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.7px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #304471;
      margin-bottom: 24px; }
    .login-btn {
      color: #304471;
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      display: block;
      margin: 0 auto;
      min-width: 200px;
      border-radius: 20px;
      border: 2px solid #304471;
      background: transparent;
      position: relative; } }
  h5 {
    font-size: 14px;
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd; }
  .doc-attachment {
    cursor: pointer;
    font-weight: 700;
    color: #304471; }
  #akt_prawny {
    #glowka_aktu {
      display: none; }
    .akt_rozdzial {
      font-weight: 700;
      text-align: center; }
    .akt_punkt, .akt_litera {
      margin: 10px 0 10px 10px;
      .akt_punkt_oznaczenie, .akt_litera_oznaczenie {
        float: left;
        margin-right: 5px;
        font-weight: 500; } }
    .akt_ustep_oznaczenie, .akt_artykul_oznaczenie {
      font-weight: 700; } } }
