.ct-wrapper {
  .closed-training-slider {
    > svg {
      height: 715px; }
    .item-image-container {
      img {
        position: relative;
        bottom: -40px;
        left: -125px;
        @media (min-width: 992px) and (max-width: 1800px) {
          left: -150px; }
        @media #{$tablet-strict} {
          display: none; } } }
    .closed-training-content {
      padding-top: 46px;
      .item-content {
        margin-top: 0;
        h1 {
          font-size: 36px;
          font-weight: 700;
          line-height: 50px;
          margin-bottom: 36px;
          @media #{$tablet-strict} {
            font-size: 30px;
            margin-bottom: 12px; } }
        h2 {
          font-size: 30px;
          font-weight: 400;
          line-height: 32px;
          margin-bottom: 36px; }
        .educado-btn {
          font-size: 22px;
          font-weight: 600;
          font-family: Signika, sans-serif;
          padding: 12px 70px 12px 20px;
          &:after {
            top: 18px; } }
        ul {
          padding-left: 0;
          list-style: none;
          padding-bottom: 26px;
          li {
            //display: flex
            //flex-direction: row
            //gap: 12px
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 10px;
            @media #{$tablet-strict} {
              font-size: 16px; }
            @media #{$desktop-strict} {
              font-size: 18px; }
            span {
              font-weight: 700; }
            svg {
              width: 26px;
              height: 26px;
              float: left;
              margin-right: 12px;
              min-width: 26px;
              position: relative;
              path {
                &:last-child {
                  fill: $c4; } } } } } } } } }
.closed-training-content {
  font-family: "Red Hat Display", sans-serif; }
.ct-cta-subject {
  background: $c5;
  color: #fff;
  display: flex;
  font-family: "Red Hat Display", sans-serif;
  flex-direction: row;
  padding: 18px 35px;
  border-radius: 18px;
  flex-wrap: nowrap;
  box-shadow: 0 3px 6px rgba(0,0,0,0.29);
  justify-content: space-between;
  @media #{$desktop} {
    align-items: center; }
  @media #{$tablet-strict} {
    flex-direction: column; }
  .content {
    padding-right: 100px;
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 12px;
      line-height: 24px;
      font-size: 16px; }
    p {
      line-height: 18px;
      font-weight: 400;
      font-size: 16px; } }
  .btn {
    height: max-content;
    min-width: 256px;
    margin-bottom: 0;
    &.btn-alt {
      color: $c1;
      &:after {
       background: url("../../assets/images/arr-black.png") no-repeat!important; } } }
  &.reverse {
    background: #fff;
    color: $c5; } }
.closed-training-form-container {
  background: $c5;
  padding: 48px 0;
  .ct-form-container {
    font-family: "Red Hat Display", sans-serif;
    color: #fff;
    background: $c5;
    border-radius: 18px;
    .closed-training-header {
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      line-height: 50px;
      margin-top: 0;
      width: 100%;
      margin-bottom: 56px; }
    .steps-container {
      margin-bottom: 96px;
      display: flex;
      flex-direction: row;
      gap: 36px;
      @media #{$phone-strict} {
        flex-direction: column;
        margin-bottom: 56px;
        padding: 0 15px; }
      .steps-item {
        font-size: 16px;
        padding: 30px 26px;
        line-height: 24px;
        text-align: center;
        border: 1px solid $c4;
        border-radius: 16px;
        flex: 1;
        position: relative;
        span {
          position: absolute;
          top: -30px;
          border-radius: 50%;
          border: 1px solid $c4;
          font-size: 32px;
          font-weight: 700;
          color: $c4;
          text-align: center;
          left: calc(50% - 30px);
          width: 60px;
          height: 60px;
          background: $c5;
          z-index: 9999;
          line-height: 58px; }
        h3 {
          font-weight: 700;
          margin: 0 0 8px;
          font-size: 16px;
          line-height: 24px; }
        p {
          font-weight: 400;
          margin-bottom: 0; } } }
    .manager-col {
      > p {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        margin-bottom: 40px;
        @media #{$desktop} {
          padding-right: 30px; } }
      .manager-section {
        display: flex;
        flex-direction: row;
        gap: 24px;
        img {
          border-radius: 50%;
          object-fit: cover;
          min-width: 84px;
          width: 84px;
          height: 84px; }
        .data {
          h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 16px; }
          p {
            margin-bottom: 16px;
            font-weight: 500;
            line-height: 24px;
            font-size: 18px;
            white-space: nowrap;
            svg {
              margin-right: 12px; }
            a {
              color: #fff;
              &:hover {
                text-decoration: underline; } } } } } }
    .form-col {
      padding: 32px 36px;
      background: white;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
      &.received {
        background: transparent; }
      @media #{$phone-strict} {
        margin-top: 40px; }
      form {
        h2 {
          font-size: 22px;
          font-weight: 700;
          color: $c5;
          margin-bottom: 20px; }
        .form-group {
          margin-bottom: 16px;
          position: relative;
          .invalid-feedback {
            position: absolute;
            left: 24px;
            top: 44px; }
          input {
            border-radius: 32px;
            border: 1px solid #727272;
            background-color: #ffffff;
            height: 50px;
            padding: 7px 22px 6px;
            font-size: 18px; }
          textarea {
            border-radius: 25px;
            border: 1px solid #707070;
            background-color: #ffffff;
            min-height: 80px;
            padding: 7px 22px 6px; }
          > label {
            color: $c5;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            input {
              display: table;
              float: left;
              height: 16px;
              margin-right: 20px;
              width: 16px; }
            div {
              display: table;
              span {
                color: red; }
              a {
                color: $c5;
                text-decoration: underline; } }
            input[name="termsAgreement"] ~ .invalid-feedback {
              position: relative;
              top: 0; }
            input[name="marketingAgreement"] ~ .invalid-feedback {
              position: relative;
              top: 0; }
            .invalid-feedback {
              font-size: 13px;
              margin-left: 12px;
              @media #{$desktop-strict} {
                top: 40px; }
              @media #{$tablet-strict} {
                top: 24px; } } } }
        .webinar-btn {
          background: $c4;
          color: $c5;
          margin: 0 auto;
          &:after {
            background: url("../../assets/images/arr-black.png"); } } } } } }
.closed-traingins-benefit-container {
  padding: 24px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 18px;
  background: white;
  margin-bottom: 40px;
  text-align: center;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 36px;
    text-align: center; }
  .educado-btn {
    margin: 36px auto 0;
    &:hover, &:focus {
      color: black;
      &:after {
        background: url("../../assets/images/arr-black.png") no-repeat; } } }
  .benefits-container-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    flex-direction: row;
    .benefit-item {
      flex: 0 1 calc(33% - 13px);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: 1px solid rgba(35, 41, 54, 0.60);
      display: flex;
      gap: 8px;
      padding: 18px;
      text-align: center;
      flex-direction: column;
      border-radius: 16px;
      @media #{$tablet-strict} {
        flex: 0 1 calc(50% - 13px); }
      @media #{$phone-strict} {
        flex: 0 1 100%; }
      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 0; }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0; }
      svg {
        margin: 0 auto; } } } }
.closed-training-category-container {
  font-family: "Red Hat Display", sans-serif;
  .closed-training-category-header {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 36px; }
  .educado-btn {
    display: table;
    margin: 12px auto 48px;
    &:hover, &:focus {
      color: black;
      &:after {
        background: url("../../assets/images/arr-black.png") no-repeat; } } }
  .category-conteiner-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-bottom: 48px;
    @media #{$phone-strict} {
      flex-direction: column; }
    .ct-category-item {
      border: 1px solid rgba(35, 41, 54, 0.60);
      border-radius: 20px;
      background: white;
      padding: 20px;
      flex: 0 1 calc(33% - 13px);
      @media #{$tablet-strict} {
        flex: 0 1 calc(50% - 13px); }
      @media #{$phone-strict} {
        flex: 0 1 100%; }

      &:nth-child(2) {
        .header {
          h2 {
            padding-top: 10px; } } }
      .header {
        display: flex;
        gap: 12px;
        padding: 10px 0;
        margin-bottom: 30px;
        h2 {
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 0;
          text-transform: uppercase; } }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          display: flex;
          gap: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
          color: black;
          svg {
            min-width: 14px;
            margin-top: 4px; } } } } } }
.closed-training-accordion {
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 60px;
  .closed-training-category-header {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 36px;
    margin-top: 16px; }
  .closed-training-accordion-container {
    background: white;
    border: 1px solid rgba(35, 41, 54, 0.60);
    border-radius: 20px;
    padding: 20px;
    .ct-accordion-content {
      h2 {
        display: flex;
        gap: 12px;
        flex-direction: row;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        align-items: center;
        margin-left: 20px; } }
    .accordion {
      .card {
        border-radius: 12px;
        background: #F0F2F6;
        margin-top: 20px;
        border: none;
        .card-header {
          background: transparent;
          border: none;
          padding: 20px;
          > button {
            width: 100%;
            display: flex;
            text-align: left;
            border: none;
            background: transparent;
            padding: 0;
            align-items: center;
            @media #{$tablet-strict} {
              flex-direction: column;
              >svg {
                display: none; } }
            .category-name {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              width: 50%;
              @media #{$tablet-strict} {
                width: 100%; }
              span {
                font-weight: 700;
                margin-right: 12px; } }
            .expert-name {
              display: flex;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              align-items: center;
              @media #{$tablet-strict} {
                flex-direction: column;
                width: 100%;
                margin-top: 10px;
                align-items: flex-start; }
              span {
                font-weight: 700;
                margin-right: 12px; }
              img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 12px; }
              p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 0; } }
            svg {
              margin-left: auto;
              transition: 0.3s all; }
            &.active {
              svg {
                transform: rotate(180deg); } } } }
        .show {
          @media #{$tablet-strict} {
            overflow: scroll; }
          .data-table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;
            font-family: "Red Hat Display", sans-serif;
            background: white;
            border-radius: 12px;
            border: none;
            thead {
              border: none;
              > tr {
                border: none; } }
            th, td {
              border: 1px solid rgba(35, 41, 54, 0.30);
              color: $c5; }
            td {
              text-align: center;
              padding: 10px 0;
              &:nth-child(4), &:nth-child(5) {
                padding: 0; }
              &:nth-child(2) {
                padding: 10px 16px; } }
            th {
              padding: 12px 16px;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              border-top: none;
              &:first-child {
                text-align: center;
                border-left: none; }
              &:last-child {
                border-right: none; }
              &:nth-child(3) {
                padding: 16px 37px 16px 16px;
                white-space: nowrap; }
              &:nth-child(4) {
                padding: 16px 40px 16px 16px;
                white-space: nowrap; }
              svg {
                margin-right: 12px;
                float: left; } }
            tr {
              border: none;
              td {
                font-size: 16px;
                font-weight: 500;
                &:first-child {
                  border-left: none; }
                &:last-child {
                  border-right: none; }
                &:nth-child(2) {
                  text-align: left; } }
              &:last-child {
                td {
                  border-bottom: none; } } }
            .user-group, .price-group {
              display: block;
              padding: 2.5px 0; }
            .user-group:first-child, .price-group:first-child {
              border-bottom: 1px solid rgba(35, 41, 54, 0.30); } } } } } } }


