//.register-thx-content-container
//  background: #ffffff
//  padding: 80px 0 120px
//  @media #{$tablet-strict}
//    padding: 40px 0
//  .image-wrapper
//    position: relative
//    &:after
//      position: absolute
//      content: ""
//      bottom: -80px
//      right: 0
//      width: 428px
//      height: 337px
//      background: url("../../assets/images/static/thx.svg") no-repeat
//      background-size: contain
//      @media #{$tablet-strict}
//        display: none
//  .register-thx-content-wrapper
//    h4
//      font-size: 24px
//      font-weight: 400
//      line-height: 32px
//      color: $c2
//      margin-bottom: 28px
//    p
//      font-size: 15px
//      font-weight: 300
//      letter-spacing: 0.15px
//      line-height: 20px
//    .btn-contact
//      margin-top: 32px
//      width: 235px
//      @media #{$tablet-strict}
//        margin: 24px auto 0
//.register-thx-products
//  padding-bottom: 112px
//  @media #{$tablet-strict}
//    display: none
//  .headline-small
//    padding: 50px 0 0

.register-thx-container {
  p, ul {
    font-size: 15px;
    font-weight: 400;
    color: $c1;
    max-width: 720px;
    line-height: 24px;
    @media #{$desktop-strict} {
      max-width: 620px; }
    strong {
      font-weight: 600; }
    span, a {
      color: $c1;
      text-decoration: underline; } }
  .container {
    padding-top: 40px;
    padding-bottom: 40px;
    &.thx-introduction-container {
      position: relative;
      &:after {
        content: '';
        height: 430px;
        width: 260px;
        top: 56px;
        right: 20px;
        background: url("../../assets/images/thx-page.svg") no-repeat;
        background-size: contain;
        position: absolute;
        @media #{$tablet-strict} {
          display: none; } } }
    &.hints-container {
      padding-top: 50px;
      padding-bottom: 50px;
      .hint {
        position: relative;
        padding-left: 60px;
        &:before {
          content: "";
          width: 41px;
          height: 41px;
          position: absolute;
          left: 0;
          background: url("../../assets/images/hint-2.svg") no-repeat; }
        &.hint-1 {
          margin-bottom: 50px;
          &:before {
            top: -9px; } }
        &.hint-2 {
          &:before {
            background: url("../../assets/images/hint-1.svg") no-repeat;
            top: 28px; } } } }
    &.adventages-container {
      p {
        margin-bottom: 40px; }
      h4 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 20px; }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          position: relative;
          padding-left: 30px;
          margin-bottom: 20px;
          &:before {
            position: absolute;
            content: '';
            background: url("../../assets/images/benefit-icon.png") no-repeat;
            width: 21px;
            height: 21px;
            left: 0;
            top: 0; } } } } }
  .bg-white {
    background: #ffffff; } }
.confimaion-container {
  font-family: 'Red Hat Display', sans-serif;
  background: $c5;
  color: #ffffff;
  padding: 60px 0 100px;
  @media #{$desktop} {
    padding: 125px 0 220px; }
  h1, h2 {
    font-size: 52px;
    line-height: 61px;
    text-transform: uppercase;
    font-weight: 400;
    @media #{$tablet-strict} {
      font-size: 32px; } }
  h2 {
    display: table;
    font-size: 42px;
    margin: 0 auto;
    position: relative;
    @media #{$tablet-strict} {
      font-size: 32px; }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      background: $c4;
      bottom: 0;
      left: 0; } }
  p {
    font-size: 25px;
    margin-top: 60px;
    > a {
      color: #fff;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        color: $c4; } } } }
