.promotion-banner-wrapper {
  @media #{$phone-strict} {
    padding-left: 0;
    padding-right: 0; }
  &.promotion-banner-oswiata {
    background: linear-gradient(180deg, #242936 26.54%, #32509F 156.41%); }
  &.promotion-banner-kadry {
    background: linear-gradient(180deg, #232936 -70.21%, #232936 -38.88%, rgba(35, 41, 54, 0.70) -3.36%, rgba(35, 41, 54, 0.60) 27.55%, rgba(35, 41, 54, 0.30) 44.65%, rgba(250, 177, 76, 0.40) 90.98%, rgba(250, 177, 76, 0.60) 111.15%, rgba(250, 161, 63, 0.90) 136.93%, #FAA13F 163.51%), #232936; }
  &.promotion-banner-default {
    background: $c5;

    .container-content {
      @media #{$tablet-strict} {
        flex-direction: column!important;
        gap: 24px; } }
    h3 {
      font-size: 18px;
      font-weight: 500;
      font-family: "Red Hat Display", sans-serif;
      margin-bottom: 26px;
      color: white;
      @media #{$tablet-strict} {
        text-align: center; } } }
  .promotion-banner-container {
    padding-top: 36px;
    padding-bottom: 36px;
    @media #{$phone-strict} {
      padding-left: 0;
      padding-right: 0; }
    .container-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media #{$tablet-strict} {
        flex-direction: column-reverse; }
      .promotion-info-container {
        color: #fff;
        display: flex;
        flex-direction: row;
        font-family: "Red Hat Display", sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        @media #{$phone-strict} {
          flex-direction: column; }
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 400;
          $a: 100%;
          $b: 3;
          width: calc(#{$a} / #{$b});
          @media #{$tablet-strict} {
            width: 100%;
            flex-direction: row;
            text-align: left; }
          p {
            font-size: 15px; }
          .circle {
            height: 90px;
            width: 90px;
            border: 3px solid #56E4A9;
            border-radius: 50%;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media #{$tablet-strict} {
              width: 70px;
              height: 70px;
              min-width: 70px;
              margin-right: 15px; }
            @media #{$phone-strict} {
              width: 40px;
              height: 40px;
              min-width: 40px;
              border: none; } } } } }
    .button-wrapper {
      width: 100%;
      display: flex;
      justify-content: right;
      @media #{$tablet-strict} {
        justify-content: center; } }
    .promotion-banner {
      font-family: "Red Hat Display", sans-serif;
      color: #ffffff;
      @media #{$tablet-strict} {
        text-align: center; }
      h3 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 40px;
        @media #{$tablet-strict} {
          font-size: 18px; } }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 24px; }
      a {
        float: right; }
      .default-banner {
        display: flex;
        flex-direction: row;
        gap: 24px;
        @media #{$phone-strict} {
          flex-direction: column; }
        .item {
          display: flex;
          flex-direction: column;
          padding: 24px;
          justify-content: space-between;
          border-radius: 16px;
          flex-basis: 100%;
          .logo-container {
            display: flex;
            flex-direction: column;
            @media #{$phone-strict} {
              flex-direction: row;
              svg {
                margin-right: 20px; } }
            span {
              font-weight: 800;
              margin: 10px 0 24px;
              font-size: 18px; } }
          .educado-btn {
            float: none; }
          &.item-kik {
            border: 1px solid #FDD26E; }
          &.item-oswiata {
            border: 1px solid #4AA5D8; } } } } } }
.category-index-headline {
  font-family: "Red Hat Display",sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  color: white;
  margin-bottom: 52px; }

.index-banner-container-content {
  display: flex;
  flex-direction: row;
  color: white;
  font-family: "Red Hat Display", serif;
  margin-bottom: 64px;
  .index-banner {
    display: flex;
    flex-direction: row;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 16px;
    padding-right: 16px;
    &:first-child {
      margin: 0;
      border: none; }
    &:last-child {
      border: none; }
    .icon-wrapper {
      margin-right: 16px; }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      padding-right: 16px; }
    &.headline {
      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px; } } } }

/* home hero banner. */

.homepage-hero-banner {
  font-family: "Red Hat Display", sans-serif;
  padding-top: 46px;
  color: #fff;
  @media #{$phone-strict} {
    background: $c5;
    width: 100vw;
    left: -15px;
    max-width: 100vw;
    position: relative; }
  .col-md-7 {
    @media #{$tablet-strict} {
      max-width: 100%;
      flex: 0 0 100%; } }
  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 28px;
    margin-top: 0;
    @media #{$tablet-strict} {
      font-size: 16px;
      text-align: center;
      line-height: 24px;
      margin-bottom: 16px; } }
  .homepage-hero-banner-container {
    display: flex;
    flex-direction: row;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: $c5;
    border-radius: 20px;
    padding: 30px;
    gap: 50px;
    @media #{$tablet-strict} {
      gap: 30px; }
    @media #{$phone-strict} {
      flex-direction: column;
      gap: 24px;
      align-items: center;
      padding: 0; }
    @media (min-width: 500px) and (max-width: 767px) {
      padding: 0 0 24px 0; }
    .hero-baner-item {
      flex-basis: 100%;
      padding: 30px;
      z-index: 99;
      height: 459px;
      max-height: 459px;
      max-width: 510px;
      position: relative;
      background-size: contain!important;
      @media #{$tablet-strict} {
        height: 300px;
        width: 330px; }
      @media #{$phone-strict} {
        width: 100%;
        padding: 15px;
        min-height: 459px; }
      @media (max-width: 500px) {
        min-height: 400px; }
      @media (max-width: 410px) {
        min-height: 350px; }
      @media (max-width: 360px) {
        min-height: 300px; }
      &:after {
        content: '';
        position: absolute;
        z-index: -1; }
      &.oswiata {
        background: url("../../assets/images/oswiata_hero_default.png") no-repeat;
        transition: 0.5s all;
        &:after {
          background: url("../../assets/images/oswiata_hero_hover.png") no-repeat; }
        &:hover {
          background: url("../../assets/images/oswiata_hero_hover.png") no-repeat;
          transition: 0.5s all; } }
      &.kik {
        background: url("../../assets/images/kik_hero_default.png") no-repeat;
        transition: 0.5s all;
        &:after {
          background: url("../../assets/images/kik_hero_hover.png") no-repeat; }
        &:hover {
          background: url("../../assets/images/kik_hero_hover.png") no-repeat;
          transition: 0.5s all; } }
      .hero-container-content {
        display: flex;
        flex-direction: column;
        .logo-wrapper {
          display: flex;
          flex-direction: column;
          svg {
            margin-bottom: 12px;
            @media #{$tablet-strict} {
              width: 42px;
              height: 40px;
              margin-bottom: 8px; } }
          span {
            font-size: 22px;
            font-weight: 700;
            @media #{$tablet-strict} {
              font-size: 14px; } }
          p {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            min-height: 72px;
            margin: 36px 0 48px;
            @media #{$tablet-strict} {
              margin: 12px 0 16px;
              font-size: 13px;
              line-height: 16px;
              min-height: 48px; } } }
        .educado-btn {
          max-width: 200px; } } } } }
