.become-expert-definition {
  .become-expert-container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px;
    @media #{$desktop} {
      padding-top: 80px;
      padding-bottom: 100px; }
    &:after {
      position: absolute;
      content: "";
      top: 35px;
      right: 120px;
      width: 380px;
      height: 365px;
      background: url("../../assets/images/static/become-expert.svg") no-repeat;
      background-size: contain;
      @media #{$desktop-strict} {
        right: 0; }
      @media #{$phone-strict} {
        display: none; }
      @media #{$tablet-strict} {
        display: none; } }
    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      color: $c2;
      margin-bottom: 20px; }
    p {
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 20px;
      margin-bottom: 24px;
      color: $c2; } } }
.three-steps-container-badge {
  background: $c2;
  h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 12px 0;
    @media #{$tablet-strict} {
      text-align: center; } } }
.three-steps-container-content {
  background: #fff;
  padding: 20px 0;
  @media #{$desktop} {
    padding: 48px 0; }
  .three-steps-item {
    @media #{$tablet-strict} {
      display: flex;
      flex-direction: column;
      align-items: center; }
    @media #{$phone-strict} {
      margin: 20px 0; }
    .item-badge {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      position: relative;
      float: left;
      margin-right: 30px;
      @media #{$tablet-strict} {
        margin-bottom: 16px; }
      &:after {
        content: "";
        position: absolute;
        width: 42px;
        height: 42px;
        top: 14px;
        left: 15px;
        background: url("../../assets/images/hand.png") no-repeat; }
      &.item-1 {
        background: #b0c5ed; }
      &.item-2 {
        background: #82d6c8; }
      &.item-3 {
        background: #7e90ff; } }
    .item-content {
      h4 {
        color: $c2;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.7px;
        line-height: 32px;
        text-transform: uppercase;
        margin-bottom: 0;
        @media #{$tablet-strict} {
          text-align: center; } }
      p {
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.15px;
        line-height: 20px;
        color: $c1;
        overflow: auto; } } } }
.expert-contact-section {
  background: #ffffff;
  color: $c2;
  margin-top: 80px;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
  &:before {
    position: absolute;
    right: 0;
    top: -184px;
    content: "";
    width: 500px;
    height: 500px;
    background-color: rgba(126, 144, 255, 0.1);
    border-radius: 50%; }
  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; }
  h5 {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    > a {
      color: $c2;
      text-decoration: underline;
      font-weight: 700; } } }
