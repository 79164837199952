.offer-wrapper {
  font-family: "Red Hat Display", sans-serif;
  .offer-headline {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    svg {
      margin-bottom: 40px; }
    h1 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 40px;
      @media #{$phone-strict} {
        margin-bottom: 0; } } }
  .offer-boxes-wrapper {
    @media #{$desktop} {
      display: flex; } }
  .offer-boxes-container {
    padding: 21px 0 80px;
    h2 {
      margin-bottom: 26px; }
    .offer-item {
      padding-top: 60px;
      padding-bottom: 60px;
      .offer-item-content {
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 0 0 30px rgba(0,0,0, 0.3);
        display: flex;
        flex-direction: column;
        padding: 36px 20px 20px;
        color: $c5;
        height: 100%;
        h4 {
          font-size: 23px;
          line-height: 30px;
          font-weight: 700;
          text-align: center;
          margin: 0 16px 6px;
          padding-bottom: 20px;
          border-bottom: 1px solid $c5;
          @media #{$tablet-strict} {
            text-align: center; } }
        .switch-info {
          color: #58585F;
          font-size: 12px;
          text-align: center;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0; }
        .nav-tabs {
          border: 1px solid $c4;
          display: table;
          border-radius: 30px;
          margin: 0 auto 24px;
          .nav-item {
            font-family: "Red Hat Display", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            display: table;
            border-radius: 30px;
            color: $c5;
            padding: 3px 15px;
            float: left;
            border: none;
            margin-bottom: 0;
            &.active {
              background: $c4;
              border-radius: 30px; } } }
        .price-container {
          margin-bottom: 16px;
          @media #{$tablet-strict} {
            text-align: center; }
          p {
            font-size: 18px;
            line-height: 37px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 0; }
          h2 {
            font-size: 31px;
            text-align: center;
            font-weight: 500;
            display: block;
            margin-bottom: 12px;
            strong {
              font-size: 44px;
              font-weight: 800; } }
          h3 {
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            p {
              text-align: center; } } }
        .benefits-container {
          padding: 16px;
          background: #F4F4F5;
          border-radius: 10px;
          font-weight: 600;
          font-size: 14px;
          min-height: 373px;
          @media #{$desktop-strict} {
            min-height: 434px; }
          @media #{$tablet-strict} {
            min-height: auto; }

          ul {
            padding-left: 0;
            list-style: none;
            span {
              font-size: 14px;
              font-weight: 600; }
            li {
              position: relative;
              margin-top: 16px;
              display: flex;
              flex-direction: row;
              gap: 10px;
              svg {
                min-width: 16px;
 } } } }                //margin-top: 4px
        .educado-btn {
          margin: 25px auto;
          display: flex;
          width: max-content; }
        .denied-text {
          font-size: 14px;
          padding: 10px 8px 10px 20px;
          margin-bottom: 0;
          font-weight: 600; }
        .unsubscribe-info {
          display: flex;
          flex-direction: row;
          color: #1d1d1d;
          font-size: 11px;
          line-height: 14px;
          position: relative;
          gap: 6px;
          svg {
            min-width: 14px; } } }
      &.webinar-offer {
        .offer-item-content {
          .price-container {
            p {
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              text-align: center; }
            > p {
              margin-top: 24px;
              margin-bottom: 16px;
              font-weight: 700; }
            h2 {
              p {
                font-weight: 400; } } } } }
      &.offer-item-promoted {
        padding-top: 0;
        padding-bottom: 0;
        .offer-item-content {
          border: 3px solid $c4;
          color: #ffffff;
          background: $c5;
          padding-top: 45px;
          padding-bottom: 36px;
          position: relative;
          .price-container {
            p {
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              text-align: center; }
            > p {
              margin-top: 24px;
              margin-bottom: 16px; }
            h2 {
              p {
                font-weight: 400; } } }
          h4 {
            border-color: $c4; }
          .unsubscribe-info {
            color: #BFBFBF;
            &:before {
              color: #BFBFBF;
              border-color: #BFBFBF; } }
          .benefits-container {
            background: #475065;
            li {
              color: #fff!important; } } }
        &:hover {
          .offer-item-content {
            box-shadow: 0 0 30px rgba(46, 227, 169, 0.6);
            .offer-btn {
              background: transparent;
              color: #ffffff;
              text-decoration: underline; } } } }
      &:hover {
        transition: 0.3s all;
        .offer-item-content {
          box-shadow: 0 0 30px rgba(0,0,0, 0.6);
          .offer-btn {
            background: #2ee3a9;
            text-decoration: underline; } } } } }
  .offer-details-container {
    @media #{$phone-strict} {
      display: none; } }
  .offer-faq-container {
    margin-bottom: 60px;
    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      margin: 60px 0 0; }
    .accordion {
      margin: 40px 0 0;
      .card {
        background: transparent;
        border: none;
        .card-header {
          background: transparent;
          padding: 25px 60px;
          position: relative;
          border-bottom: 1px solid rgba(35, 41, 54, 0.60);
          margin: 0;
          &:before {
            content: '';
            position: absolute;
            top: 27px;
            left: 20px;
            width: 20px;
            height: 20px;
            background: url("../../assets/images/question.png");
            background-size: contain; }
          button {
            font-family: "Red Hat Display", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            background: transparent;
            border: none;
            text-align: left;
            padding: 0;
            &:after {
              content: '';
              position: absolute;
              top: 27px;
              right: 30px;
              width: 14px;
              height: 7px;
              background: url("../../assets/images/offer-arr.svg");
              background-size: contain; }
            &.active {
              &:after {
                transform: rotate(180deg); } } } }
        .card-body {
          padding: 25px 60px;
          font-size: 16px; }
        &:last-child {
          .card-header {
            border: none; } } } } }
  .counter-container-content {
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.29);
    padding: 32px 40px 46px;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    .home-counters {
      h2 {
        text-align: center;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        margin: 0 0 28px; }
      .counters-container {
        display: flex;
        justify-content: space-around;
        text-align: center;
        @media #{$phone-strict} {
          flex-direction: row;
          flex-wrap: wrap; }
        .counter-container {
          width: 100%;
          @media #{$phone-strict} {
            margin-bottom: 20px;
            width: 50%; }
          .counter-wrapper {
            font-size: 36px;
            font-weight: 700; }
          .text {
            font-weight: 500;
            font-size: 24px;
            line-height: 11px;
            @media #{$tablet-strict} {
              font-size: 20px; }
            small {
              display: block;
              font-size: 14px;
              font-weight: 500; } } } } } }
  .offer-benefits-container {
    color: #fff;
    &.oswiata {
      background: linear-gradient(178deg, #242936 26.54%, #32509F 156.41%); }
    &.kik {
      background: var(--Gradient-kik-2, linear-gradient(180deg, #232936 -70.21%, #232936 -38.88%, rgba(35, 41, 54, 0.70) -3.36%, rgba(35, 41, 54, 0.60) 27.55%, rgba(35, 41, 54, 0.30) 44.65%, rgba(250, 177, 76, 0.40) 90.98%, rgba(250, 177, 76, 0.60) 111.15%, rgba(250, 161, 63, 0.90) 136.93%, #FAA13F 163.51%), #232936); }
    .offer-benefits-wrapper {
      padding: 24px;
      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 48px;
        margin-top: 16px; } }
    .offer-benefits-container-content {
      display: flex;
      justify-content: space-around;
      text-align: center;
      gap: 40px;
      flex-direction: row;
      @media #{$phone-strict} {
        flex-direction: column; }
      .offer-item {
        display: flex;
        flex-direction: row;
        gap: 12px;
        text-align: left;
        svg {
          min-width: 50px; }
        .offer-text {
          display: flex;
          flex-direction: column;
          h2 {
            margin-bottom: 16px;
            margin-top: 0;
            text-align: left;
            text-transform: none;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px; }
          p {
            font-size: 16px;
            font-weight: 500;
            span {
              color: $c4;
              font-weight: 700; } } } } } } }
