.school-multi-container {
  font-family: "Red Hat Display", sans-serif;
  color: rgba(35, 41, 54, 1);
  margin-bottom: 80px;
  &.bg-multi {
      background: url("../../assets/images/bg_strona_logowania.svg") no-repeat;
      background-size: cover;
      margin-bottom: 0; }
  .multi-first-log-container {
    padding-top: 60px;
    padding-bottom: 60px;
    h1 {
      display: flex;
      flex-direction: column;
      color: white;
      font-family: "Red Hat Display", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      text-transform: none;
      padding-left: 0;
      margin: 0 0 30px;
      span {
        font-weight: 700; } } }
  h1 {
    padding-left: 36px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
    margin-top: 40px; }
  .form-col {
    background: #fff;
    border: 1px solid rgba(35, 41, 54, 0.6);
    border-radius: 20px;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 30px 15px;
    height: 100%;
    .thx-multi-form {
      font-family: "Red Hat Display", sans-serif;
      display: flex;
      flex-direction: column;
      @media #{$desktop} {
        padding: 20px 19px; }
      h2 {
        display: block;
        color: #232936;
        text-align: center;
        font-size: 22px;
        font-weight: 700; }
      p {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 50px; }
      svg {
        margin: 0 auto; } }

    h2 {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 54px;
      gap: 20px;
      @media #{$tablet-strict} {
        flex-direction: column;
        text-align: center;
        svg {
          margin: 0 auto; } }
      @media #{$phone-strict} {
        font-size: 18px; }
      svg {
        min-width: 80px; } }
    h4 {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 32px;
      margin-bottom: 30px;
      span {
        color: red;
        position: relative;
        top: -3px; } }
    .button-wrapper {
      text-align: center;
      button {
        padding: 12px 60px 12px 40px;
        &:hover, &:focus {
          color: black;
          &:after {
            background: url("../../assets/images/arr-black.png") no-repeat; } } } }
    .form-group {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      .checkbox {
        display: block;
        gap: 5px;
        margin-bottom: 20px;
        input {
          height: 14px;
          width: 14px;
          margin-top: 2px;
          float: left;
          margin-right: 5px; }
        .invalid-feedback {
          margin-left: 30px; }
        > div {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          display: flex;
          flex-direction: row;
          gap: 6px;
          @media #{$tablet-strict} {
            display: block; }
          a {
            color: inherit;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
              color: inherit; } }
          span {
            color: red;
            position: relative;
            top: -2px; } } }
      .invalid-feedback {
        margin-left: 22px; }
      > input {
        border: 1px solid rgba(114, 114, 114, 1);
        border-radius: 32px;
        padding: 12px 20px;
        font-size: 18px;
        line-height: 24px;
        height: auto;
        &::placeholder {
          color: rgba(114, 114, 114, 1);
          font-size: 18px;
          line-height: 24px; } } } }
  .multi-benefits-col {
    @media #{$phone-strict} {
      margin-top: 30px; }
    padding: 20px;
    height: 100%;
    background: #fff;
    border: 1px solid rgba(35, 41, 54, 0.6);
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .head-section {
      display: flex;
      flex-direction: column;
      text-align: center;
      h2 {
        font-size: 23px;
        font-weight: 700;
        line-height: 30px;
        border-bottom: 1px solid $c4;
        margin: 0 20px 10px;
        padding-bottom: 12px; }
      h4 {
        display: table;
        margin: 10px auto;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center; }
      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px; }
      h3 {
        font-weight: 500;
        font-size: 31px;
        line-height: 24px;
        margin-bottom: 16px;
        strong {
          font-weight: 700;
          font-size: 44px; } }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 16px; } }
    .form-benefit-list {
      padding: 16px;
      background: rgba(35, 41, 54, 0.05);
      border-radius: 10px;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      > span, strong {
        font-weight: 700; }
      li {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        svg {
          min-width: 16px; } } }
    > p {
      display: flex;
      flex-direction: row;
      gap: 6px;
      color: rgba(191, 191, 191, 1);
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      svg {
        min-width: 14px; } } } }
.subuser-thx-container {
  border-radius: 20px;
  border: 1px solid rgba(35, 41, 54, 0.60);
  background: #FFF;
  display: flex;
  flex-direction: column;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  font-size: 22px;
  align-items: center;
  padding: 32px 36px;
  margin: 80px 0;
  h1 {
    font-weight: 700;
    margin: 32px 0;
    font-size: 22px; }
  p {}
  .educado-btn {
    &:hover {
      background: $c4; } } }
