.contact-content-section {
  background: #fff;
  color: $c2;
  .contact-content-container {
    overflow: hidden;
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
    ol li a {
      color: $c2;
      &:focus, &:hover {
        color: $c2;
        text-decoration: underline; } }
    @media #{$tablet-strict} {
      padding-top: 40px;
      padding-bottom: 24px;
      text-align: center; }
    &:before {
      position: absolute;
      right: 0;
      top: -40%;
      content: "";
      width: 534px;
      height: 534px;
      background-color: rgba(126, 144, 255, 0.09);
      border-radius: 50%; }
    .btn-contact {
      margin-bottom: 32px;
      @media #{$tablet-strict} {
        margin: 0 auto 32px; } }

    h3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 16px;
      > span {
        font-weight: 700;
        margin-left: 16px; } }
    p {
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 20px; } } }
.contact-legal-section {
  color: $c2;

  .contact-legal-container {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    text-align: center;
    @media #{$phone-strict} {
      padding-top: 0;
      padding-bottom: 60px; }
    @media #{$desktop} {
      text-align: left;
      padding-top: 58px;
      padding-bottom: 100px; }
    &:after {
      position: absolute;
      content: "";
      bottom: 40px;
      right: 0;
      width: 221px;
      height: 430px;
      background: url("../../assets/images/static/contact.svg") no-repeat;
      @media #{$desktop-strict} {
        right: 0; }
      @media #{$phone-strict} {
        display: none; }
      @media #{$tablet-strict} {
        display: none; } } }
  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 24px;
    margin-top: 15px;
    @media #{$desktop} {
      margin-top: 0; }
    @media #{$phone-strict} {
      margin-top: 40px; } }
  p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 20px; } }
