.table-container#profile-table {
  .ReactTable {
    color: $c5;
    font-family: "Red Hat Display", sans-serif !important;
    border: none;
    .rt-table {
      .-header {
        box-shadow: none;
        .rt-tr {
          align-items: center;
          border-bottom: 3px solid #F8F9FA;
          padding: 6px 0; }

        .rt-th {
          box-shadow: none;
          border: none;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          .rt-resizable-header-content {
            overflow: visible;
            text-overflow: unset;
            white-space: normal;
            .header-wrapper {
              display: flex;
              align-items: center;
              gap: 12px;
              .triangles {
                display: flex;
                flex-direction: column; } } }

          &.-cursor-pointer {
            text-align: left;
            &:nth-child(4) {
              text-align: center; }
            &.-sort-asc {
              .rt-resizable-header-content {
                .triangles {
                  svg {
                    &:first-child {
                      path {
                        fill: black; } } } } } }
            &.-sort-desc {
              .rt-resizable-header-content {
                .triangles {
                  svg {
                    &:last-child {
                      path {
                        fill: black;
                        stroke: black; } } } } } } } } }
      .rt-tbody {
        .rt-tr-group {
          font-size: 14px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          border: none;
          .rt-td {
            border: none;
            overflow: hidden; }
          .tooltip-col {
            overflow: visible; }
          .table-button-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            button {
              background: none;
              border: none;
              position: relative;
              span {
                display: none;
                position: absolute;
                right: 30px;
                top: 0;
                width: fit-content;
                padding: 4px;
                z-index: 99999;
                font-size: 14px;
                font-weight: 500;
                color: $c5;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                border-radius: 3px;
                background: #F0F2F6; }
              &:hover {
                span {
                  display: table; } } } }
          .-odd {
            background: transparent; }
          .-even {
            background: rgba(240, 242, 246, 0.50); } } } }
    .pagination-bottom {
      .-pagination {
        gap: 30px;
        justify-content: unset;
        box-shadow: none;
        margin: 20px 30px 10px;
        padding: 10px 0;
        border-width: 3px;
        .-btn {
          max-width: 40px;
          background: transparent; }
        .-previous {
          order: 2;
          flex: 0 1; }
        .-next {
          order: 3;
          flex: 0 1; }
        .-center {
          order: 1;
          justify-content: flex-start;
          flex: unset;
          .-pageJump {
            margin-left: 15px; }
          .-pageInfo {
            margin-left: 30px; }
          .-pageInfo {
            order: 2; }
          .select-wrap {
            order: 1;
            &:before {
              content: 'Liczba wierszy  ';
              margin-right: 15px; } } } } } } }
.table-headline {
  .educado-btn {
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    color: $c5;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-width: 1px;
    max-width: 230px;
    &.reverse {
      background: $c4;
      &:hover {
        background: transparent; } }
    &:after {
      display: none; }
    &:hover {
      background: $c4; } }
  .stripe {
    font-size: 16px;
    font-weight: 500;
    color: $c5;
    display: flex;
    padding: 10px 12px 10px 16px;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 16px;
    &.stripe-71 {
      background: rgba(74, 165, 216, 0.10); }
    &.stripe-13, &.stripe-14 {
      background: rgba(253, 210, 110, 0.10); } }
  .data-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    @media #{$tablet-strict} {
      flex-direction: column;
      gap: 16px;
      margin-bottom: 30px; }
    .data-numbers {
      display: flex;
      align-items: center;
      @media #{$phone-strict} {
        flex-direction: column;
        gap: 16px; }
      span {
        padding: 0 16px;
        font-size: 12px;
        font-weight: 500;
        strong {
          font-size: 18px;
          font-weight: 700;
          margin-left: 5px; } } } }
  .add-user-panel {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 30px;
    h3 {
      font-size: 18px;
      font-weight: 600; }
    @media #{$desktop} {
      flex-direction: row; }
    .button-pannel {
      display: flex;
      flex-direction: row;
      gap: 16px;
      button {
        max-width: 235px; }
      @media #{$phone-strict} {
        flex-direction: column; } } } }

.table-modal-container {
  .modal-content {
    top: 70px;
    border-radius: 12px;
    border: 1px solid $c4;
    font-family: "Red Hat Display", sans-serif;
    //min-height: 350px
    .modal-body {
      padding: 30px;
      color: $c5;
      h3 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(35, 41, 54, 0.60);
        margin-bottom: 20px;
        button {
          border: none;
          background: transparent; }
        span {
          display: flex;
          gap: 16px;
          font-size: 18px;
          font-weight: 700;
          align-items: center; } }
      .modal-content-container {
        display: flex;
        flex-direction: column;
        form {
          button {
            display: table; }
          .form-group {
            margin-bottom: 16px;
            flex-direction: column; } }
        .add-admin-field {
          display: flex;
          flex-direction: row;
          gap: 8px;
          .info-container {
            position: relative;
            span {
              display: none; }
            &:hover {
              span {
                display: table;
                position: absolute;
                right: 30px;
                top: 0;
                width: 241px;
                padding: 4px;
                z-index: 99999;
                font-size: 14px;
                font-weight: 500;
                color: #232936;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                border-radius: 3px;
                background: #F0F2F6; } } }
          label {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-left: 16px;
            font-weight: 500;
            input {
              height: 15px;
              width: 15px; } } }
        input {
          border-radius: 32px;
          border: 2px solid rgba(35, 41, 54, 0.60);
          background: #FFF;
          padding: 10px 12px;
          height: 48px;
          font-size: 16px;
          font-weight: 500;
          &::placeholder {
            color: rgba(35, 41, 54, 0.30); }
          &:focus-within {
            border: 2px solid rgba(35, 41, 54, 1); } }
        p {
          padding: 8px 0;
          font-weight: 500; }
        ul {
          list-style: none;
          padding: 8px 0;
          li {
            margin-top: 12px;
            display: flex;
            gap: 12px;
            svg {
              min-width: 12px;
              margin-top: 8px; } }
          &.add-user-text {
            padding-top: 0;
            li {
              align-items: center;
              margin-top: 0;
              margin-bottom: 12px;
              font-weight: 500;
              svg {
                margin-top: 0; } } } }
        .button-container {
          display: flex;
          gap: 16px;
          margin-top: 20px;
          .educado-btn-black {
            display: flex;
            gap: 10px; }
          .educado-btn {
            margin: 0; } }
        .educado-btn {
          margin: 30px auto 0;
          width: fit-content;
          padding: 10px 16px;
          &:after {
            display: none; }
          &:hover {
            color: black; }
          &:active {
            background: $c4;
            border-color: $c4; } }
        &.file-import-container {
          text-align: center;
          h2 {
            font-family: "Red Hat Display", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin: 32px 0 16px;
            span {
              display: block; } }
          .hidden-file-input {
            display: none; }
          .custom-file-upload {
            cursor: pointer; }
          .file-name {
            margin-top: 6px;
            font-size: 12px; }
          .educado-btn {
            margin-top: 0;
            &.download-btn {
              &:active, &:focus {
                background: white;
                border-color: black; } }
            svg {
              margin-right: 8px; } }
          .submit-file-button {
            margin-bottom: 24px; } } } } } }
