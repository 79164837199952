.pagination {
  margin-bottom: 40px;
  @media #{$desktop} {
    margin-bottom: 100px; }
  .page-item {
    .page-link {
      color: $c2; }
    &.active {
      .page-link {
        color: #ffffff;
        border-color: $c2;
        background: $c2; } } } }

.show-more-btn {
  color: #232936;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: Signika, sans-serif;
  text-align: center;
  border: 2px solid #232936;
  display: table;
  background: white;
  margin: 30px auto;
  border-radius: 32px;
  padding: 12px 40px 12px 20px;
  position: relative;
  transition: all 0.3s;
  &:after {
    content: "";
    right: 18px;
    top: 13px;
    position: absolute;
    width: 14px;
    height: 18px;
    background: url("../../assets/images/arr-down2.svg") no-repeat; }
  &:hover, &:focus {
    background: $c4;
    border: 2px solid $c4;
    color: $c5;
    outline: none;
    transition: all 0.3s;
    box-shadow: none;
    &:after {
      top: 15px;
      right: 18px; } } }
