.main {
  background: #eceef4;
  .home-main-container {
    padding-bottom: 0;
    @media #{$desktop} {
      padding-bottom: 0; }
    .home-products-container {
      .headline-small {
        padding: 26px 0 6px;
        @media #{$phone-strict} {
          text-align: center; }
        @media #{$desktop} {
          padding: 36px 0 20px;
          text-align: center; }
        &.home-products-header {
          a {
            color: $c2;
            font-size: 16px;
            line-height: 32px;
            text-transform: none;
            margin-left: 20px;
            white-space: nowrap;
            @media #{$tablet-strict} {
              margin-left: 0; }
            &:hover, &:focus {
              text-decoration: underline; } } } } } } }

/*promo kontener nad filtrami */

.home-page-promotion-container {
  .home-page-promotion-container-content {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 18px;
    background: #FFF;
    display: flex;
    gap: 24px;
    padding: 24px;
    @media #{$tablet-strict} {
      flex-direction: column; }
    .home-promotion-item {
      display: flex;
      gap: 16px;
      flex-basis: 100%;
      p {
        font-weight: 400;
        margin-bottom: 0;
        font-size: 16px;
        font-family: "Red Hat Display", sans-serif;
        span {
          font-weight: 700; } } } } }

/*Profiler Modal HomePage */

#home-profiler-modal {
  max-width: 555px !important;
  top: 70px;
  @media #{$phone-strict} {
    max-width: 100% !important;
    margin: 0; }
  .modal-content {
    border: 4px solid $c4;
    padding: 40px;
    background: #F0F2F6;
    @media #{$phone-strict} {
      padding: 15px; }
    .modal-content-container {
      padding: 0;
      .modal-header {
        flex-direction: column;
        padding: 0;
        border-bottom: none;
        @media #{$phone-strict} {
          padding: 0 15px; }
        h3 {
          margin: 30px 0 40px; }
        .close {
          position: absolute;
          top: 10px;
          font-weight: 400;
          right: 10px; } }
      .modal-body {
        .profiler-container {
          .col {
            flex: 1 0 50%;
            max-width: 50%;
            @media #{$phone-strict} {
              max-width: 45%; }
            input {
              @media #{$phone-strict} {
                height: 40px !important; } } }
          .btn-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            @media #{$phone-strict} {
              flex-direction: column;
              align-items: center; }
            .register-btn {
              padding: 5px 20px;
              @media #{$phone-strict} {
                max-width: 200px !important;
                min-width: 192px; }

              &.btn-select-all {
                min-width: 192px;
                border: 2px solid #232936;
                background: transparent;
                @media #{$phone-strict} {
                  min-height: 42px; }
                label {
                  color: #232936;
                  position: absolute;
                  left: 20px;
                  top: 3px; }
                &:after {
                  background: url("../../assets/images/arr-black.png");
                  top: 12px; }
                input {
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  position: absolute;
                  z-index: 99999; } }
              &.active {
                border: 2px solid #9EA2A9;
                label {
                  color: #9EA2A9; }
                &:after {
                  background: url("../../assets/images/arr-gray.png"); } } } } } } } } }


