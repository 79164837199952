.affiliate-code-container {
  .breadcrumb-container {
    padding: 37px 0 48px; }
  h1 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 30px; }
  .affiliate-code-content-thx {
    p {
      font-size: 15px;
      line-height: 24px; } }
  .affiliate-code-content-right {
    background: #fff;
    padding: 37px 30px 50px 50px;
    position: relative;
    @media #{$desktop} {
      $a: 100vh;
      $b: 309px;
      min-height: calc(#{$a} - #{$b}); }
    @media #{$phone-strict} {
      padding: 37px 30px 50px 50px;
      &:before {
        display: none; } }
    @media #{$tablet-strict} {
      padding: 30px 15px;
      &:before {
        position: absolute;
        content: "";
        background: #fff;
        width: 500vw;
        height: 100%;
        top: 0;
        right: 100%; }
      @media #{$phone-strict} {
        &:before {
          display: none; } } }
    &:after {
      position: absolute;
      content: "";
      background: #fff;
      width: 500vw;
      height: 100%;
      top: 0;
      left: 100%; }
    @media #{$phone-strict} {
      &:after {
        display: none; } }
    h2, p {
      font-size: 15px;
      line-height: 24px;
      color: $c2;
      >a {
        color: $c2;
        text-decoration: underline; } }
    h2 {
      font-weight: 700;
      margin-bottom: 30px; }
    .content-details {
      margin-top: 40px;
      font-size: 15px;
      line-height: 24px;
      color: $c2;
      h2, h3 {
        text-transform: uppercase;
        font-size: 15px;
        line-height: 24px;
        font-weight: 700; }
      h3 {
        font-weight: 600; }
      ul {
        li {
          color: $c2; } }
      img {
        width: 220px;
        @media #{$desktop-strict} {
          width: 170px; } } } }
  .btn {
    display: table;
    background: $c2;
    font-size: 16px;
    border: 1px solid $c2;
    width: 200px;
    font-weight: 500;
    line-height: 28px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 30px;
    &:after {
      content: "";
      width: 18px;
      height: 15px;
      right: 17px;
      top: 12px;
      position: absolute;
      background: url("../../assets/images/arr-white.svg") no-repeat; }
    &:focus, &:hover, &:active {
      outline: none;
      box-shadow: none;
      &:after {
        right: 22px;
        transition: all 0.3s; } } }
  .affiliate-code-content-thx {
    padding-bottom: 72px;
    @media #{$tablet-strict} {
      padding: 30px 15px; }
    @media #{$desktop} {
      p {
        padding-right: 360px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 290px;
          height: 260px;
          bottom: 0;
          right: 40px;
          background: url("../../assets/images/static/offer.svg") no-repeat bottom;
          background-size: contain; } } } }
  #affiliate-code-form {
    .field-wrapper {
      &.error {
        position: relative;
        .alert {
          position: absolute;
          bottom: -40px;
          left: 105px;
          margin-top: 0;
          font-size: 80%;
          color: #dc3545;
          padding: 0;
          border-color: transparent;
          background: transparent;
          @media #{$phone-strict} {
            left: 5px; } }
        input {
          background: url("../../assets/images/svg/warning.svg") #fff no-repeat right calc(0.375em + 0.1875rem) center;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          border-color: #dc3545; } } }
    label {
      float: left;
      padding-right: 18px;
      font-weight: 500;
      font-size: 15px;
      padding-top: 7px;
      @media #{$phone-strict} {
        width: 100%; } }
    .form-group {
      width: 266px;
      position: relative;
      .invalid-feedback {
        position: absolute;
        bottom: -23px;
        left: 5px; }
      input::placeholder {
        text-transform: uppercase;
        font-size: 13px;
        text-align: center;
        opacity: 0.5; } } }
  #affiliate-code-account-form {
    .marketing-terms {
      .form-group {
        .invalid-feedback {
          left: 0;
          bottom: 0;
          font-weight: 500;
          font-size: 12.8px;
          @media #{$tablet-strict} {
            bottom: -10px; } } } }
    .form-group {
      position: relative;
      label {
        padding-right: 18px;
        min-width: 150px;
        font-weight: 500;
        font-size: 15px;
        padding-top: 7px; }
      input {
        width: 264px;
        &::placeholder {
          opacity: 0.5;
          text-transform: uppercase; } }
      .invalid-feedback {
        position: absolute;
        bottom: -20px;
        left: 160px;
        @media #{$phone-strict} {
          left: 5px; } }
      @media #{$phone-strict} {
        flex-direction: column;
        input {
          width: 100%; } } }
    .btn {
      margin-top: 25px; }
    .marketing-terms {
      .form-group {
        margin-bottom: 0;
        label {
          font-weight: 300;
          line-height: 20px;
          a {
            color: $c2;
            text-decoration: underline; }
          input {
            width: 13px;
            float: left; }
          div {
            padding: 9px 26px;
            @media #{$desktop} {
              padding: 9px 167px 0 26px; } } } } } } }
