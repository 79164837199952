.category-top-box {
  > h1 {
    @media #{$tablet-strict} {
      display: none; } }
  .breadcrumb {
    .breadcrumb-item {
      &:before {
        font-size: 14px;
        line-height: 30px;
        color: #fff; }
      > a {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
        line-height: 32px;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline; }
        &:after {
          display: none; } } } } }

.breadcrumb-grey {
  .breadcrumb {
    .breadcrumb-item {
      &:before {
        color: $c3; }
      > a {
        color: $c3;
        text-transform: uppercase; } } } }

.breadcrumb-container {
  .container {
    display: flex;
    .col {
      padding: 0;
      @media #{$phone-strict} {
        display: none;
        &.active {
          display: flex; } }
      .reg-breadcrumb {
        opacity: 0.8;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.98px;
        line-height: 20px;
        text-transform: uppercase;
        &.code-breadcrumb {
          text-transform: none;
          &.active {
            opacity: 1; } }

        &.active {
          font-weight: 700; } } } } }
