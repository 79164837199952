.profiler-container {
  position: relative;
  .success-info {
    font-weight: 500;
    display: table;
    padding-left: 30px;
    margin-top: 32px;
    line-height: 32px;
    font-size: 16px;
    color: $c2;
    margin-bottom: 0;
    @media #{$phone-strict} {
      position: absolute;
      bottom: -35px;
      left: 0; } }
  .register-btn {
    border-radius: 56px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    background: $c2;
    padding: 3px 20px;
    min-width: 150px;
    position: relative;
    border: none;
    margin-top: 30px;
    float: left;
    @media #{$phone-strict} {
      float: none; }
    &:after {
      content: "";
      width: 18px;
      height: 15px;
      background: url("../../assets/images/arr-white.svg") no-repeat;
      right: 17px;
      top: 12px;
      position: absolute; }
    &:focus, &:hover, &:active {
      outline: none;
      box-shadow: none;
      &:after {
        right: 22px;
        transition: all 0.3s; } } }
  .col {
    display: flex;
    flex: 1 0 25%;
    max-width: 25%;
    padding-left: 0;
    @media #{$tablet-strict} {
      flex: 1 0 50%;
      max-width: 50%; }
    input {
      width: 100%!important;
      height: 24px!important;
      margin: 3px 14px 0 0;
      z-index: 9999999999;
      position: relative;
      opacity: 1;
      background: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-progress-appearance: unset;
      @media #{$phone-strict} {
        height: 40px!important; }
      &:before {
        position: absolute;
        background: url("../../assets/images/checkbox.svg") no-repeat;
        left: 0;
        top: 0;
        content: "";
        width: 14px;
        height: 14px; }
      &:checked {
        &:before {
          position: absolute;
          background: url("../../assets/images/checkbox-check.svg") no-repeat;
          left: 0;
          top: 0;
          content: "";
          width: 14px;
          height: 14px; } } }
    label {
      font-size: 15px;
      line-height: 20px;
      color: $c1;
      font-weight: 300;
      position: absolute;
      left: 25px; }
    input:checked +label {
      font-weight: 500; } } }
