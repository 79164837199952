.help-accordion-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  @media #{$phone-strict} {
    padding-bottom: 32px; }
  &:before {
    position: absolute;
    left: -39%;
    top: 20px;
    content: "";
    width: 1614px;
    height: 1610px;
    background-color: rgba(126, 144, 255, 0.1);
    border-radius: 50%; }
  &:after {
    position: absolute;
    content: "";
    bottom: 35px;
    right: 100px;
    width: 205px;
    height: 430px;
    background: url("../../assets/images/static/help.svg") no-repeat;
    background-size: contain;
    @media #{$desktop-strict} {
      right: 0; }
    @media #{$phone-strict} {
      display: none; }
    @media #{$tablet-strict} {
      display: none; } }
  .accordion {
    h4 {
      font-size: 14px;
      font-weight: 700;
      color: $c2;
      letter-spacing: 0.7px;
      line-height: 24px;
      text-transform: uppercase;
      margin: 64px 0 20px; }
    .card {
      border: none;
      background: transparent;
      margin-bottom: 32px;
      .card-header {
        background: #ffffff;
        border: none;
        margin: 0;
        padding: 0;
        &:focus {
          outline: none; }
        button {
          padding: 25px 50px 25px 25px;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 0.15px;
          line-height: 20px;
          color: $c2;
          background: #fff;
          border: none;
          position: relative;
          text-align: left;
          width: 100%;
          @media #{$desktop} {
            padding: 25px 122px 22px 32px; }
          &:after {
            content: "";
            width: 14px;
            height: 8px;
            position: absolute;
            right: 32px;
            top: 50%;
            background: url('../../assets/images/arr-down.svg');
            @media #{$phone-strict} {
              right: 12px; } }
          &:focus {
            box-shadow: none;
            outline: none; }
          &.active {
            &:after {
              background: url('../../assets/images/arr-up.svg'); } } } }
      .collapse, .collapsing {
        .card-body {
          color: $c2;
          font-size: 15px;
          letter-spacing: 0.15px;
          line-height: 20px;
          padding: 24px;
          @media #{$desktop} {
            padding: 24px 122px 0 32px; } }
        &.show {} } } } }


.help-contact-badge {
  background: #fff;
  .help-contact-left {
    color: $c2;
    @media #{$phone} {
      padding: 40px 15px; }
    @media #{$desktop} {
      padding: 65px 100px 55px 15px; }
    h4 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.7px;
      line-height: 24px;
      text-transform: uppercase; }
    p {
      color: #304471;
      font-size: 15px;
      letter-spacing: 0.15px;
      line-height: 20px; } }
  .help-contact-right {
    padding: 60px;
    position: relative;
    overflow: hidden;
    @media #{$phone-strict} {
      padding: 32px; }
    &:before {
      position: absolute;
      right: 0;
      top: -153px;
      content: "";
      width: 534px;
      background-color: rgba(126, 144, 255, 0.1);
      border-radius: 50%;
      height: 534px; }
    .btn-contact {
      margin: 0 auto; }
    p {
      text-align: center;
      color: $c2;
      padding-top: 22px;
      > span {
        font-weight: 700; } } } }
