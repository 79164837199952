.slider-container-wrapper {
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 40px;
  &.no-banner {
    @media #{$desktop} {
      margin-top: 40px; } }
  .slider-container-content {
    position: relative;
    &:before, &:after {
      position: absolute;
      content: '';
      background: url("../../assets/images/triangle-yellow.svg") no-repeat;
      background-size: contain;
      z-index: 3; }
    &:before {
      width: 44px;
      height: 51px;
      top: -25px;
      right: 125px; }
    &:after {
      top: -5px;
      right: 60px;
      width: 56px;
      height: 65px; }
    .carousel {
      margin-top: 0;
      position: relative;
      .carousel-indicators {
        bottom: -40px; }
      .carousel-item {
        padding: 26px 0 26px;
        position: relative;
        @media #{$phone-strict} {
          > .row {
            background: $c5;
            padding-top: 10px; } }
        .slider-image-container {
          border-radius: 15px;
          .image-frame {
            height: 100%;
            width: 100%;
            position: relative;
            &:after {
              position: absolute;
              bottom: 0;
              left: 0;
              content: '';
              width: 100%;
              height: 199px;
              background: url("../../assets/images/oswiata_slider.svg") no-repeat;
              border-radius: 22px;
              z-index: 9999;
              background-size: cover;
              @media #{$phone-strict} {
                height: 130px; } }
            &.kik {
              &:after {
                background: url("../../assets/images/kik_slider.svg") no-repeat; } } }

          @media #{$desktop} {
            padding-right: 25px; }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 22px; } }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 70%;
          height: 100%;
          background: $c5;
          border-radius: 20px;
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
          z-index: 1; }
        @media #{$tablet-strict} {
          &:after {
            width: 100%; } }
        @media #{$phone-strict} {
          &:after {
            display: none; } }
        .slider-image-container {
          z-index: 2; }
        .slider-details-container {
          padding: 30px 0 5px;
          z-index: 2;
          color: #fff;
          @media #{$desktop} {
            min-height: 460px; }
          .sliders-details-container-content {
            padding-left: 25px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media #{$phone-strict} {
              padding-left: 15px; }
            .wrapper {
              .slider-item-type > span, .slider-category-item-container {
                background: white;
                font-family: Signika, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 26px;
                text-transform: uppercase;
                color: $c5;
                display: table;
                padding: 0 16px;
                border-radius: 3px;
                margin: 0 12px 10px 0;
                &.chat-badge {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  svg {
                    height: 16px; } } }
              .slider-item-type {
                display: flex; }
              .slider-category-item-container {
                background: #FDD26E;
                > span {
                  margin-right: 3px;
                  &:after {
                    content: '|';
                    padding-left: 3px; }
                  &:last-child {
                    &:after {
                      content: ''; } } }
                &.category71 {
                  background: linear-gradient(0deg, rgba(74, 165, 216, 0.60) 0%, rgba(74, 165, 216, 0.60) 100%), #FFF; } }
              h2 {
                font-size: 25px;
                font-weight: 400;
                margin-bottom: 24px;
                padding-right: 32px; }
              h3, .expert-desc, h3 > a {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: white; }
              h3 {
                > a {
                  &:hover {
                    color: white;
                    text-decoration: underline; } } }
              .slider-details-text {
                margin-top: 26px;
                .slider-text-info {
                  display: flex;
                  margin-bottom: 20px;
                  p, div {
                    margin: 0 0 0 20px;
                    font-size: 18px;
                    line-height: 30px; }
                  .webinar-date-item {
                    &.expired {
                      text-decoration: line-through; } }
                  &.webinar-free-timetable {
                    flex-direction: column;
                    display: inline-flex;
                    .expired {
                      opacity: 60%;
                      text-decoration: none; }
                    .webinar-date-item {
                      margin: 0 0 5px 0;
                      padding-bottom: 5px;
                      border-bottom: 1px solid #DBDBDB;
                      > span {
                        margin-left: 10px;
                        line-height: 30px;
                        font-size: 15px;
                        .date-index {
                          font-weight: 500;
                          margin-right: 60px; }
                        .date {
                          font-weight: 400; }
                        .time {
                          font-weight: 400;
                          width: 50px;
                          display: inline-block; } } } } } } }
            .slider-button-wrapper {
              @media #{$phone-strict} {
                display: flex;
                gap: 20px;
                flex-direction: column;
                text-align: center;
                > .educado-btn-text {
                  width: 171px;
                  margin: 0 auto; } }
              .slider-button-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 30px;
                font-family: Signika, sans-serif;
                font-weight: 600;
                .btn-watch {
                  padding: 12px 59px 12px 50px;
                  &:after {
                    right: 30px; }
                  &:hover {
                    &:after {
                      right: 35px; } } }
                @media #{$phone-strict} {
                  flex-direction: column;
                  gap: 20px;
                  margin-bottom: 20px; } } } } } } }

    .carousel-control-prev, .carousel-control-next {
      display: none; } } }


